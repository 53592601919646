import React, {useEffect, useState, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import TitleBar from 'js/components/titleBar.js';
import DataStoreContext from 'dataStoreContext';
import {FormatService} from 'services/helpers.js';
import moment from 'moment';
import 'moment/locale/sv'
import 'moment-duration-format';
import Nav from 'react-bootstrap/Nav'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import PaymentReceipt from 'js/components/paymentReceipt.js'
import SessionReceipt from 'js/components/sessionReceipt.js'
import visa from 'resources/visa.png';
import mastercard from 'resources/mastercard.png';
import epspotSqLogo from 'resources/logoSqLowRes.png';
import HelperService from "services/helpers.js";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {IconPlug, IconCarHeater, IconCarCharge, IconQuestionCircle} from 'resources/icons.js';
import Api from 'services/api';

import 'styles/common.scss';
import 'styles/historyView.scss';

// Translation
import { useTranslation} from 'react-i18next';

export default function HistoryView(props) {
    
    let history = useHistory();
    const { userMe, paymentReceipts, completedSessions, setCompletedSessions } = useContext(DataStoreContext);
    const [activeTab, setActiveTab]  = useState('PAYMENTS'); // PAYMENTS || SESSIONS  
    const [isFetchingSessions, setIsFetchingSessions]  = useState(false); 
    const [allSessionsFetched, setAllSessionsFetched]  = useState(false); 

    // Translation
    const { t, i18n } = useTranslation('views');
    moment.locale(i18n.language);

    useEffect(() => {
        props.toggleMenu(false);
        if (!userMe) setAllSessionsFetched(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const handleBackClick = () => {
        history.push("/");
    }

    const fetchMoreSessions = async () => {
        let fromDate = completedSessions[completedSessions.length-1].startTime;
        let moreCompletedSessions = null;
        setIsFetchingSessions(true);
        try { moreCompletedSessions = await Api.getCompletedSessions(userMe.userName, fromDate)} 
        catch (error) { console.log('could not fetch completed sessions'); }
        let allSessions = null;
        if (moreCompletedSessions && moreCompletedSessions.items.length > 0) {
            allSessions = completedSessions.concat(moreCompletedSessions.items);
            setCompletedSessions(HelperService.sortCompletedSessions(allSessions));
        }
        else {
            setAllSessionsFetched(true);    
        }
        setIsFetchingSessions(false);
    }    

    const renderIcon = (receipt) => {
        if (receipt.paymentMethod === 'CARD' && receipt.receiptMetadata.cardBrand === 'visa') {
            return (<img src={visa} alt="Visa" />);
        }
        else if (receipt.paymentMethod === 'CARD' && receipt.receiptMetadata.cardBrand === 'mastercard') {
            return (<img src={mastercard} alt="Mastercard" />);
        }
        else if (receipt.paymentMethod === 'CARD') {
            return (<img src={visa} alt="Visa" />);
        }
        else  if (receipt.paymentMethod === 'SWISH'){
            return <div className="swish"></div>
        }
        else  if (receipt.paymentMethod === 'EPSPOT_MANUAL'){
            return (<img className="epspot" src={epspotSqLogo} alt="Epspot" />);
        }
    }



    const formatDuration = (dur) => {
        let duration = moment.duration(dur, 'seconds');
        let prettyDur = (duration.days() > 0 ? duration.days() + 'd:' : '') + (duration.hours() > 0 ? duration.hours() + 'h:' : '') + duration.minutes() + 'm:' + duration.seconds() + 's' ;
        return prettyDur;
    
    }

    const formatPower = (totalEnergy) => {
        let n = totalEnergy / 1000000;
        if(n === 0) return "0"
        if( n > 0 && n < 0.01) return "< 0.01"
        return n.toFixed(2);
        
    }

    const renderSessionType = (session) => {
        //console.log(session);
        if (session.sessionType === 'CHARGE') return <IconCarCharge/>;
        if (session.sessionType === 'HEAT') return <IconCarHeater/>;
        if (session.sessionType === 'POWER') return <IconPlug/>;
        return <IconQuestionCircle/>
    }

    const renderReceiptAmount = (receipt) => {
        if (receipt.hasRefund) {
            let refund = HelperService.getRefundAmount(receipt);
            let remainingPaidAmount = receipt.totalAmount - refund;
            return (<div>
                        <span className="amount-with-refund">{ FormatService.formatCurrency(100*receipt.totalAmount, 2,  'SEK') }</span>
                        <span>{ FormatService.formatCurrency(100*remainingPaidAmount, 2,  'SEK') }</span>
                    </div>);
        }
        else {
            return (<span>{ FormatService.formatCurrency(100*receipt.totalAmount, 2,  'SEK') }</span>);
        }
    }

    const renderPayments = (receipts) => {
        if(!receipts || receipts.length === 0) return (<div style={{position: 'absolute', marginTop: '14px'}}>{ t("historyView.noReceipts")}</div>);
        return (
            <Accordion defaultActiveKey="0">
                { receipts.map(receipt => {
                    //if (receipt.paymentMethod==='EPSPOT_MANUAL') console.log(receipt);
                    return (<Card className="receipt-card" key={receipt.id}>
                        <Accordion.Toggle className="receipt-summary" as={Card.Header} eventKey={receipt.id}>
                            
                            <div className="icon"> { renderIcon(receipt) } </div>
                            
                            <div className="receipt-summary-date-number">
                                <div className="receipt-summary-date">{moment(receipt.createdOn).format('LL')}</div>
                                <div className="receipt-summary-number">#{receipt.receiptNumber}
                                {receipt.receiptType==='DEDUCT' ? <span className='credit-receipt'>{t("historyView.credit_receipt")}</span> : null} 
                                </div>
                            </div>

                            <div className="receipt-summary-amount">
                                {renderReceiptAmount(receipt)}
                            </div>
                            
                        
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={receipt.id}>
                        <Card.Body><PaymentReceipt receipt={receipt} modal={false} closeCB={()=>{}}/></Card.Body>
                        </Accordion.Collapse>
                    </Card>);
                    }
                )}
            </Accordion>
        );
    }

    const renderSessions = (sessions) => {
        if(!sessions || sessions.length === 0) return (<div style={{position: 'absolute', marginTop: '14px'}}>{t("historyView.noFinishedSessions")}</div>);
        return (
            <>
            <Accordion defaultActiveKey="0">
                    { sessions.map(session => {
                        let cost = 0;
                        if(session.debitedCostSum)
                            cost = session.debitedCostSum;
                        else if (session.debitedCost && !isNaN(session.debitedCost))
                            cost = session.debitedCost;
                        return (<Card className="session-card" key={session.id}>
                            <Accordion.Toggle className="session-summary" as={Card.Header} eventKey={session.id}>

                                { renderSessionType(session) }
                                
                                <div className="session-summary-time-info">
                                    <div className="session-summary-date">{moment(session.startTime).format('LL')}, {session.provider ? session.provider.name : ''}</div>
                                    <div className="session-summary-time-dur"><span>{moment(session.startTime).format('H:mm')}</span>, {t("historyView.time")} <span> {formatDuration(session.duration)}</span></div>
                                </div>

                                
                                <div className="session-summary-amount">
                                    <div className="session-summary-energy">
                                        { formatPower(session.totalEnergy) } <span className='unit'>kWh</span>
                                    </div>

                                    <div className="session-summary-sek">
                                        { FormatService.formatCurrency(100 * cost, 2,  'SEK') }
                                    </div>                                    
                                </div>

                                
                                
                            
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={session.id}>
                            <Card.Body>
                                <SessionReceipt session={session} modal={false} closeCB={()=>{}}/>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>);
                        }
                    )}
            </Accordion>
            {(isFetchingSessions) ?
            <Spinner className="fetch-spinner" animation="border" /> : 
            (allSessionsFetched) ? null : <Button className="fetch-more-button" onClick={()=>fetchMoreSessions()}>{t("historyView.fetchMore")}</Button> 
            }
            </>
        );
    }


    const renderAnonymousHistory = () => {
        let unregCompletedSessions = HelperService.getUnregisteredCompletedSessions();
        return (
            <>
                <Nav fill defaultActiveKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                <Nav.Item>
                        <Nav.Link eventKey="SESSIONS"><div className="nav-header"><span className="header-text">{t("historyView.sessions")}</span></div></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="PAYMENTS"><div className="nav-header"><span className="header-text">{t("historyView.receipts")}</span></div></Nav.Link>
                    </Nav.Item>
                    
                </Nav>

                { (activeTab === 'SESSIONS') ? renderSessions(unregCompletedSessions): null}
                { (activeTab === 'PAYMENTS') ? renderPayments(paymentReceipts): null}
            </>
        );
    }

    const renderUserHistory = () => {
        //console.log('TODO: Understand why this is printed like 4 times in a row and possibly fix it');
        return (
            <>
                    <Nav fill defaultActiveKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                    <Nav.Item>
                        <Nav.Link eventKey="SESSIONS"><div className="nav-header"><span className="header-text">{t("historyView.sessions")}</span></div></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="PAYMENTS"><div className="nav-header"><span className="header-text">{t("historyView.receipts")}</span></div></Nav.Link>
                    </Nav.Item>
                </Nav>

                { (activeTab === 'SESSIONS') ? renderSessions(completedSessions): null}
                { (activeTab === 'PAYMENTS') ? renderPayments(paymentReceipts): null}
            </>
        );
    }

    return (
    <div id="historyView" className="sliding-view history-view">
        <div className="bg"></div>
        <div className="cover"></div>
        <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={t("historyView.history")}/>
        
        <div className="content">
            { userMe ? renderUserHistory() : renderAnonymousHistory() }
        </div>
    </div>
  );
}