import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import DataStoreContext from 'dataStoreContext'

import 'styles/tariff.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';

const HIGH_TARIFF = 6.50;

export default function Tariff({ tariff, loggedInView }) {

    const { t } = useTranslation("components")

    let openingFee = 0;
    const { setHelpTextModal, scannedOutlet } = useContext(DataStoreContext);
    if (loggedInView && tariff.openingFee) openingFee = tariff.openingFee;
    else if (!loggedInView && tariff.singlePaymentOpeningFee) openingFee = tariff.singlePaymentOpeningFee;

    /*const roundTo2Decimals = (num) => {
        let f = parseFloat(num.toFixed(2)).toString();
        if (f.indexOf('.') < 0) return f + '.00';
        else {
            let decPart = f.slice(1+f.indexOf('.'));
            if (decPart.length < 2) return f + '0';
        }
        return f;
    }*/

    const roundTo2Decimals = (num) => {
        let f = parseFloat(num.toFixed(2)).toString();
        if (f.indexOf('.') < 0) return f;
        else {
            let decPart = f.slice(1 + f.indexOf('.'));
            if (decPart.length < 2) return f + '0';
        }
        return f;
    }

    const renderKWhHourPrice = () => {
        if (tariff.feeKwh > 0 && tariff.feeHour > 0) { //combined tariff
            return (
                <div> <span className="fee">{roundTo2Decimals(tariff.feeKwh)}</span> <span className="fee-spec">kr/kWh</span> + <span className="fee">{roundTo2Decimals(tariff.feeHour)}</span>  <span className="fee-spec">kr/{t("tariff.hour")}</span> </div>
            );
        }
        else if (tariff.feeKwh === 0 && tariff.feeHour === 0) {
            return (
                <div> <span>{t("tariff.no_running_fee")}</span> </div>
            );
        }
        else {
            return (
                <>
                    {(tariff.feeKwh > 0) ? <div> <span className="fee">{roundTo2Decimals(tariff.feeKwh)}</span> <span className="fee-spec">kr/kWh</span> </div> : null}
                    {(tariff.feeHour > 0) ? <div> <span className="fee">{roundTo2Decimals(tariff.feeHour)}</span> <span className="fee-spec">kr/{t("tariff.hour")}</span> </div> : null}
                </>
            );
        }
    }

    const getSlotPeriodUnit = (period) => {
        if (period === 1) return 'timme';
        return 'timmar';
    }

    const renderRunningTariff = () => {
        return (
            <>
                {renderKWhHourPrice()}
                {(openingFee > 0) ? <div> <span className="fee">{roundTo2Decimals(openingFee)}</span> <span className="fee-spec">kr {t("tariff.opening_fee")}</span> </div> : null}
            </>
        );
    }

    const renderDayTariff = () => {
        if (!tariff.feeTimeslot) tariff.feeTimeslot = 0;
        return (
            <>
                <div> <span className="fee">{parseFloat(tariff.feeTimeslot.toFixed(0))}</span> <span className="fee-spec">kr/{t("tariff.day")}</span>
                    {(tariff.slotEndHour) ? <><span className="fee-spec"> ({t("tariff.period_end")}</span> <span className="fee">{tariff.slotEndHour}:00</span><span className="fee-spec">)</span></> : null}
                </div>
                {renderKWhHourPrice()}
                {(openingFee > 0) ? <div> <span className="fee">{roundTo2Decimals(openingFee)}</span> <span className="fee-spec">kr {t("tariff.opening_fee")}</span> </div> : null}
            </>
        );
    }

    const renderPeriodTariff = () => {
        if (!tariff.feeTimeslot) tariff.feeTimeslot = 0;
        return (
            <>
                <div> <span className="fee">{parseFloat(tariff.feeTimeslot.toFixed(0))}</span> <span className="fee-spec">kr/{tariff.slotPeriod} {getSlotPeriodUnit(tariff.slotPeriod)}</span>
                    {(tariff.slotEndHour) ? <><span className="fee-spec"> ({t("tariff.period_end")}</span> <span className="fee">{tariff.slotEndHour}:00</span><span className="fee-spec">)</span></> : null}
                </div>
                {renderKWhHourPrice()}
                {(openingFee > 0) ? <div> <span className="fee">{roundTo2Decimals(openingFee)}</span> <span className="fee-spec">kr {t("tariff.opening_fee")}</span> </div> : null}
            </>
        );
    }

    return (
        <div className="tariff">
            <div className="title" onClick={() => setHelpTextModal(['price_and_payment:tariffs'])}><FontAwesomeIcon className="coins" icon={faCoins} /> Tariff <span className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span></div>

            <div className="values">
                {tariff.type === 'RUNNING' ? renderRunningTariff() : null}
                {tariff.type === 'SLOT' && tariff.slotPeriod === 24 ? renderDayTariff() : null}
                {tariff.type === 'SLOT' && tariff.slotPeriod !== 24 ? renderPeriodTariff() : null}
            </div>
            {tariff.feeKwh >= HIGH_TARIFF ?
                <div className="high-tariff-info">
                    <span>{t('tariff.fee_determined_by')}</span>
                    <h5>{scannedOutlet.basicProvider.name}</h5>
                </div> : null
            }

        </div>
    );

}


/*
getTariffRenderElement : function(loggedInView, outlet) {
        if (! outlet.tariff) return null;
        let t = outlet.tariff;

        if (t.type === 'RUNNING') { //Löpande laddning
            if (!t.feeKwh) t.feeKwh = 0;
            if (!t.feeHour) t.feeHour = 0;
            
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            if (t.feeKwh === 0 && openingFee === 0 && t.feeHour === 0) return (<span>ingen avgift</span>);
            return (
                <>
                    {(t.feeKwh > 0) ? <span>{parseFloat(t.feeKwh.toFixed(2))} kr/kWh</span> : null}
                    {(t.feeKwh > 0 && t.feeHour && t.feeHour> 0) ? ' + ' : null} 

                    {(t.feeHour > 0) ? <span>{parseFloat(t.feeHour.toFixed(2))}  kr/tim</span> : null}
                    
                    {((t.feeKwh > 0) || (t.feeHour > 0)) && (openingFee > 0) ? ' + ' : null} 

                    {(openingFee > 0) ? <span>{parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null}
                </>
            )
        }
        else if (t.type === 'SLOT' && t.slotPeriod === 24) { // Dygnsladdning
            if (!t.feeTimeslot) t.feeTimeslot = 0;
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            return (
                <>
                    <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr/dygn</span>
                    {(openingFee > 0) ? <span> + {parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null} 
                    {(t.slotEndHour) ? <span> (periodslut {t.slotEndHour}:00)</span> : null} 
                </>
            )
        }
        else if (t.type === 'SLOT' && t.slotPeriod !== 24) { // Timperiodladdning
            if (!t.feeTimeslot) t.feeTimeslot = 0;
            let openingFee = 0;
            if (loggedInView && t.openingFee) openingFee = t.openingFee;
            else if (!loggedInView && t.singlePaymentOpeningFee) openingFee = t.singlePaymentOpeningFee;
            
            return (
                <>
                    {(t.slotPeriod === 1) ? <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr/timme</span> : null} 
                    {(t.slotPeriod > 1) ? <span>Periodladd, {parseFloat(t.feeTimeslot.toFixed(0))} kr / {t.slotPeriod} timmar</span> : null} 
                    {(openingFee > 0) ? <span> + {parseFloat(openingFee.toFixed(2))}  kr i öppning</span> : null} 
                </>
            )
        }
        else return null; //something wrong
    },
    */