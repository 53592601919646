import React, {useState, useEffect, useContext} from 'react';
import OutletBaseInfo from 'js/components/outletBaseInfo.js'
import DataStoreContext from 'dataStoreContext'
import Api from "services/api.js";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useInterval } from 'services/hooks.js'
import cloneDeep from 'lodash/cloneDeep';
import 'styles/uninsertedOutletView.scss';

// Translation
import { useTranslation } from 'react-i18next';

export default function UninsertedOutletView({disconnectOutletCB }) {
    const [timesUp, setTimesUp] = useState(false);
    const { scannedOutlet, setScannedOutlet, appInForeground } = useContext(DataStoreContext);
    const [count, setCount] = useState(0);
    const [delay, setDelay] = useState(2000);
    const [restartAnimation, setRestartAnimation] = useState(false);

    // Translation
    const { t } = useTranslation('views');

    useEffect(() => {
        if (count > 9) {
            setDelay(null);
            setTimesUp(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [count]);


   /* useInterval( () => {
        setCount(count => count + 1);
        checkOutletInserted();
    }, delay); */

    useInterval(
        () => {
            setCount(count => count + 1);
            checkOutletInserted();
        },
        (appInForeground) ? delay : null
      );
    
    const handleClick = () => {
        setDelay(2000);
        setCount(0);
        setRestartAnimation((restartAnimation)? false: true);
    }

    const checkOutletInserted = async () => {
        let outletStatus = null;
        
        if (scannedOutlet.useNewModel) {
            outletStatus = await Api.getOutletStatus2(scannedOutlet.id, scannedOutlet.plantRef, scannedOutlet.providerRef);
        }
        else {
            outletStatus = await Api.getOutletStatus(scannedOutlet.id);
        }

        if (outletStatus && outletStatus.plugInserted) {
            setDelay(null);
            let scannedOutletCopy = cloneDeep(scannedOutlet);
            scannedOutletCopy.outletStatus = outletStatus;
            setScannedOutlet(scannedOutletCopy);
        }
        
    }

    const renderTimeoutInfo = () => {
        const classes = timesUp ? 'timeout-info' : 'timeout-info hide';
        return (
            <div className={classes}>
                <div className="timeout-text">{t("uninsertedOutletView.waitedTooLong")}</div>
                <div className="timeout-button" onClick={handleClick}>{t("uninsertedOutletView.connectorPluggedIn")}</div>
            </div>
        );
    }

    const swapOutlet = () => {
        console.log('swap outlet');
        disconnectOutletCB();
    };

  return (
    <div className="uninserted-outlet-view">
        <OutletBaseInfo outlet={scannedOutlet} swapOutletCB={swapOutlet}/>

        

        <div className="animation"></div>

        <div className="countdown">
        <CountdownCircleTimer
            key={restartAnimation} 
            isPlaying
            durationSeconds={20}
            strokeWidth={5}
            colors={[
                ['#FF8200']
            ]}
        />
        </div>

        <div className="header-text">{t("uninsertedOutletView.plugInConnector")}</div>

        { renderTimeoutInfo() }

        
    </div>
  );
}