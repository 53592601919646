import React, { useState, useEffect, useContext } from 'react';
import DataStoreContext from 'dataStoreContext'
import {FormatService} from "services/helpers.js";
import 'react-rangeslider/lib/index.css'
import moment from 'moment';
import 'moment-duration-format';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";
import 'styles/sessionInputPower.scss';
import 'styles/sessionInputSlotPeriod.scss';
import 'styles/common.scss'

export default function SessionInputSlotPeriod({outlet, initSessionPropsCB}) {
    const { userMe } = useContext(DataStoreContext);
    let tariff = outlet.tariff;
    const IS_DAY_MODE = tariff.slotPeriod === 24;
    const HAS_CHECKOUT_TIME = IS_DAY_MODE && tariff.slotEndHour && tariff.slotEndHour >= 0;
    const [ chargeHours, setChargeHours] = useState(0);
    const [ cost, setCost] = useState(0);
    const { t } = useTranslation('components');

    registerLocale('sv', sv);
    let LOGGED_IN_VIEW = userMe ? true : false;
    
    
    useEffect(() => {
        setChargeHours(tariff.slotPeriod);    
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [userMe]);


    useEffect(() => {
        let openingFee = (LOGGED_IN_VIEW) ? tariff.openingFee : tariff.singlePaymentOpeningFee;
        let numOfSlots = chargeHours/tariff.slotPeriod;
        let cost = openingFee + tariff.feeTimeslot * numOfSlots;
        
        setCost(cost);
        let sessionProps = {};
        sessionProps.sessionType = 'POWER';
        sessionProps.cost = cost;
        sessionProps.limitType = 'TIME';

        if (HAS_CHECKOUT_TIME) {
            let fullSlotHours = (numOfSlots - 1) * tariff.slotPeriod;
            sessionProps.limit = fullSlotHours*3600 + calculateLastSlotDuration();
            if (sessionProps.limit < 0) sessionProps.limit = 0; 
        }
        else sessionProps.limit = chargeHours*3600;
        console.log('session duration (s): ' + sessionProps.limit);
        
        sessionProps.allowDisconnect = true;
        initSessionPropsCB(sessionProps);

    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [chargeHours]);

    const formatAmount = (value) => {
        return value.toFixed(2);
    }

    /* If IS_DAY_MODE and the tariff has a slotEndHour specified, it means this outlet has a hard check-out time
       e.g. 15:00. So, if we at 14:00 buys one day of power we will just get 1h in reality. This method makes this calculation.
     */
    const calculateLastSlotDuration = () => {
        let currentHour = moment().hour();
        const sessionEnd = moment().set('hour', tariff.slotEndHour).set('m', 0).set('s', 0).set('ms', 0);
        if (currentHour >= tariff.slotEndHour) sessionEnd.add(1, 'days');        
        let lastSlotDurationInSeconds = sessionEnd.diff(moment(), 's');
        return lastSlotDurationInSeconds;
    }

    const stepDownChargeHours = () => {
        if (chargeHours > 0) setChargeHours(chargeHours-tariff.slotPeriod);
    }
    const stepUpChargeHours = () => {
        setChargeHours(chargeHours+tariff.slotPeriod);
    }

    const getDaySlotEndDate = () => {
        if (!IS_DAY_MODE || !tariff.slotEndHour ) return null; 
        let days = chargeHours / tariff.slotPeriod; // (slotperiod always 24 for DAY_MODE)
        let endTime = moment().add(days, 'days');
        let endHour = endTime.hour();
        if (endHour < tariff.slotEndHour) endTime.subtract(1, 'days');
        endTime.set('hour', tariff.slotEndHour).set('m', 0).set('s', 0).set('ms', 0); 
        return endTime.format('dddd HH:mm');
    }

    return (
        <>
            <div className="session-input-slot-info">
                <label className="centered-label">{ IS_DAY_MODE ? <span>{t('sessionInput.select_num_of_days')}</span> : <span>{t('sessionInput.select_num_of_hours')}</span> }</label>
                <div className="amount-row">
                    <FontAwesomeIcon icon={ faMinusCircle } className={(chargeHours === 0) ? 'amount-btn disabled': 'amount-btn'} onClick={stepDownChargeHours}/> 
                    <div className="amount">
                        { IS_DAY_MODE ? chargeHours/24 : chargeHours } 
                    </div>
                    <FontAwesomeIcon icon={ faPlusCircle }  className={(chargeHours === 500) ? 'amount-btn disabled': 'amount-btn'} onClick={stepUpChargeHours}/>
                </div>

                <div className="cost"><div>{formatAmount(cost)}</div><div>{FormatService.getCurrencyUnit('SEK')}</div></div> 

                <div className="slot-end-container">
                {HAS_CHECKOUT_TIME && chargeHours > 0 ? <div className="slot-end-date">{t('sessionInput.stop_checkout')} {getDaySlotEndDate()}</div> : null}
                </div>


            </div>
       
        </>
    );

}