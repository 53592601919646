import React, {useState, useContext, useEffect, useRef} from 'react';
import DataStoreContext from 'dataStoreContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import HelperService from "services/helpers.js";
import { faUserEdit, faCreditCard, faPlusSquare, faTrash, faCheckCircle, faSignOutAlt, faQuestionCircle, faCar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProfileForm from 'js/components/profileForm'
import AddVehicleForm from 'js/components/addVehicleForm'
import {FormatService} from 'services/helpers.js';
import AddCardForm from 'js/components/addCardForm.js'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import TopupAccountForm from 'js/components/topupAccountForm.js'
import PaymentReceipt from 'js/components/paymentReceipt.js'
import SessionReceipt from 'js/components/sessionReceipt.js'
import Form from 'react-bootstrap/Form'
//import { usePageVisibility } from 'react-page-visibility';
import cloneDeep from 'lodash/cloneDeep';
import Api from "services/api.js";
import Nav from 'react-bootstrap/Nav'
import 'styles/profileView.scss';
import 'styles/common.scss';
import visa from 'resources/visa.png';
import mastercard from 'resources/mastercard.png';
import ForgotPwForm from 'js/components/forgotPwForm'
import moment from 'moment';
import 'moment/locale/sv'

import {useStripe} from '@stripe/react-stripe-js';

// Translation
import { useTranslation } from 'react-i18next';


export default function ProfileView({toggleOverlayCB}) {
    const { userMe, setUserMe, paymentCards, setPaymentCards, transactions, setCompletedSessions, setActiveSessions, setInfoModal, paymentReceipts, setPaymentReceipts, memberships, setHelpTextModal, isFlutterApp, sendFlutterNotification, appInForeground} = useContext(DataStoreContext);
    const stripe = useStripe();
    const [editMode, setEditMode]  = useState(false); 
    const [addVehicle, setAddVehicle]  = useState(false); 
    const [vehicleUpdateOngoing, setVehicleUpdateOngoing]  = useState(false); 
    const [addPaymentCard, setAddPaymentCard]  = useState(false); 
    const [primaryCardId, setPrimaryCardId]  = useState(null); //id of primary card
    const [addPaymentCardError, setAddPaymentCardError]  = useState(null); 
    const [topupAccount, setTopupAccount]  = useState(false); 
    const [addTopupAccountError, setAddTopupAccountError]  = useState(null);
    const [paymentReceipt, setPaymentReceipt]  = useState(null);
    const [sessionReceipt, setSessionReceipt]  = useState(null);
    const [pendingReceiptId, setPendingReceiptId]  = useState(null);
   // const isVisible = usePageVisibility();

    const [processingPaymentInfo, setProcessingPaymentInfo]  = useState(false); 
    const [processingTopup, setProcessingTopup]  = useState(false); 
    const [changePassword, setChangePassword]  = useState(false); 
    const [activeTab, setActiveTab]  = useState('info'); // info || bank
    const [swishQrUrl, setSwishQrUrl]  = useState(null); 
    const [showAliasLoginForm, setShowAliasLoginForm]  = useState(false);
    const [aliasUserId, setAliasUserId]  = useState('');
    const [aliasLoginFormError, setAliasLoginFormError]  = useState(null);

    let timeoutRef = useRef();
    const counterRef = useRef(0);

    // Translation
    const { t, i18n } = useTranslation(['views','helpers']);
    moment.locale(i18n.language);
    
    const months = {
        "jan": t("helpers:jan"),
        "feb": t("helpers:feb"),
        "mar": t("helpers:mar"),
        "apr": t("helpers:apr"),
        "may": t("helpers:may"),
        "jun": t("helpers:jun"),
        "jul": t("helpers:jul"),
        "aug": t("helpers:aug"),
        "sep": t("helpers:sep"),
        "oct": t("helpers:oct"),
        "nov": t("helpers:nov"),
        "dec": t("helpers:dec"),
        
    }
    useEffect(() => {
        let pc = HelperService.getPrimaryPaymentCard(paymentCards);
        if (pc) setPrimaryCardId(pc.id);
        else { // no primary card is set, we just set the first one in the list
            if (paymentCards && paymentCards[0]) handleSetPrimaryCard(paymentCards[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [paymentCards]);

    useEffect(() => {

        if (pendingReceiptId) {
            checkPendingReceipt();
        }
        
    //}, [isVisible, swishQrUrl]);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [appInForeground, swishQrUrl]);


    /* The state of the payment is checked on visibility (when this view gets focus again) and 
        then periodically after that */
    const checkPendingReceipt = async () => {
        if (!pendingReceiptId) return;

        console.log(counterRef.current);

        if (counterRef.current > 20) {
            console.log('swish payment timed out');
            setPendingReceiptId(null);
            setProcessingTopup(false);
            setAddTopupAccountError(t("views:profileView.swishTimeOut"));
            counterRef.current = 0;
            setSwishQrUrl(null);
            return;
        }
        
        try {
            const receipt = await Api.getPaymentStatus(pendingReceiptId);
           // console.log(receipt);
            if (receipt && receipt.paymentStatus === 'SUCCESS') {
                let usage = userMe.usage;
                let updatedUserMe = await Api.getUserMe();
                updatedUserMe.usage = usage;
                setUserMe(updatedUserMe);
                setTopupAccount(false);
                setProcessingTopup(false);
                setPaymentReceipt(receipt);
                // just add this receipt to the top of the receipt list, no need to refetch from server
                let paymentReceiptsCopy = cloneDeep(paymentReceipts);
                if (paymentReceiptsCopy && paymentReceiptsCopy.length > 0) paymentReceiptsCopy.unshift(receipt);
                else paymentReceiptsCopy = [receipt];
                setPaymentReceipts(paymentReceiptsCopy);
                setPendingReceiptId(null);
                counterRef.current = 0;
            }
            else if (receipt && (receipt.paymentStatus === 'PENDING' || receipt.paymentStatus === 'UNKNOWN')) {
                //console.log('payment pending');
                counterRef.current++;
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                if (appInForeground) timeoutRef.current = setTimeout(checkPendingReceipt, 3000);
            }
            else {
                console.log('Payment cancelled');
                //setProcessingTopup(false);
                //setTopupAccount(false);
                setPendingReceiptId(null);
                counterRef.current = 0;
                setProcessingTopup(false);
                setAddTopupAccountError(t("views:profileView.swishCancelByUser"));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleLogOut = () => {
        console.log('LOGGING OUT');
        //localStorage.removeItem('accessToken');
        setCompletedSessions(null);
        setPaymentCards(null);
        setActiveSessions(null);
        setPaymentReceipts(null);
        setUserMe(null);
        toggleOverlayCB();
        //history.push("/");
    }

    const cancelEditMode = () => {
        setEditMode(false);
        setAddVehicle(false);
    }

    const formatPreferredLanguage = (lang) => {
        if (lang === 'sv') return t("views:profileView.sv");
        if (lang === 'en') return t("views:profileView.en");
    }

    const formatBalance = (account) => {
        if (!account) return '0';
        let sekAccount = account.find((acc) => acc.currency === 'SEK');
        if (!sekAccount) return '0';
        return FormatService.formatCurrency(sekAccount.balance, 2,  'SEK');
        
    }

    const handleVehicleChange = async (vehicle, removeIt=false) => {
        let modifiedVehicles = null;
        if (removeIt) {
            modifiedVehicles = userMe.vehicles.filter(v => v.regNo !== vehicle.regNo);
        }
        else {
            modifiedVehicles = [vehicle];
            if (userMe.vehicles && userMe.vehicles.length > 0) modifiedVehicles = modifiedVehicles.concat(userMe.vehicles);
            modifiedVehicles = modifiedVehicles.filter((v,i,a)=>a.findIndex(t=>(t.regNo===v.regNo))===i); 
        }
        
        setVehicleUpdateOngoing(true);
        try {
            const userMe = await Api.updateUserMe({vehicles: modifiedVehicles});

            if (userMe) {
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage);       
            }
            setVehicleUpdateOngoing(false);
            setAddVehicle(false);
        } catch (error) {
            console.log(error);
            setVehicleUpdateOngoing(false);
            setAddVehicle(false);
        }
    }

    const handleTopupAccount = async (paymentMethod, amount, paymentId) => {
        setAddTopupAccountError(null);
        if (paymentMethod === 'CARD') {
            let body = {
                paymentType: "CARD",
                paymentMethodId: paymentId,
                amount: amount,
                currency: "SEK"
            };
            try {
                setProcessingTopup(true);
                let receipt = await Api.topupAccount(userMe.userName, body);
               // console.log(receipt);
                let usage = userMe.usage;
                let updatedUserMe = await Api.getUserMe();
                updatedUserMe.usage = usage;
                setUserMe(updatedUserMe);
                setTopupAccount(false);
                setProcessingTopup(false);
                setPaymentReceipt(receipt);
                // just add this receipt to the top of the receipt list, no need to refetch from server
                let paymentReceiptsCopy = cloneDeep(paymentReceipts);
                if (paymentReceiptsCopy && paymentReceiptsCopy.length > 0) paymentReceiptsCopy.unshift(receipt);
                else paymentReceiptsCopy = [receipt];
                setPaymentReceipts(paymentReceiptsCopy);
            } catch (error) {
                console.log(error);
                setProcessingTopup(false);
                setAddTopupAccountError(t("views:profileView.paymentFailed") + '('+error.message+')' + t("views:profileView.tryAgain"));
            }
        }
        else if (paymentMethod === 'SWISH') {
            let body = {
                paymentType: "SWISH",
                amount: amount,
                currency: "SEK"
            }
            try {
                //setProcessingTopup(true);
                let receipt = await Api.topupAccount(userMe.userName, body);
                //console.log(receipt);
                if (receipt && receipt.receiptMetadata && receipt.receiptMetadata.swishToken) {
                    //setTopupAccount(false);
                    //setProcessingTopup(false);
                    setPendingReceiptId(receipt.id);

                   // console.log(receipt.receiptMetadata.swishToken);

                   // window.location = "swish://paymentrequest?token=" + receipt.receiptMetadata.swishToken + "&callbackurl=merchant%253A%252F%252F";
                    
                   let swishUrl = 'swish://paymentrequest?token=' + receipt.receiptMetadata.swishToken;

                    if (isFlutterApp) {
                        setProcessingTopup(true);
                        swishUrl += '&callbackurl=https://power.epspot.com';
                        let payload = '{"command": "LAUNCH_URL", "url":"' + swishUrl + '"}';
                        sendFlutterNotification(payload);
                    }
                    else {
                        window.location = swishUrl;
                    }
                }
            } catch (error) {
                console.log(error);
                setProcessingTopup(false);
                setAddTopupAccountError(t("views:profileView.paymentFailed") + '('+error.message+')' + t("views:profileView.tryAgain"));
            }
        }
        else {
            console.log(paymentMethod + 'not yet supported');
        }
    
    }

    const handlePcSwishCancel = async () => {
        setProcessingTopup(false);
        setSwishQrUrl(null);
        setPendingReceiptId(null);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }

    const handlePcSwish = async (amount) => {
        setAddTopupAccountError(null);
        let body = {
            paymentType: "SWISH",
            amount: amount,
            currency: "SEK"
        }
        try {
            setProcessingTopup(true);
            let receipt = await Api.topupAccount(userMe.userName, body);
            if (receipt && receipt.receiptMetadata && receipt.receiptMetadata.swishToken) {
                setPendingReceiptId(receipt.id);
                let qr = await Api.getSwishQrCode(receipt.receiptMetadata.swishToken);
                const blob = new Blob( [ qr ] );
                const url = URL.createObjectURL( blob );
                setSwishQrUrl(url);  
                //checkPendingReceipt();
                
                setProcessingTopup(false);
            }
        } catch (error) {
            console.log(error);
            setProcessingTopup(false);
            setAddTopupAccountError(t("views:profileView.paymentFailed") + '('+error.message+')' + t("views:profileView.tryAgain"));
        }
    }
    

    /* Change the primary card. This is a method that should never fail so we update 
     the local reference immediately and then call server. If server should fail anyway, we revert local reference.
    */
    const handleSetPrimaryCard = async (cardId) => {
        if (primaryCardId === cardId) return;
        let oldPrimary = primaryCardId;
        setPrimaryCardId(cardId);
        try {
            let updatedCards = await Api.setDefaultPaymentCard(userMe.userName, cardId);
            setPaymentCards(updatedCards.items);
        } catch (error) {
            console.log(error);
            setPrimaryCardId(oldPrimary);
        }
    }

    const showRemovePaymentCardModal = (card) => {
        console.log(card);
        setInfoModal([[
            t("views:profileView.removeCardConfirmation")], 
            () => () => {removePaymentCard(card)},
            () => () => {}
        ]);
    }
    const removePaymentCard = async (card) => {
        try {
            await Api.removePaymentCard(userMe.userName, card.id);
            const updatedCardList = await Api.getPaymentCards(userMe.userName);
            setPaymentCards(updatedCardList.items); 
        } catch (error) {
            console.log(error);
        }
    }
    
    const handleAddPaymentCard = async (card, nameOnCard) => {

        setProcessingPaymentInfo(true);
        setAddPaymentCardError(null);
        console.log('ADD!!!');
        //await new Promise(r => setTimeout(r, 2000));


        try {
            let codeRequest = await Api.getStripeCode(userMe.userName);
            if (!codeRequest.stripeClientSecret) throw new Error('someting is wrong');

            const result = await stripe.confirmCardSetup(codeRequest.stripeClientSecret, {
                payment_method: {
                  card: card,
                  billing_details: {
                    name: nameOnCard,
                  },
                }
            });

            if (result.error) {
                console.log(result.error);
                setAddPaymentCardError(t("views:profileView.addCardError") + ': ' + (result.error.message));
                setProcessingPaymentInfo(false);
            }
            else {
                console.log('Card Added');
                if (paymentCards.length === 0) {
                    console.log(result.setupIntent);
                    let updatedCardList = await Api.setDefaultPaymentCard(userMe.userName, result.setupIntent.payment_method);
                    setPaymentCards(updatedCardList.items);
                }
                else {
                    let updatedCardList = await Api.getPaymentCards(userMe.userName);
                    setPaymentCards(updatedCardList.items); 
                }
                setProcessingPaymentInfo(false);
                setAddPaymentCard(false);
            }

        } catch (error) {
            console.log(error);
            setAddPaymentCardError(t("views:profileView.addCardError"));
            setProcessingPaymentInfo(false);
        }
    }

    const handleClick = (event, type, providerId, typeId, extraData) => {     
        event.preventDefault();
        event.stopPropagation();   
        if (type === 'SESSION') {
            getV2Session(providerId, typeId);
        }
        if (type === 'LEGACY_SESSION') {
            getLegacySession(typeId);
        }
        if (type === 'TOPUP') {
            let receipt = paymentReceipts.find(r => r.receiptNumber === typeId);
            if (receipt) setPaymentReceipt(receipt);
        }
    };

    const getLegacySession = async (sessionId) => {
        try {
            let session = await Api.getSession(sessionId);
            setSessionReceipt(session);
        } catch (error) {
            console.log(error);
        }
    }

    const getV2Session = async (providerId, sessionId) => {
        try {
            let session = await Api.getEndedSessionV2(providerId, sessionId);
            setSessionReceipt(session);
        } catch (error) {
            console.log(error);
        }
    }

   /* const getProviderName = (providerId) => {
        if (userMe.basicProviders) {
            let provider = userMe.basicProviders.find(p => p.id === providerId);
            if (provider) return provider.name;
        }
        return providerId;
    }*/

    const renderPaymentCard = (card) => {
        return (
            <div key={card.id} className="saved-payment-card">
                { (card.card.brand === 'mastercard') ? <img src={mastercard} alt="Mastercard" /> : <img src={visa} alt="Visa" />}
                
                <div className="details">
                    <div className="last4"><span className="dots">...</span><span>{card.card.last4}</span></div>
                    <div className="valid">{t("views:profileView.validUntil")} {FormatService.formatMonth(card.card.exp_month, months)} {card.card.exp_year}</div>
                </div>
                <div className={(primaryCardId === card.id)?'primary-card-button active':'primary-card-button'} onClick={()=>handleSetPrimaryCard(card.id)}><FontAwesomeIcon icon={faCheckCircle}/></div>
                <Button className="remove-card-button" onClick={()=>showRemovePaymentCardModal(card)}><FontAwesomeIcon icon={faTrash}/></Button>

            </div>
        );
    }

    const renderTransactionType = (transaction) => {
        if (transaction.transactionType === 'SESSION_COST') {
            if (transaction.endedSessionId) {
                return <div className='click-target' onClick={(e)=>handleClick(e,'SESSION', transaction.providerRef, transaction.endedSessionId)}>{t('views:profileView.session_cost')}</div>;    
            }
            if (transaction.legacySessionId) {
                //return <div>Session Cost (<span>{transaction.legacySessionId}</span>)</div>;
                return <div className='click-target' onClick={(e)=>handleClick(e,'LEGACY_SESSION', null, transaction.legacySessionId, transaction.createdOn)}>{t('views:profileView.session_cost') +' (Lgcy)'}</div>;    
            }
        }

        if (transaction.transactionType === 'TOPUP') {
            if (transaction.receiptNumber) {
                return <div>{t('views:profileView.topup')} (<span className='click-target' onClick={(e)=>handleClick(e,'TOPUP', null, transaction.receiptNumber)}>{t('views:profileView.receipt')}</span>)</div>;    
            }
                return <div>{t('views:profileView.topup')}</div>;          
        }

        if (transaction.transactionType === 'DEDUCT') {
            if (transaction.receiptNumber) {
                return <div>{t('views:profileView.manual_deduction')} (<span className='click-target' onClick={(e)=>handleClick(e,'TOPUP', null, transaction.receiptNumber)}>{t('views:profileView.receipt')}</span>)</div>;    
            }
                return <div>{t('views:profileView.manual_deduction')}</div>;          
        }

        if (transaction.transactionType === 'MANUAL_TOPUP') {
            if (transaction.receiptNumber) {
                return <div>{t('views:profileView.manual_topup')} (<span className='click-target' onClick={(e)=>handleClick(e,'TOPUP', null, transaction.receiptNumber)}>{t('views:profileView.receipt')}</span>)</div>;    
            }
                return <div>{t('views:profileView.manual_topup')}</div>;    
                
        }
        if (transaction.transactionType === 'REFUND') {
            if (transaction.receiptNumber) {
                return <div>{t('views:profileView.refund')} (<span className='click-target' onClick={(e)=>handleClick(e,'TOPUP', null, transaction.receiptNumber)}>{t('views:profileView.receipt')}</span>)</div>;    
            }
                return <div>{t('views:profileView.refund')}</div>;    
                
        }
        if (transaction.transactionType === 'LEGACY_EXPIRED') {
                return <div>Account Expired, set to 0</div>;    
        }
        if (transaction.transactionType === 'RESERVED') {
                return <div>{t('views:profileView.ongoing_sessions')}</div>;    
        }
        return <span>{transaction.transactionType}</span>;
    } 

    

    const renderTransactionMonth = (transaction, currentBalance) => {
        let monthNum = transaction.month.substring(5);
        let year = transaction.month.substring(0,4);
        monthNum = parseInt(monthNum);

        return (
            <Card className="month-transaction-item" key={transaction.month}>
                <Accordion.Toggle className="session-summary" as={Card.Header} eventKey={transaction.month}>
                    <div className="">
                        <div className="top-row">
                            <div className="month">{FormatService.formatMonth(monthNum, months)} {year}</div>
                            <div className="balance">{FormatService.formatCurrency(currentBalance, 2,  'SEK')}</div>
                        </div>

                        <div className="body-section">
                            <div className="section-item">
                                <span className="title">{t('views:profileView.session_costs')}:</span> <span className="value">{FormatService.formatCurrency(100*transaction.sessionCostSum, 2,  'SEK')}</span>
                            </div>

                            <div className="section-item">
                                <span className="title">{t('views:profileView.topup_sum')}:</span> <span className="value">{FormatService.formatCurrency(100*transaction.topupSum, 2,  'SEK')}</span>
                            </div>

                        </div>
                    </div>                    
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={transaction.month}>
                <Card.Body className="transaction-list-details">
                    <div>
                    
                        <table>
                        <thead>
                          <tr>
                            <th>{t('views:profileView.date')}</th>
                            <th>{t('views:profileView.debit')}</th>
                            <th>{t('views:profileView.credit')}</th>
                            <th>{t('views:profileView.balance')}</th>
                          </tr>
                        </thead>
                        { transaction.transactions.map(t =>   
                          {
                            let row = (
                            <>
                            <tbody className='content-row'>
                                <tr className='balance-row'>
                                    <td>{moment(t.createdOn).format('L')}</td>
                                    <td className='deb'>{t.type === 'DEBIT' ? t.amount.toFixed(2) : null}</td>
                                    <td className='cred'>{t.type === 'CREDIT' ? t.amount.toFixed(2) : null}</td>
                                    <td className='balance-col'>{FormatService.formatCurrency(currentBalance, 2,  'SEK')}</td>
                                </tr>
                                <tr className='desc-row'>
                                    <td colSpan={4}>{renderTransactionType(t)}</td>
                                </tr>    
                            </tbody>
                            <tbody><tr className='empty-row'><td colSpan={4}></td></tr></tbody>
                            </>
                            );
                            if (t.type === 'CREDIT') currentBalance -= t.amount * 100.0;
                            if (t.type === 'DEBIT') currentBalance += t.amount * 100.0;
                            return row;
                          }
                        )}
                      </table>
                    
                </div>
                </Card.Body>
                </Accordion.Collapse>
            </Card>


            
        );
    }

    const renderPaymentCards = () => {
        if(!paymentCards || paymentCards.length === 0) return (<div style={{position: 'absolute', marginTop: '14px'}}>{t("views:profileView.noConnectedCard")}</div>);
        return (
            <div>
                { paymentCards.map(card => 
                    renderPaymentCard(card)
                )}
            </div>
        );
    }

    const renderTransactions = () => {
        if(!transactions || transactions.length === 0) return (<div style={{position: 'absolute', marginTop: '14px'}}>{'no transactions'}</div>);
        let monthEndBalance = userMe.account.find((acc) => acc.currency === 'SEK').balance;
        return (
            <Accordion defaultActiveKey="0">
                    { transactions.map(t => 
                    {
                        let monthStartBalance = monthEndBalance;
                        for (const item of t.transactions) {
                            if (item.type === 'CREDIT') monthStartBalance -= item.amount * 100.0;
                            if (item.type === 'DEBIT') monthStartBalance += item.amount * 100.0;
                        }
                        let result = renderTransactionMonth(t, monthEndBalance);
                        monthEndBalance = monthStartBalance;
                        return result;
                    }
                )}
            </Accordion>
        );
    }


    const loginAs = async () => {
        setAliasLoginFormError(false);
        try {
            const token = await Api.aliasLogin(aliasUserId);
            if (token && token.token) {
                handleLogOut();

                Api.accessToken = token.token;
                const userMe = await Api.getUserMe();
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage); 
                localStorage.setItem('accessToken', token.token);
                setShowAliasLoginForm(false);
            }
            
        } catch (error) {
            console.log('error');
            setAliasLoginFormError(error.message);
        }
    }

    const renderSuperadmin = () => {
        if (!userMe.roles) return null;
        let roles = Object.keys(userMe.roles);
        if (!roles.includes('superadmin')) return null;
        return (
            <div className="prop-touple">
                <div className="superadmin-label" onClick={()=>{setShowAliasLoginForm(true)}}>Superadmin</div>
            </div>
        );
    }

    const renderMemberships = () => {
        if (!memberships || memberships.length===0) return null;
        return (
            <div className="prop-touple">
                <div className="prop-touple-label"  onClick={()=>setHelpTextModal(['account_membership:membership'])}>
                        {t("views:profileView.myMemberships")} <span style={{marginLeft:'20px'}} className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </div>
                    <div className="prop-touple-value">
                        { memberships.map((info, index) => 
                            renderMemberInfo(info, index)
                        )}
                </div>
            </div>
        );
    }

    const renderVehicles = () => {
        if (!userMe.vehicles || userMe.vehicles.length===0) {
            return (
                <div className="prop-touple">
                    <div className="prop-touple-label">{t("views:profileView.myVehicles")} </div>
                    <div className="prop-touple-value">
                        -
                    </div>
                </div>
            );    
        }
        return (
            <div className="prop-touple">
                <div className="prop-touple-label">{t("views:profileView.myVehicles")} </div>
                    <div className="prop-touple-value vehicle-list">
                        { userMe.vehicles.map((vehicle, index) => 
                            <div className="license-plate">
                            <div className="removable-plate" key={index}>
                                <div className="reg-no">{renderRegNo(vehicle.regNo)}</div>
                                <Button className="remove-card-button" onClick={()=>{handleVehicleChange(vehicle, true)}}><FontAwesomeIcon icon={faTrash}/></Button> 
                            </div>
                            { vehicle.name ? <div className='vehicle-name'>{vehicle.name}</div> : null}
                            </div>
                        )}
                </div>
            </div>
        );
    }

    const renderRegNo = (regNo) => {
        if (!regNo) return null;
        if (regNo.length === 6) {
            let firstPart = regNo.substr(0,3);
            let secondPart = regNo.substr(3,5);
            return (<><span>{firstPart}</span><span>{secondPart}</span></>);
        }
        return(<span>{regNo}</span>)
    };

    /* Info object from membership structure: 
        {
            "memberId": "47817e28-b5c4-46a8-accf-7990f4266ced",
            "providerId": "fc6bc21d-3d30-4556-ad83-6f93fc312d73",
            "accesses": [
                {
                    "accessId": "b5182512-140e-4ba5-ab1c-f3526e97d9c9",
                    "accessName": "oatest"
                }
            ],
            "providerName": "EpSpot HQ"
        }

        <div  className="acc-label">{t("views:profileView.accesses")}</div>
     */
    const renderMemberInfo = (info, index) => {
        return (
            <div className="membership" key={index}>
                <div className="membership-name">{info.providerName}</div>
                { info.accesses && info.accesses.length > 0 ? 
                        <div  className="accesses">
                        
                        <div className="prop-touple-label"  onClick={()=>setHelpTextModal(['account_membership:member-access'])}>
                        {t("views:profileView.accesses")} <span style={{marginLeft:'20px'}} className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span>
                        </div>
                        { 
                            info.accesses.map((acc) => 
                                <div key={acc.accessId}  className="acc-name">{acc.accessName}</div>
                            )
                        }    
                    </div>  
                    : null
                }
                
            </div>
        )
    }

    const renderEditMode = () => {
        return (
            <>
            <div className="button-heading">
                    <h1>{t("views:profileView.editProfile")}</h1>
            </div>
            <ProfileForm user={userMe} cancelCB={cancelEditMode}/>
            </>
        );
    }

    const renderVehicleForm = () => {
        return (
            <>
            <div className="button-heading">
                    <h1>{t("views:profileView.add_vehicle")}</h1>
            </div>
            <AddVehicleForm submitCB={handleVehicleChange} cancelCB={cancelEditMode} submitting={vehicleUpdateOngoing}/>
            </>
        );
    }

    const renderBasicInfoTab = () => {
        return (
            <>
                <div className="prop-touple-action">
                <div className="prop-touple">
                    <div className="prop-touple-label">
                        {t("views:profileView.name")}
                    </div>
                    <div className="prop-touple-value">
                        {userMe.firstName} {userMe.lastName}
                    </div>
                </div>

                <Button className="ovale-button" onClick={()=>{setEditMode(true)}}><FontAwesomeIcon icon={ faUserEdit } /> {t("views:profileView.edit")}</Button>

                </div>

                <div className="prop-touple">
                    <div className="prop-touple-label">
                    {t("views:profileView.email")}
                    </div>
                    <div className="prop-touple-value">
                        {userMe.userName}
                    </div>
                </div>

                {renderSuperadmin()}

                <div className="prop-touple">
                    <div className="prop-touple-label">
                    {t("views:profileView.mobile")}
                    </div>
                    <div className="prop-touple-value">
                        {userMe.phone}
                    </div>
                </div>

                <div className="prop-touple">
                    <div className="prop-touple-label">
                    {t("views:profileView.lng")}
                    </div>
                    <div className="prop-touple-value">
                        { formatPreferredLanguage(userMe.preferredLanguage) }
                    </div>
                </div>

                <div className="prop-touple">
                    <div className="prop-touple-label">
                    {t("views:profileView.password")}
                    </div>
                    <div className="prop-touple-value">
                        <span style={{fontWeight:900}}>&#183;&#183;&#183;&#183;&#183;&#183;</span> <span className="change-pw-link" onClick={()=>{setChangePassword(true)}}>{t("views:profileView.changePassword")}</span>
                    </div>
                </div>

                <div className="prop-touple">
                    <div className="prop-touple-label">
                    <div className="prop-touple-label"  onClick={()=>setHelpTextModal(['account_membership:newsletter'])}>
                        {t("views:profileView.newsletter")} <span style={{marginLeft:'20px'}} className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </div>
                    </div>
                    <div className="prop-touple-value">
                        { userMe.subscribedToNewsletter ? t("views:profileView.yes") : t("views:profileView.no") }
                    </div>
                </div>

                <div className="prop-touple-action" style={{alignItems: 'baseline'}}>
                    {renderVehicles()}

                    <Button className="ovale-button add-card" onClick={()=>{setAddVehicle(true)}}><FontAwesomeIcon icon={ faCar } /> {t("views:profileView.add_vehicle")}</Button>
                </div>

                {renderMemberships()}

            </>
        );
    }
    
    const renderBalanceTab = () => {
        return (
            <>
            <div className="prop-touple balance-prop">
                    <div className="prop-touple-label">
                        {t("views:profileView.balance")}
                    </div>
                    <div className="prop-touple-action-value">
                        <div className="prop-touple-value balance">
                            { formatBalance(userMe.account) }
                        </div>
                        <div className="prop-touple-action">
                        <Button className="ovale-button topup" onClick={()=>{setTopupAccount(true)}}><FontAwesomeIcon icon={ faPlusSquare } />  {t("views:profileView.refill")}</Button>
                        </div>
                    </div>
                </div>
                <div className="prop-touple">
                    <div className="prop-touple-label">
                    {t("views:profileView.connectedDebitCards")}
                    </div>
                    <div className="prop-touple-value">
                        {renderPaymentCards()}
                    </div>
                    <Button className="ovale-button add-card" onClick={()=>{setAddPaymentCard(true)}}><FontAwesomeIcon icon={ faCreditCard } /> {t("views:profileView.addCard")}</Button>
                </div>

                <div className="prop-touple">
                    <div className="prop-touple-label" style={{display:'flex', marginTop:'20px'}}>
                    <span>{t('views:profileView.transactions')}</span>
                    <span style={{marginLeft:'auto'}}>{t('views:profileView.balance_short')}</span>
                    </div>
                    <div className="prop-touple-value">
                        {renderTransactions()}
                    </div>                    
                </div>
            </>
        );
    }

 
    const renderViewMode = () => {
        return (
            <>
                <div className="button-heading">
                    <h1>{t("views:profileView.myProfile")}</h1>
                        <Button className="edit-button" onClick={handleLogOut}><FontAwesomeIcon icon={ faSignOutAlt } /> {t("views:profileView.logOut")}</Button>
                    
                </div>

                <Nav fill defaultActiveKey="info" onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                    <Nav.Item>
                        <Nav.Link eventKey="info"><div className="nav-header"><span className="header-text">{t("views:profileView.personalData")}</span></div></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="bank"><div className="nav-header"><span className="header-text">{t("views:profileView.balanceAndPayment")}</span></div></Nav.Link>
                    </Nav.Item>
                </Nav>

                

                { (activeTab === 'info') ? renderBasicInfoTab(): null}
                { (activeTab === 'bank') ? renderBalanceTab(): null}

                { ( addPaymentCard )? 
                    <AddCardForm errorText={addPaymentCardError} name={userMe.firstName + ' ' + userMe.lastName} submitCB={handleAddPaymentCard} submitting={processingPaymentInfo} cancelCB={()=>{setAddPaymentCard(false);setAddPaymentCardError(null)}} />:null 
                }
                { ( topupAccount )? 
                    <TopupAccountForm qrUrl={swishQrUrl} errorText={addTopupAccountError} primaryCardId={primaryCardId} paymentCards={paymentCards} pcSwishCB={handlePcSwish} pcSwishCancelCB={handlePcSwishCancel} submitCB={handleTopupAccount} submitting={processingTopup} cancelCB={()=>{setTopupAccount(false);setAddTopupAccountError(null)}} />:null 
                }
                { ( paymentReceipt )? 
                    <PaymentReceipt receipt={paymentReceipt} modal={true} closeCB={()=>{setPaymentReceipt(false)}}/>:null 
                } 
                { ( sessionReceipt )? 
                    <SessionReceipt session={sessionReceipt} modal={true} closeCB={()=>{setSessionReceipt(false)}}/>:null 
                } 
                { ( changePassword )? 
                    <ForgotPwForm showLogin={()=>{}} modal={true} loginData={{email:userMe.userName}} cancelCB={()=>{setChangePassword(false)}} toggleOverlayCB={()=>{}}/>:null 
                }
                
            </>
        );
    }

    const handleAliasCancel = () => {
        setAliasLoginFormError(null);
        setShowAliasLoginForm(false);
    }
    

    if (!userMe) return null;
    return (
            <div className="profile-view">
                <div className="photo-header-bg"> <div className="cover-color"/> </div>

                <div className="content-container">

                

                
                
                { (!editMode && !addVehicle) ? renderViewMode() : null }

                { editMode ? renderEditMode() : null }

                { addVehicle ? renderVehicleForm() : null }
                
                
                </div>

                {
                    !showAliasLoginForm ? null :
                    <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show onHide={() => { setShowAliasLoginForm(false) }}>
                        <Modal.Body className="info-modal">
                            <p>Log In as another User</p>
                            <Form.Group>
                                <Form.Label>User Id (email)</Form.Label>
                                <Form.Control size="lg" id="userId" name="userId" type="text" placeholder='john@example.com' value={aliasUserId}
                                    onChange={(e) => setAliasUserId(e.target.value)} />
                            </Form.Group>
                            <p style={{color:'red', fontSize:'14px', fontWeight:'bold'}}>{aliasLoginFormError ? aliasLoginFormError : <span>&nbsp;</span>}</p>
                            <div className="button-row">
                                <Button onClick={() => { loginAs() }} disabled={aliasUserId.length < 4 || !aliasUserId.includes('@')}>OK</Button>
                                <Button className="cancel-btn" onClick={() => { handleAliasCancel() }}>Cancel</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                

            </div>
    );
}