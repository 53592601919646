import React, {useContext} from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { faUserEdit,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import ProgressButton from 'js/components/progressButton'
import DataStoreContext from 'dataStoreContext'
import Api from "services/api.js";
import Toggle from 'react-toggle';
import 'styles/loginForm.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
            .trim()
            .email("Detta är inte en giltig mailadress")
            .required("Du måste ange en mailadress")
  });

export default function LoginForm({loginData, showRegistration, showForgotPw, toggleOverlayCB}) {
    const {setUserMe} = useContext(DataStoreContext);

    const { t, i18n } = useTranslation("components");
    
    const handleCancel = (event) => {
        console.log.apply("handleCancel")
        event.preventDefault();
        toggleOverlayCB();
    }

    const handleSubmitClick = async (values, setSubmitting, setErrors) => {
        try {
            let email = values.email.trim();
            
            const token = await Api.logIn(email, values.password);
            
            if (token && token.token) {
                Api.accessToken = token.token;
                const userMe = await Api.getUserMe();
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage); 
                if (values.rememberMe) {
                    console.log('store access token');
                    localStorage.setItem('accessToken', token.token);
                }
                toggleOverlayCB();
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            if (error.code === 2) setErrors({email: t("loginForm.user_not_registered")});
            if (error.code === 3) setErrors({password: t("loginForm.invalid_password")});
            setSubmitting(false);
        }
    }

    return (
            <div className="login-form">
                <div className="photo-header-bg"> <div className="cover-color"/> </div>
                <div className="content-container">

                <div className="button-heading">
                    <h1>{t("loginForm.log_in")}</h1>
                    <div className="edit-button-with-label">
                        <span>{t("loginForm.no_account")}</span>
                    <Button className="edit-button" onClick={showRegistration}><FontAwesomeIcon icon={ faUserEdit } />{t("loginForm.register")}</Button>
                    </div>
                </div>


                <p className="welcome-text">{t("loginForm.welcome_text")}</p>
                <Formik
                    initialValues={{ email: loginData ? loginData.email : "", password: loginData ? loginData.password : "", rememberMe: true, showPassword: true }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        handleSubmitClick(values, setSubmitting, setErrors);
                    }}
                    validationSchema={LoginSchema}
                    
                >
                    { props => {
                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, status } = props;

                        return (
                            <>
                            <Form  onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{t("loginForm.email")}</Form.Label>
                                    <Form.Control size="lg" id="email" name="email" type="text" placeholder={t("loginForm.email_placeholder")} value={values?.email?.trim()}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email && "error"} />
                                    {errors.email && touched.email && (<Form.Text className="input-feedback"> {errors.email} </Form.Text>)}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("loginForm.password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control size="lg" id="password" name="password" type={values.showPassword ? "text": "password"} placeholder={t("loginForm.password_placeholder")} value={values.password}
                                                      onChange={handleChange} onBlur={handleBlur} className={errors.password && touched.password && "error"}/>
                                        <Form.Text className="showPasswordIcon"> {values.showPassword ? <FontAwesomeIcon onClick={ () => setFieldValue("showPassword", false)} 
                                                   icon={faEyeSlash}/>:<FontAwesomeIcon onClick={ () => setFieldValue("showPassword", true) } icon={faEye}/>} </Form.Text>
                                    </InputGroup>
                                    {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}  
                                    {errors.password && touched.password && (<Form.Text className="input-feedback password"> {errors.password} </Form.Text>)}   

                                </Form.Group>

                                <Form.Group style={{display: 'flex'}}>
                                    <Toggle name="rememberMe" icons={{ checked: null, unchecked: null }} onChange={handleChange} checked={values.rememberMe}/>
                                    <Form.Label className="toggle-label">{t("loginForm.remember_me")}</Form.Label>
                                    <div className="forgot-pw-button" onClick={showForgotPw}>{t("loginForm.forgot_password")}</div>
                                </Form.Group>
                                

                                <div className="confirm-cancel-button-row">
                                    <ProgressButton disabled={ errors.email || (values?.password?.length < 3 || errors.password) }  
                                        className="login-button" text={t("loginForm.log_in")} type="button" loading={isSubmitting} clickCB={handleSubmit}/>
                                    <Button className="cancel-button" type="cancel" onClick={(e)=>{handleCancel(e)}}>{t("loginForm.cancel")}</Button>    
                                </div>
                            </Form>
                            </>
                        );
                    }}
                </Formik>
                </div>
            </div>
    );
}