import React from 'react';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import TitleBar from 'js/components/titleBar.js';


import Api from "services/api.js";
import { Formik } from "formik";
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import ProgressButton from 'js/components/progressButton'

import 'styles/loginForm.scss';
import 'styles/registrationView.scss';
import 'styles/common.scss';
import 'styles/scanOutletView.scss';


export default function SetPasswordView() {

        const { t } = useTranslation('views');
        let history = useHistory();
   
        const handleCancelClick = () => {
            history.push("/");
        }
       


        const handleSubmit = async (value, { setSubmitting, setErrors }) => {
            try {
                let response = await Api.confirmRequest(value);
                console.log(response);
                setSubmitting(false);
            } catch (error) {
                console.log('CREATING ACCOUNT FAILED');
            }
            
        }


        return (
            <div className="sliding-view">
                <div className="bg"></div>
                <div className="cover"></div>
                <TitleBar icon={faChevronLeft} clickCB={handleCancelClick} title={t("setPwForm.title")}/>
              
                <div className="login-form" show aria-labelledby="contained-modal-title-vcenter">
                        <div className="content">
                  
                    <Formik
                        initialValues={{ email: '', password: '', showPassword: false }}
                        onSubmit={handleSubmit}
                        // validationSchema={LoginSchema}
                    >
                        {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, status, setFieldValue }) => (
                            <Form style={{marginTop:'30px'}} onSubmit={handleSubmit}>

                                <Form.Group>
                                <Form.Text style={{textAlign:'center', marginTop:'30px', marginBottom:'30px', color:'#555555'}}> {t("setPwForm.existing_account", { firstName: values.firstName, email: values.email })}</Form.Text> 
                                </Form.Group>
                 
                                <Form.Group>
                                    <Form.Label>{t("setPwForm.new_password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control size="lg" id="password" name="password" type={values.showPassword ? "text" : "password"} placeholder={t("setPwForm.pw_placeholder")} value={values.password}
                                            onChange={handleChange} onBlur={handleBlur} className={errors.password && touched.password && "error"}/>
                                        <Form.Text className="showPasswordIcon" name="showPassword"> {values.showPassword ? <FontAwesomeIcon onClick={() => setFieldValue("showPassword", false)} icon={faEyeSlash} /> : <FontAwesomeIcon onClick={() => setFieldValue("showPassword", true)} icon={faEye} />} </Form.Text>
                                        {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}     
                                    </InputGroup>
                                    {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}
                                    {errors.password && touched.password && (<Form.Text className="input-feedback password"> {errors.password} </Form.Text>)}
                                </Form.Group>
                                
                            
    
                                <div className="confirm-cancel-button-row">
                                    <ProgressButton disabled={( !(values && values.email && values.email.length > 0) || errors.email) || (!touched.password || errors.password) } 
                                        className="login-button" text={t("setPwForm.set_pw")} loading={isSubmitting} clickCB={handleSubmit}/>
                                    <Button className="cancel-button" type="cancel" onClick={handleCancelClick}>{t("setPwForm.cancel")}</Button>    
                                </div>
                                <Form.Text style={{textAlign:'center', marginTop:'-30px', marginBottom:'10px', color:'#555555'}}> {t("setPwForm.activation_info")}</Form.Text>    
                            </Form>
                        )}
                    </Formik>

                    </div>
             
                </div>
            </div>
        );
    }