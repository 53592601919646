import React, { useEffect, useContext } from 'react';
import DataStoreContext from 'dataStoreContext';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TitleBar from 'js/components/titleBar.js';
import 'styles/aboutView.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';

export default function AboutView(props) {
  const { t } = useTranslation('views');
  let history = useHistory();
  const { isFlutterApp, sendFlutterNotification } = useContext(DataStoreContext);

  useEffect(() => {
    props.toggleMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackClick = () => {
    history.push('/');
  };

  const openUrl = (url) => {
    if (isFlutterApp) {
        let payload = '{"command": "LAUNCH_URL", "url":"' + url + '"}';
        sendFlutterNotification(payload);
    }
    else {
        window.open(url);
    }
  };

  return (
    <div className="sliding-view about-view">
      <div className="bg"></div>
      <div className="cover"></div>
      <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={t('aboutView.about')} />

      <div className="content">
        <h1>{t('aboutView.companyInfo')}</h1>
        <div className="title">{t('aboutView.name')}</div>
        <div className="value">EpSpot AB</div>

        <div className="title">{t('aboutView.vatRegistrationNumber')}</div>
        <div className="value">SE556965539101</div>

        <div className="title">{t('aboutView.address')}</div>
        <div className="value multiline">
          <span>Aluddsparken 7D</span>
          <span>112 65 Stockholm</span>
          <span>{t('aboutView.sweden')}</span>
        </div>

        <h1>{t('aboutView.contactUs')}</h1>
        <div className="title">{t('aboutView.email')}</div>
        <div className="value">support@epspot.com</div>

        <div className="title">{t('aboutView.phone')}</div>
        <div className="value">+46 (0)8 51970808</div>

        <div className="title">{t('aboutView.web')}</div>
        <div className="value">
          <div className="link" style={{marginTop:'0'}} onClick={()=>openUrl('https://www.epspot.com')}>
            www.epspot.com
          </div>
        </div>

        <h1>{t('aboutView.termsAndConditions')}</h1>
        <div className="value">
          <div className="link" onClick={()=>openUrl('https://www.epspot.com/tos')}>
            {t('aboutView.termsOfUse')}
          </div>
        </div>
        <div className="value">
        <div className="link" onClick={()=>openUrl('https://www.epspot.com/cookie-policy/')}>
            {t('aboutView.privacyPolicy')}
          </div>
        </div>
      </div>
    </div>
  );
}
