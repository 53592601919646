import React from 'react';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export default function ProgressButton({text, loading, fullWidth, clickCB, disabled, className, type}) {


  return (
      (loading) ?
        <Button className={className} block={fullWidth} disabled><Spinner style={{color:'white'}} animation="border" /></Button> :
        <Button className={className} disabled={disabled} block={fullWidth} onClick={clickCB} type={type}>{text}</Button>
      
    
  );
}