import React, { useState, useEffect, useContext } from 'react';
import DataStoreContext from 'dataStoreContext'
import Slider from 'react-rangeslider'
import HelperService, {FormatService} from "services/helpers.js";
import 'react-rangeslider/lib/index.css'
import moment from 'moment';
import 'moment-duration-format';
import {IconBattery, IconClock} from 'resources/icons.js';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Toggle from 'react-toggle';
import { CSSTransition } from 'react-transition-group';
import { registerLocale } from  "react-datepicker";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import sv from 'date-fns/locale/sv';
import { useTranslation } from 'react-i18next';
import DelayedStartInput from 'js/components/delayedStartInput';
import cloneDeep from 'lodash/cloneDeep';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'


import "react-datepicker/dist/react-datepicker.css";
import 'styles/sessionInputPower.scss';
import 'styles/common.scss'

//sessionType = 'CHARGE' || 'POWER'
export default function SessionInputPower({outlet, sessionType, initSessionPropsCB}) {
    const { userMe } = useContext(DataStoreContext);
    const tariff = outlet.tariff;
    const [sliderData, setSliderData] = useState({time:0, kWh: 0, cost: 5000});
    const [timePickerMode, setTimePickerMode] = useState(true);
    const [delayedStartTime, setDelayedStartTime] = useState(moment());
    const [enableDepartureTime, setEnableDepartureTime] = useState(false);
    const [enablePowerAmount, setEnablePowerAmount] = useState(false);
    const [enableRegInput, setEnableRegInput] = useState(false);
    const [allowDisconnect, setAllowDisconnect] = useState(false);
    const [selectedRegNumber, setSelectedRegNumber] = useState(null);

    const { t } = useTranslation('components');

    registerLocale('sv', sv);

    let LOGGED_IN_VIEW = userMe ? true : false;
    let tariffFeeType = HelperService.getTariffFeeType(tariff);
    if (HelperService.isZeroTariff(tariff)) LOGGED_IN_VIEW = true;


    useEffect(() => {
        let storageData = (sessionType === 'CHARGE') ? window.localStorage.getItem('CHARGE_DATA') : window.localStorage.getItem('POWER_DATA');
        if (storageData){
            storageData = JSON.parse(storageData);
            if (storageData.hasOwnProperty('allowDisconnect')) setAllowDisconnect(storageData.allowDisconnect);
            if (storageData.hasOwnProperty('timePickerMode')) setTimePickerMode(storageData.timePickerMode);

            if (storageData.hasOwnProperty('startHour') && storageData.hasOwnProperty('startMinute')) {
                let delayedStartTimeCopy = cloneDeep(delayedStartTime);
                delayedStartTimeCopy.hour(storageData.startHour);
                delayedStartTimeCopy.minute(storageData.startMinute);
                setDelayedStartTime(delayedStartTimeCopy);
            }
        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, []);

      
    useEffect(() => {
        //sliderChange(0);
        setEnablePowerAmount(false);    
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [userMe]);

    useEffect(() => {
        // each time we toggle between kWh and time we call the slider so that the values are recalculated
        sliderChange({time: sliderData.time});    
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [timePickerMode]);

    useEffect(() => {
        sliderChange(sliderData);    
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [enablePowerAmount, enableDepartureTime, delayedStartTime, allowDisconnect, selectedRegNumber]);

    // inputData {time: xxx} | {cost: xxx} | {kWh: xxx}
    const sliderChange = (inputData) => {
        var sliderData = {};
        /* console.log(inputData);
        console.log(inputData.time);
        console.log(!!inputData.time); */
        
        if (inputData.time) {
            sliderData.time = inputData.time;
            let maxChargeKW = HelperService.getMaxCharge_kW(outlet);
            sliderData.kWh = (maxChargeKW * inputData.time) /  3600.0;
            sliderData.cost = HelperService.getTotalCost(sliderData, tariff, LOGGED_IN_VIEW);
            sliderData.timePickerMode = timePickerMode;
        }
        else if (inputData.cost) {
            sliderData.cost = inputData.cost;
            if (tariffFeeType === 'KWH_TARIFF') {
                sliderData.kWh = inputData.cost/(100*tariff.feeKwh);
            }
            else if (tariffFeeType === 'HOUR_TARIFF') {
                sliderData.time = 3600.0 * inputData.cost/(100*tariff.feeHour);
            }
        }

        else {
            sliderData.cost = 0;
            sliderData.time = 0;
            sliderData.kWh = 0;
        }        

        var request = {};
        request.outletId = outlet.outletId;
        request.totalAmount = sliderData.cost/100.0;
        request.locale = 'sv';
        request.currency =  'SEK';


        setSliderData(sliderData);

        if (tariffFeeType === 'HOUR_TARIFF') request.minutes = parseInt((sliderData.time / 60) + 0.5);
        else if (tariffFeeType === 'KWH_TARIFF') request.kWh = Math.ceil(sliderData.kWh);

        let sessionProps = {};
        sessionProps.sessionType = sessionType;
        sessionProps.cost = sliderData.cost/100.0;
        
        if (enablePowerAmount || !LOGGED_IN_VIEW) {
            if (tariffFeeType === 'HOUR_TARIFF') {
                sessionProps.limitType = 'TIME';
                sessionProps.limit = Math.ceil(sliderData.time);
            }
            else if (tariffFeeType === 'KWH_TARIFF') {
                sessionProps.limitType = 'ENERGY';
                sessionProps.limit = Math.ceil(sliderData.kWh * 1000000);
            }
            else { // COMBO_TARIFF
                if (!LOGGED_IN_VIEW) {
                    // combo-tariff for unregistered user shall stop when cost is reached
                    sessionProps.limitType = 'NONE';
                }
                else {
                    if (timePickerMode) {
                        sessionProps.limitType = 'TIME';
                        sessionProps.limit = Math.ceil(sliderData.time);
                    }
                    else {
                        sessionProps.limitType = 'ENERGY';
                        sessionProps.limit = Math.ceil(sliderData.kWh * 1000000);
                    }
                }
                   
            }
        }
        else {
            sessionProps.limitType = 'NONE';
        }
        

        if (enableDepartureTime) {
            request.expectedDepartureTime =  1000 * delayedStartTime.unix(); 
            sessionProps.delayedStartTime = delayedStartTime.format();  
        }

        //allowDisconnect is only for POWER session type
        if (allowDisconnect) sessionProps.allowDisconnect = allowDisconnect;
        if (selectedRegNumber) sessionProps.metadata = {regNo: selectedRegNumber};
        initSessionPropsCB(sessionProps);
    }

    const renderCostPicker = () => {
        return (
            <div className="slider-value-row">
                <div className="slider-value-group">
                    <div className="primary-info">
                        <div className="duration">
                            <span>{formatAmount(sliderData.cost)}</span>
                            <span>{FormatService.getCurrencyUnit('SEK')}</span>
                        </div>
                    </div>
                    <div className="secondary-info">
                        {(tariffFeeType === 'KWH_TARIFF' || tariffFeeType === 'FREE_TARIFF') ? <div className="power">{(sliderData.kWh).toFixed(1)} kWh</div> : null}
                        {(tariffFeeType === 'HOUR_TARIFF'|| tariffFeeType === 'FREE_TARIFF') ?
                            <div className="duration">
                                <span>{moment.duration(sliderData.time, 'seconds').hours()}</span>
                                <span>h</span>
                                <span>{(moment.duration(sliderData.time, 'seconds').minutes()).toString().padStart(2, '0')}</span>
                                <span>m</span>
                            </div>
                            : null}
                    </div>

                    <Slider min={0} max={50000} value={sliderData.cost} tooltip={false} onChange={(cost) => { sliderChange({ cost: cost }) }} />
                </div>
            </div>
        );
    };

    const renderTimePicker = () => {
        return (
            <div className="slider-value-row">
            <div className="slider-value-group">
            
                <div className="primary-info">
                { ((tariffFeeType === 'COMBO_TARIFF' || tariffFeeType === 'FREE_TARIFF') && LOGGED_IN_VIEW) ?
                        <ToggleButtonGroup className="charge-type-toggle" type="radio" value={timePickerMode} name="options" onChange={()=>handleTimePickerChange(!timePickerMode)}>
                            <ToggleButton value={false}><IconBattery/></ToggleButton>
                            <ToggleButton value={true}><IconClock/></ToggleButton>
                        </ToggleButtonGroup>
                        : null
                    }

                    <div className="duration">
                        <span>{moment.duration(sliderData.time, 'seconds').hours()}</span>
                        <span>h</span>
                        <span>{(moment.duration(sliderData.time, 'seconds').minutes()).toString().padStart(2, '0')}</span>
                        <span>m</span>
                    </div>
                </div>
                { (tariffFeeType !== 'COMBO_TARIFF') ?  
                    <div className="secondary-info">
                        <span>{formatAmount(sliderData.cost)}</span>
                        <span>{FormatService.getCurrencyUnit('SEK')}</span>
                    </div> : null
                }
                
                <Slider min={0} max={3599*24} value={sliderData.time} tooltip={false} onChange={(time)=>{sliderChange({time: time})}} />
            </div>
            </div>
        );
    };

    const renderPowerPicker = () => {
        return (
            <div className="slider-value-row">
            <div className="slider-value-group">
            
                <div className="primary-info">
                { ((tariffFeeType === 'COMBO_TARIFF' || tariffFeeType === 'FREE_TARIFF') && LOGGED_IN_VIEW) ?
                        <ToggleButtonGroup className="charge-type-toggle" type="radio" value={timePickerMode} name="options" onChange={()=>handleTimePickerChange(!timePickerMode)}>
                        <ToggleButton value={false}><IconBattery/></ToggleButton>
                            <ToggleButton value={true}><IconClock/></ToggleButton>
                        </ToggleButtonGroup>
                        : null
                    }
               
                    <div className="duration">
                    <span>{(sliderData.kWh)?.toFixed(1)}</span>
                    <span>kWh</span>
                    </div>
                    
                </div>
                { (tariffFeeType !== 'COMBO_TARIFF') ?  
                    <div className="secondary-info">
                        <span>{formatAmount(sliderData.cost)}</span>
                        <span>{FormatService.getCurrencyUnit('SEK')}</span>
                    </div> : null
                }

                <Slider min={0} max={3599*24} value={sliderData.time} tooltip={false} onChange={(time)=>{sliderChange({time: time})}} />
            </div>
            </div>

        );
    };    

    
    // format a cost integer to viewable cost (1234 => 12.34)
    const formatAmount = (cost) => {
        if (cost && cost > 0) cost = cost/100.0;
        return cost.toFixed(2);
    }

    const handleAllowDisconnect = (allow) => {
        // not applicable to sessionType==CHARGE
        HelperService.writeToLocalStorage('POWER_DATA', {allowDisconnect: allow});
        setAllowDisconnect(allow);
    }

    const handleTimePickerChange = (isTimePicker) => {
        let storageKey = 'POWER_DATA';
         if (sessionType === 'CHARGE') storageKey = 'CHARGE_DATA';
        HelperService.writeToLocalStorage(storageKey, {timePickerMode: isTimePicker});
        setTimePickerMode(isTimePicker);
    }

    const handleRegDropdownSelect = (eventKey) => {
        console.log(eventKey);
        setSelectedRegNumber(eventKey);
        
    }

    const renderListOfVehicles = () => {
        if (!userHasVehicles()) return null;
        return (
            <>
            {userMe.vehicles.map((v) => <Dropdown.Item key={v.regNo} eventKey={v.regNo}>
                <div className='vehicle-list-item'>
                    <span>{v.name ? v.name : v.regNo}</span>
                   { v.name ? <span className="second">{v.regNo}</span> : null }
                </div></Dropdown.Item>)}
            </>
          );

    }

    const handleRegNoToggle = () => {
        setSelectedRegNumber(null);
        setEnableRegInput(!enableRegInput)
    }

    const userHasVehicles = () => (userMe && userMe.vehicles && userMe.vehicles.length > 0);

    const renderRegNoInput = () => {
        if (!userHasVehicles()) return null;

        return (
            <>
                {
                    sessionType === 'CHARGE' ?
                        <div className="centered-toggle-button allowed-disconnect">
                            <Toggle checked={enableRegInput}
                                icons={{
                                    checked: <span className="track-text"></span>,
                                    unchecked: <span className="track-text"></span>,
                                }}
                                onChange={() => { handleRegNoToggle() }} />
                                <label>Ange Fordon</label>
                        </div>
                        : null
                }

                <CSSTransition in={enableRegInput} timeout={300} classNames="ris">       
                    { userHasVehicles() ? 
                        <div className='reg-input'>
                        <DropdownButton id="bg-nested-dropdown" align="end" title={selectedRegNumber ? <span className="reg-no-text">{selectedRegNumber}</span>:<span>Välj fordon</span>} size="lg" onSelect={handleRegDropdownSelect}>
                        { userHasVehicles() ? 
                            renderListOfVehicles() : 
                            <Dropdown.Header className="no-card-info-text">{t("topupAccountForm.need_registered_card")}</Dropdown.Header>
                        }

                        </DropdownButton>
                        </div>
                        : null
                    }
                </CSSTransition>  
            </>
        );
    }

    return (
        <>
            {
                sessionType === 'POWER' ?
                    <div className="centered-toggle-button allowed-disconnect">
                        <Toggle checked={allowDisconnect}
                            icons={{
                                checked: <span className="track-text"></span>,
                                unchecked: <span className="track-text"></span>,
                            }}
                            onChange={() => { handleAllowDisconnect(!allowDisconnect) }} />
                        <label>{t('sessionInput.allow_disconnect')}</label>
                    </div>
                    : null
            }
            
        
            { !HelperService.isCarPoolDemo(outlet) ? 
         
         <>
           <div className={(enablePowerAmount || !LOGGED_IN_VIEW)? "centered-toggle-button on" : "centered-toggle-button off" }> 
            <div className="left-side">
                <div className="shortened-line" style={LOGGED_IN_VIEW ? null : {width:'100%'}}/>
            </div>
            { LOGGED_IN_VIEW ?
            <Toggle
            checked={enablePowerAmount}
            icons={{
                checked: <span className="track-text"></span>,
                unchecked: <span className="track-text"></span>,
                }}
            onChange={()=>{setEnablePowerAmount(!enablePowerAmount)}} />  
            : null }

            {(tariffFeeType === 'HOUR_TARIFF' && LOGGED_IN_VIEW) ? <label><span>{t('sessionInput.set_charge_time')}</span></label> : null}
            {(tariffFeeType === 'KWH_TARIFF' && LOGGED_IN_VIEW) ? <label><span>{t('sessionInput.set_charge_amount')}</span></label> : null}
            {((tariffFeeType === 'COMBO_TARIFF' || tariffFeeType === 'FREE_TARIFF') && LOGGED_IN_VIEW) ? timePickerMode ? <label><span>{t('sessionInput.set_charge_time')}</span></label> : <label><span>{t('sessionInput.set_charge_amount')}</span></label> : null}
            {(!LOGGED_IN_VIEW) ? <label><span>{t('sessionInput.set_money_amount')}</span></label> : null}
            
            
            <div className="right-side"/>

        </div>
        
         </>
               
         : null}

  
       
        
        { !HelperService.isCarPoolDemo(outlet) ? 
         
         <>
            {LOGGED_IN_VIEW ? 
            <CSSTransition in={enablePowerAmount  || !LOGGED_IN_VIEW} timeout={300} classNames="pas">       
            <div className="power-amount-section">
      
            {tariffFeeType === 'HOUR_TARIFF' ? renderTimePicker() : null}
            {tariffFeeType === 'KWH_TARIFF' ? renderPowerPicker() : null}
            {tariffFeeType === 'COMBO_TARIFF' ? timePickerMode ? renderTimePicker() : renderPowerPicker() : null}
            {tariffFeeType === 'FREE_TARIFF' ? timePickerMode ? renderTimePicker() : renderPowerPicker() : null}
            
            </div>
            </CSSTransition>
            : 
            <div className="power-amount-section pas-enter-done">
      
                  { renderCostPicker()}
            
            </div>
        }
         </>
               
         : null}
    

        {outlet.allowDelayedStart ? <DelayedStartInput sessionType={sessionType} enableDepartureTime={enableDepartureTime} setEnableDepartureTime={setEnableDepartureTime} 
            delayedStartTime={delayedStartTime} setDelayedStartTime={setDelayedStartTime} 
            ></DelayedStartInput> : null}
             

        { renderRegNoInput() }
         
        
      </>
    );

}


