import React, {useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import TitleBar from 'js/components/titleBar.js';
import DataStoreContext from 'dataStoreContext';
import 'styles/common.scss';
import 'styles/supportView.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'

// Translation
import { useTranslation } from 'react-i18next';
import HelperService from '../../services/helpers';

export default function SupportView(props) {
    // Translation
    const { t } = useTranslation('views');
    const { userMe, scannedOutlet, activeSessions, isFlutterApp, sendFlutterNotification } = useContext(DataStoreContext);
    
    let history = useHistory();

    useEffect(() => {
        props.toggleMenu(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    const handleBackClick = () => {
        history.push("/");
    }

    const openSupportMail = () => {
        let link = HelperService.buildSupportMailLink('support@epspot.com', 'Power App Support', {outlet:scannedOutlet, user: userMe, activeSessions: activeSessions});
        if (isFlutterApp) {
            let payload = '{"command": "LAUNCH_URL", "url":"' + link + '"}';
            sendFlutterNotification(payload);
        }
        else {            
            window.open(link, '_blank');
        }
      };

    const renderProviderSupport = () => {
        if (!scannedOutlet || !scannedOutlet.basicProvider) {
            return ( // should never happen so no need to translate
                <div>Missing provider info</div>
            );
        }
        let p = scannedOutlet.basicProvider;
        return (
            <>
            <div className="prov-name">{p.name}</div>
            { p.supportContact ?
                <div className="contact-row">
                        { p.supportContact.phone ? <span><FontAwesomeIcon icon={faPhone}/>{p.supportContact.phone}</span> : null }
                        { p.supportContact.email ? <div> 
                        <Button className="support-button" onClick={()=>openSupportMail(p.supportContact.email)}><FontAwesomeIcon icon={ faEnvelope } />Support</Button>
                    </div> : null }
                </div> :
                <div className="contact-row">
                    <span>{t('support.contact_details_to')} {p.name} {t('support.is_missing_contact_epspot_instead')}</span> 
                </div>
            }
                
            </>
        );
    }

    return (
    <div className="sliding-view support-view">
        <div className="bg"></div>
        <div className="cover"></div>
        <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={'Support'}/>
        
        <div className="content">
            <p>
                {t('support.questions_on_outlet')}
            </p>

            <p style={{marginBottom:'30px'}}>
                {t('support.general_questions')} 
            </p>

            <div className="plate"  style={{opacity: scannedOutlet ? '1':'0.6'}}>
                <h1>{t('support.outlet_owner')}</h1>
                {
                    scannedOutlet ? renderProviderSupport() :
                    <div className="scan-to-see">{t('support.scan_outlet_to_see_contact')}</div>
                }
            </div>

            <div className="plate">
                <h1>{t('support.service_provider')}</h1>
                <div className="prov-name">EpSpot AB</div>
                <div className="contact-row">
                    <span><FontAwesomeIcon icon={faPhone}/>+46 (0)8 51970808</span>
                    {/* <span><FontAwesomeIcon icon={faEnvelope}/>support@epspot.com</span> */}

                    <div> 
                        <Button className="support-button" onClick={()=>openSupportMail('support@epspot.com')}><FontAwesomeIcon icon={ faEnvelope } />Support</Button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}