import React, { useState, useEffect, useContext } from 'react';
import DataStoreContext from 'dataStoreContext'
import {FormatService} from "services/helpers.js";
import 'react-rangeslider/lib/index.css'
import moment from 'moment';
import 'moment-duration-format';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";
import 'styles/sessionInputPower.scss';
import 'styles/sessionInputSlotPeriod.scss';
import 'styles/common.scss'

export default function SessionInputUnitPeriod({outlet, initSessionPropsCB}) {
    const { userMe } = useContext(DataStoreContext);
    let tariff = outlet.tariff;
    const [ numOfUnits, setNumOfUnits] = useState(1);
    const [ cost, setCost] = useState(0);
    const { t } = useTranslation('components');

    registerLocale('sv', sv);
    let LOGGED_IN_VIEW = userMe ? true : false;
    
    
    useEffect(() => {
        //setNumOfUnits(tariff.slotPeriod);    
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [userMe]);


    useEffect(() => {
        let openingFee = (LOGGED_IN_VIEW) ? tariff.openingFee : tariff.singlePaymentOpeningFee;
        let cost = tariff.unitFee * numOfUnits;
        if (openingFee > 0) cost += openingFee;

        console.log('tariff', tariff);
        
        setCost(cost);
        let sessionProps = {};
        sessionProps.sessionType = 'POWER';
        sessionProps.cost = cost;
        sessionProps.limitType = 'TIME';

        sessionProps.limit = numOfUnits*tariff.unitDuration;
        console.log('session duration (s): ' + sessionProps.limit);
        
        sessionProps.allowDisconnect = true;
        initSessionPropsCB(sessionProps);

    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [numOfUnits]);

    const formatAmount = (value) => {
        return value.toFixed(2);
    }

  
    const stepDownnumOfUnits = () => {
        if (numOfUnits > 0) setNumOfUnits(numOfUnits-1);
    }
    const stepUpnumOfUnits = () => {
        setNumOfUnits(numOfUnits+1);
    }

    const getTotalDuration = () => {
        if (!tariff.unitDuration ) return null; 
        let seconds = tariff.unitDuration * numOfUnits;
        if (seconds >= 3600) return moment.utc(seconds*1000).format('HH[h:]mm[m:]ss[s]'); 
        else if (seconds >= 60) return moment.utc(seconds*1000).format('mm[m:]ss[s]'); 
        else return moment.utc(seconds*1000).format('ss[s]'); 
    }

    return (
        <>
            <div className="session-input-slot-info">
                <label className="centered-label"><span>{t('sessionInput.select_units')}</span></label>
                <div className="amount-row">
                    <FontAwesomeIcon icon={ faMinusCircle } className={(numOfUnits === 0) ? 'amount-btn disabled': 'amount-btn'} onClick={stepDownnumOfUnits}/> 
                    <div className="amount">
                        { numOfUnits } 
                    </div>
                    <FontAwesomeIcon icon={ faPlusCircle }  className={(numOfUnits === 500) ? 'amount-btn disabled': 'amount-btn'} onClick={stepUpnumOfUnits}/>
                </div>

                <div className="cost"><div>{formatAmount(cost)}</div><div>{FormatService.getCurrencyUnit('SEK')}</div></div> 

                <div className="slot-end-container">
                    <div className="slot-end-date">{t('sessionInput.total_unit_duraton')} {getTotalDuration()}</div>
                </div>


            </div>
       
        </>
    );

}