import React, {useState, useEffect, useRef} from 'react';
import {LoadingModal} from 'js/components/modals.js';
import Api from "services/api.js";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProgressButton from 'js/components/progressButton'
import {FormatService} from 'services/helpers.js';

import {useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js';

import 'styles/cardPaymentView.scss';

// Translation
import { useTranslation } from 'react-i18next';

/* Used for credit card payment of a charging session, by an anonymous/unregistered user AND we also 
* use the same dialog for Swish payment from another device (we show the swish qr code in this dialog)
*/
export default function CardPaymentView({sessionData, qrUrl, outlet, stripeCompletedCB, stripeCanceledCB, pcSwishCancelCB}) {
    const stripe = useStripe();
    const elements = useElements();
    const [ loading, setLoading] = useState(false);
    const [ submitting, setSubmitting] = useState(false);
    const [ loadingText, setLoadingText] = useState('');
    const [ cvcStatus, setCvcStatus] = useState({complete: false, error: ''});
    const [ cardNumberStatus, setCardNumberStatus] = useState({complete: false, error: ''});
    const [ expiryStatus, setExpiryStatus] = useState({complete: false, error: ''});
    const [ nameStatus, setNameStatus] = useState({complete: false, error: ''});
    const [ emailStatus, setEmailStatus] = useState({complete: true, error: ''});
    const [pendingReceiptId, setPendingReceiptId]  = useState(null);
    const [fetchStatusCounter, setFetchStatusCounter]  = useState(0);
    const [ errorText, setErrorText] = useState(null);

    let timeoutRef = useRef();

    // Translation
    const { t } = useTranslation('views');
    

    useEffect(() => {
        if (pendingReceiptId) {
            checkPendingReceipt();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [pendingReceiptId]);

    const cardStyles = {
        base: {
            fontSize: "19px",
            color: "#424770",
            fontFamily: "sans-serif",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
    };
    
    const cardNumberOptions = {
        iconStyle: "default",
        showIcon: true,
        style: cardStyles
      };
    const options = { style: cardStyles };  

    const handleFormValidation = (event, formName) => {
        if (errorText) setErrorText(null);
        let statusObject = {complete: false, error: ''};
        if (!event.empty && event.complete) {
            statusObject.complete = true;
            statusObject.error = '';
        }
        else if (event.error) {
            statusObject.complete = false;
            statusObject.error = event.error.message;
        }
        else if (formName === 'name') {
            if (event.target.value && event.target.value.length > 1) {
                statusObject.complete = true;
                statusObject.error = '';
            }
            
        }
        else if (formName === 'email') {
            if (event.target.value && event.target.value.length > 4) {
                if (event.target.value.indexOf('@') > 0) {
                    statusObject.complete = true;
                    statusObject.error = '';
                }
                else {
                    statusObject.error = t("cardPaymentView.invalidEmail");
                }
                
            }
        } 
        else {
            statusObject.complete = false;
            statusObject.error = '';
        }
        if (formName === 'cvc') {
            setCvcStatus(statusObject);
        }
        else if (formName === 'cardnumber') {
            setCardNumberStatus(statusObject);
        }
        else if (formName === 'expiry') {
            setExpiryStatus(statusObject);
        }
        else if (formName === 'name') {
            setNameStatus(statusObject);
        }
        else if (formName === 'email') {
            setEmailStatus(statusObject);
        }
        
    }  

    /* Submitting the payment results in a pending receipt. This receipt is then periodically checked 
     * in checkPendingReceipt until payment is successful or cancelled/failed. 
     */  
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        

        let name = document.getElementById('cardholder-name').value;
        let email = document.getElementById('cardholder-email').value;

        try {
            setSubmitting(true);
            //1: get the intent
            let body = {};
            body.name = name;
            if (email) body.email = email;
            body.paymentMethod = 'CARD';
            body.totalAmount = parseFloat(sessionData.cost.toFixed(2));
            body.sessionData = {outletId: outlet.id};
            body.sessionData.autoOffType = sessionData.limitType;
            body.sessionData.autoOffValue = sessionData.limit;
            body.sessionData.sessionType = sessionData.sessionType;
            if (sessionData.delayedStartTime) body.sessionData.startTime = sessionData.delayedStartTime;
            if (sessionData.allowDisconnect) body.sessionData.allowDisconnect = sessionData.allowDisconnect;

            let intent = await Api.initiatePrepaidSession(body); 
            if (!intent || !intent.paymentIntentId) throw new Error();

            let billingDetails = {name: name};
            if (email) billingDetails.email = email;

            //2: confirm
            const result = await stripe.confirmCardPayment(intent.secret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: billingDetails,
                }
                });
            
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    console.log(result.error.message);
                    //setInfoModal([result.error.message]);
                    setErrorText(result.error.message);
                    //setLoadingText('');
                    //setLoading(false);
                    await Api.cancelStripePayment({intentId: intent.paymentIntentId});
                    setSubmitting(false);
                } 
                else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        setPendingReceiptId(intent.paymentIntentId);
                        console.log('PAYMENT SUCCESS!!!');
                    }
                }
        } catch (error) {
            console.log(error);
            setErrorText(t("cardPaymentView.cardPaymentFailed"));
            setLoadingText('');
            setLoading(false);
        } 

    }


    const checkPendingReceipt = async () => {
        if (!pendingReceiptId) return;

        if (fetchStatusCounter > 15) {
            console.log('card payment timed out');
            setPendingReceiptId(null);
            //setProcessingTopup(false);
            //setAddTopupAccountError('Swishbetalningen avbröts, den tog för lång tid');
            setFetchStatusCounter(0);
            setSubmitting(false);
            return;
        }
        
        try {
            const receipt = await Api.getPaymentStatus(pendingReceiptId);
            if (receipt && receipt.paymentStatus === 'SUCCESS') {               
                setPendingReceiptId(null);
                setFetchStatusCounter(0);
                setSubmitting(false);
                stripeCompletedCB(receipt);
            }
            else if (receipt && receipt.paymentStatus === 'PENDING') {
                console.log('payment pending');
                setFetchStatusCounter(fetchStatusCounter+1);
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(checkPendingReceipt, 2000);
            }
            else {
                console.log('Payment cancelled');
                setPendingReceiptId(null);
                setFetchStatusCounter(0);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const inputIsIncomlete = () => {
        return ! ( cardNumberStatus.complete  && expiryStatus.complete && cvcStatus.complete && nameStatus.complete && emailStatus.complete );
    }

    const inputHasError = () => {
        //return false;
        return ! ( cardNumberStatus.error===''  && expiryStatus.error==='' && cvcStatus.error==='' && nameStatus.error==='' && emailStatus.error==='' );
    }

    const handleCardCancel = () => {
        stripeCanceledCB();
    }

    const getErrorText = () => {
        if (errorText && errorText.length > 0) return errorText; 
        if  (cvcStatus.error.length > 0 ) return cvcStatus.error; 
        if  (expiryStatus.error.length > 0 ) return expiryStatus.error; 
        if  (cardNumberStatus.error.length > 0 ) return cardNumberStatus.error; 
        if  (emailStatus.error.length > 0 ) return emailStatus.error; 
        if  (nameStatus.error.length > 0 ) return nameStatus.error; 
        return '';
    }

    return (
        <>
            <Modal className="card-payment-view" show aria-labelledby="contained-modal-title-vcenter" onHide={() => { }}>
                <div className="content">
                    {
                        qrUrl ? 
                        <div className="swish-qr">
                            <p>{t("cardPaymentView.open_swish_on_mobile")}</p>
                            <img src={qrUrl} alt="swish qr url"/>
                            <Button className="cancel-button" type="cancel" onClick={()=>{pcSwishCancelCB()}}>{t("cardPaymentView.cancel")}</Button>
                        </div>
                        :
                        <>
                            <h1>{t("cardPaymentView.cardPayment")}</h1>
                            <div>{t("cardPaymentView.price") + '' + FormatService.formatCurrency(100 * sessionData.cost, 2, 'SEK')}</div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>
                                        {t("cardPaymentView.name")}
                                        <Form.Control className="outlet-input" type="text" placeholder="Anna Andersson" autocomplete="ccname" id="cardholder-name" name="name" onChange={event => { handleFormValidation(event, 'name') }} />
                                    </label>

                                    <label>
                                        {t("cardPaymentView.email")}
                                        <Form.Control className="outlet-input" type="email" placeholder="anna@example.com" id="cardholder-email" name="email" onChange={event => { handleFormValidation(event, 'email') }} />
                                    </label>

                                    <label>
                                        {t("cardPaymentView.cardNumber")}
                                        <div className="form-control">
                                            <CardNumberElement options={cardNumberOptions} onChange={event => { handleFormValidation(event, 'cardnumber') }} />
                                        </div>
                                    </label>
                                    <div className="cvc-row">
                                        <label className="expiry-date">
                                            {t("cardPaymentView.expirationDate")}
                                            <div className="form-control">
                                                <CardExpiryElement options={options} onChange={event => { handleFormValidation(event, 'expiry') }} />
                                            </div>
                                        </label>
                                        <label className="cvc">
                                            {t("cardPaymentView.cvc")}
                                            <div className="form-control">
                                                <CardCvcElement options={options} onChange={event => { handleFormValidation(event, 'cvc') }} />
                                            </div>
                                        </label>
                                    </div>
                                    <div className="error-text">{getErrorText()}</div>

                                    <ProgressButton disabled={!stripe || inputIsIncomlete() || inputHasError()} className="ok-button" text={t("cardPaymentView.makePurchase")} loading={submitting} clickCB={handleSubmit} />
                                    <Button className="cancel-button" type="cancel" onClick={()=>handleCardCancel()}>{t("cardPaymentView.cancel")}</Button>
                                </div>
                            </form>
                        </>
                    }
                    
                </div>



                {(loading) ? <LoadingModal loadText={loadingText} /> : null}

            </Modal>

    </>
  );
}
