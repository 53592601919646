import React, {useState, useEffect} from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ProgressButton from 'js/components/progressButton'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import HelperService from "services/helpers.js";
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { useTranslation } from 'react-i18next';

import 'styles/topupAccountForm.scss';

export default function TopupAccountForm({qrUrl, paymentCards, primaryCardId, submitting, submitCB, cancelCB, pcSwishCB, pcSwishCancelCB, errorText}) {
    const [ topupAmount, setTopupAmount] = useState(100);
    const [ selectedCard, setSelectedCard] = useState(null);
    const [ paymentMethod, setPaymentMethod] = useState(null); //'SWISH' or 'CARD'

    const { t } = useTranslation("components"); 

    // not the prettiest solution... but since we change primary card info 
    // immediately in order to not freeze GUI we must cater for the occasional case where 
    // paymentCards list isn't updated yet. Therefore we use primaryCardId and find the corresponding card manually
    useEffect(() => {
        setSelectedCard(HelperService.getPaymentCard(paymentCards, primaryCardId));
        /* if (paymentCards.length === 0) setPaymentMethod('SWISH');
        else setPaymentMethod('CARD'); */
        setPaymentMethod('SWISH');

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
    


    const handleCancel = (event) => {
        event.preventDefault();
        cancelCB();
        
    }

    const handleTopup = async (paymentType) => {
        if (paymentMethod==='CARD' && selectedCard) {
            submitCB(paymentType, topupAmount, selectedCard.id);
        }
        else if (paymentMethod === 'SWISH') {
            submitCB('SWISH', topupAmount);
        }
    }

    const handleSwishFromPc = async () => {
        if (paymentMethod === 'SWISH') {
            pcSwishCB(topupAmount);
        }
    }

    const handleDropdownSelect = (eventKey) => {
        console.log(eventKey);
        if (eventKey === 'SWISH') {
            setPaymentMethod('SWISH');
            setSelectedCard(null);
        }
        else {
            setSelectedCard(HelperService.getPaymentCard(paymentCards, eventKey));
            setPaymentMethod('CARD');
        }
    }

    const stepDownAmount = () => {
        if (topupAmount > 100 && topupAmount <= 500) setTopupAmount(topupAmount-100);
        else if (topupAmount > 500) setTopupAmount(topupAmount-500);
      
        /* else if (topupAmount === 100) setTopupAmount(3); //temporary to test card payments with smallest possible amount
        else if (topupAmount === 3) setTopupAmount(1); //temporary to swish payments with smallest possible amount */
    }
    const stepUpAmount = () => {
        if (topupAmount < 500 && topupAmount > 3) setTopupAmount(topupAmount+100);
        else if (topupAmount < 3000 ) setTopupAmount(topupAmount+500);
      //  else if (topupAmount === 3) setTopupAmount(100);
      //  else if (topupAmount === 1) setTopupAmount(3);
    }

    const renderListOfCards = () => {
        if (!(paymentCards && paymentCards.length > 0)) return null;
        return (
            <>
            {paymentCards.map((card) => <Dropdown.Item key={card.id} eventKey={card.id} disabled={(paymentMethod==='CARD' && selectedCard && selectedCard.id===card.id)}>{card.card.brand + '(...'+card.card.last4+')'}</Dropdown.Item>)}
            </>
          );

    }
    
    const renderPayButtonText = () => {
        if (paymentMethod === 'SWISH') return 'swish';
        if (paymentMethod === 'CARD' && selectedCard) return  `${t("topupAccountForm.card")}(...+${selectedCard.card.last4})`;
        return '';
    }    
    

    return (
        <Modal className="topup-account-form" show aria-labelledby="contained-modal-title-vcenter" onHide={()=>{}}>
        
        <div className="content">
            {
                qrUrl ? 
                <div className="swish-qr">
                    <p>{t("topupAccountForm.open_swish_on_mobile")}</p>
                    <img src={qrUrl} alt="swish qr url"/>
                    <Button className="cancel-button" type="cancel" onClick={()=>{pcSwishCancelCB()}}>{t("topupAccountForm.cancel")}</Button>
                </div>
                :
                <>   
                <h1>{t("topupAccountForm.top_up_account")}</h1>
                <div className="amount-row">
                    <FontAwesomeIcon icon={ faMinusCircle } className={(topupAmount === 100) ? 'amount-btn disabled': 'amount-btn'} onClick={stepDownAmount}/>
                    {/* <FontAwesomeIcon icon={ faMinusCircle } className={(topupAmount === 1) ? 'amount-btn disabled': 'amount-btn'} onClick={stepDownAmount}/>  */}
                    <div className="amount">
                        { topupAmount} kr
                    </div>
                    <FontAwesomeIcon icon={ faPlusCircle }  className={(topupAmount === 3000) ? 'amount-btn disabled': 'amount-btn'} onClick={stepUpAmount}/>
                </div>

                <div className="line-divider-with-title">
                    <div className="line"/>
                    <span>{t("topupAccountForm.pay_with")}</span>    
                    <div className="line"/>
                </div>

                {
                    <ButtonGroup className="split-pay-button">
                    <ProgressButton className="pay-button" text={renderPayButtonText()} loading={submitting} clickCB={()=>handleTopup('CARD')}/>
                    <DropdownButton as={ButtonGroup} onSelect={handleDropdownSelect} title={<div className="button-title">{t("topupAccountForm.change_payment_method")}</div>} id="bg-nested-dropdown" drop="top">
                        <Dropdown.Item eventKey="SWISH" disabled={paymentMethod==='SWISH'}>Swish</Dropdown.Item>
                        <Dropdown.Header>{t("topupAccountForm.debit_card")}</Dropdown.Header>
                        { (paymentCards && paymentCards.length > 0) ? 
                            renderListOfCards() : 
                            <Dropdown.Header className="no-card-info-text">{t("topupAccountForm.need_registered_card")}</Dropdown.Header>
                        }

                    </DropdownButton>
                    </ButtonGroup>
                }
                {
                    errorText ? <div className="error-text">{errorText}</div> : null
                }    
                <Button className="cancel-button" type="cancel" onClick={handleCancel}>{t("topupAccountForm.cancel")}</Button>

                {paymentMethod === 'SWISH' ? 
                    <div className="swish-from-other">{t("topupAccountForm.swisch_from_other_device")} 
                    <span onClick={()=>{ handleSwishFromPc() }}>{t("topupAccountForm.click_here")} </span>
                    </div> 
                    
                    : null
                }
                

                </>
            }
        
        </div>

    </Modal>
  );
}

// 