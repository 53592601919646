import React, { useState, useEffect, useContext } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons'

import StartChargingView from "./js/views/startChargingView.js";
//import SessionOngoingView from "./js/views/sessionOngoingView.js";
import UninsertedOutletView from "./js/views/uninsertedOutletView.js";
import ScanOutletView from "./js/views/scanOutletView.js";
import DataStoreContext, {Constants} from './dataStoreContext'
import HelperService from "./services/helpers.js";
import Api from "./services/api.js";
import { useHistory } from "react-router-dom";
import TitleBar from 'js/components/titleBar.js';
import {LoadingModal} from 'js/components/modals.js'
import { useTranslation } from 'react-i18next';

import './styles/home.scss';
import { useOnlineStatus } from './services/hooks.js';

export default function Home({firstRun, toggleMenu, hideInitScreenCB, externalOutletId}) {
    const onlineStatus = useOnlineStatus();

      const VIEW_MODE = {
        OUTLET_INSERTED: 'outletInserted',
        OUTLET_UNINSERTED: 'outletUninserted',
        NO_OUTLET_SELECTED: 'noOutletSelected',
        SESSION_ONGOING: 'sessionOngoing',
        LOADING: 'dataLoading',
      };  

      const { t, i18n } = useTranslation(['views','components']);
      
      let START_TIME = null;
      
      let history = useHistory();
       

      const [loading, setLoading]  = useState(null);
      const [ loadingText, setLoadingText] = useState('');
      const {   userMe, setUserMe, scannedOutlet, setScannedOutlet, setInfoModal, setSupportModal, activeSessions, 
                userSettings, externalOutletToOpen, setExternalOutletToOpen} = useContext(DataStoreContext);
    
        const [viewMode, setViewMode] = useState(VIEW_MODE.NO_OUTLET_SELECTED);
      
        /*console.log('*************');
        console.log(internalState);
        console.log(externalOutletId);
        console.log('*************');
        if (externalOutletId !== previousValue) {
          setInternalState(externalOutletId);
          setPreviousValue(externalOutletId);
        }  
         */

    
    


    /* Hack to get around issue that the browser address bar hides the title bar on 
       Huawei devices (at least one version, further testing is needed to track down further) 
       when keyboard is opened, then closed.
    */
   const [titleBarKey, setTitleBarKey]  = useState(); 
    const reloadTitleBar = () => {
        setTimeout(() => {
            setTitleBarKey((new Date()).getMilliseconds());
        }, 500);
        
    
    }

    useEffect(() => {
        if (externalOutletToOpen) {
            loadOutlet(externalOutletToOpen);
            setExternalOutletToOpen(null);
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [externalOutletToOpen]); 

    useEffect(() => {
        checkOutletStatus();
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [scannedOutlet]);    

    useEffect(() => {
        if (firstRun) {
            firstRunInit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


   /* const plugInserted = () => {
        if (scannedOutlet && scannedOutlet.outletStatus && scannedOutlet.outletStatus.plugInserted) return true;   
        return false;
    }
    */

    const firstRunInit = async () => {
        START_TIME = Date.now();
        let outletId = HelperService.getPathOutletId();
        if (outletId) {
            hideInitScreen();
            setLoadingText(t('views:home.fetch_outlet_info'));
            setLoading(true);
        }

        let storedToken = localStorage.getItem('accessToken');
        // user reloaded app with stored credentials
        if (storedToken) {
            console.log('Found access token, will attempt to log in');
            await autoLogin(storedToken);
        }

        // FOR LOCAL OUTLET TEST
        //outletId = 'x'; //with this no need to type an id

        if (outletId) {
            loadOutlet(outletId);            
            history.push("/");   
        } 
        else {
            hideInitScreen();
        }
    };

    const autoLogin = async (storedToken) => {
        try {
            Api.accessToken = storedToken;
            let userMe = await Api.getUserMe();
            let usage = await Api.getMyUsage();
            userMe.usage = usage;
            // TODO: setting userMe here causes loading screen flicker. Not sure why but need to fix somehow.
            setUserMe(userMe);
            if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage); 
            
        } catch (error) {
            console.log(error);
            localStorage.removeItem('accessToken');
            Api.accessToken = null;
        }
    }


    const hideInitScreen = async () => {
        const timeDelta = Date.now()  - START_TIME;
        // TODO remove
        //await new Promise(r => setTimeout(r, 300000));
        if (timeDelta > Constants.MIN_INIT_SCREEN_VISIBILITY) {
            hideInitScreenCB();
        } 
        else {
            if (userSettings && userSettings.devOptions && userSettings.devOptions.quickCloseLoadingScreen) {
                hideInitScreenCB();
            }
            else {
                const sleep = () => new Promise(resolve => setTimeout(resolve, Constants.MIN_INIT_SCREEN_VISIBILITY - timeDelta));
                await sleep();
                hideInitScreenCB();
            }
            
        }
    }


    const loadOutlet = async (outletId) => {
        try {
            setLoadingText(t('views:home.fetch_outlet_info'));
            setLoading(true);
            let useNewModel = false;

            /* To handle both new and old data models simultaneously we start by fetching from new model. If we get an outlet, and this outlet 
               has the useNewModel=true we are in new model mode. If we don't get an outlet (if the provider's outlets has not yet been converted to new model) 
               or if useNewModel=false we are in old model mode.
             */

            let outlet = null;
            try {
                outlet = await Api.getOutlet(outletId);
            } catch (error) {
                if (error.code !== 125) throw error;
            }

            if (!outlet || !outlet.useNewModel) {
                outlet = await Api.getOutlet_LEGACY(outletId);
                console.log('USING OLD MODEL');
            }
            else {
                console.log('USING NEW MODEL');
                useNewModel = true;
            } 
            //let outlet = await Api.getOutlet_LEGACY(outletId);
            //let outlet = testOutlet;
            //console.log(outlet);

            if (!useNewModel) {
                
                
                if (!userMe && outlet.tariff && outlet.tariff.paymentMethods && !outlet.tariff.paymentMethods.includes('SINGLE_SESSION_PAYMENT') && !HelperService.isCarPoolDemo(outlet)) {
                    let err = new Error('This outlet does not support single session payments, thus the user must log in');
                    err.code = 1;
                    throw err;
                }
                
               
                if (outlet.parents) {
                    let providerId = outlet.parents[outlet.parents.indexOf('V2Provider') + 1];
                    let plantId = outlet.parents[outlet.parents.indexOf('V2Plant') + 1];
                    let locationId = outlet.parents[outlet.parents.indexOf('V2Location') + 1];
    
                    let provider = await Api.getProvider(providerId);
                    outlet.basicProvider = provider;
                    
                    let location = await Api.getLocation(providerId, plantId, locationId);
                    //console.log(location);
                    outlet.basicLocation = location;
                }
                const outletStatus = await Api.getOutletStatus(outlet.id);
                outlet.outletStatus = outletStatus;
            }
            else {
                const outletStatus = await Api.getOutletStatus2(outlet.id, outlet.plantRef, outlet.providerRef);
                outlet.outletStatus = outletStatus;
            }
            

            /* if (outlet.providerRef && outlet.plantRef && outlet.locationRef) {
                let provider = await Api.getProvider(outlet.providerRef);
                outlet.basicProvider = provider;
                
                let location = await Api.getLocation(outlet.providerRef, outlet.plantRef, outlet.locationRef);
                //console.log(location);
                outlet.basicLocation = location;
            } */
                
            
            /*
            // FOR LOCAL OUTLET TEST
            let testStatus = {
                "status": "STATUS_AVAILABLE",
                "outletId": "000-000-678",
                "plugInserted": true
            }
            outlet.outletStatus = testStatus;
            */

            // Eways temporary fix
            let infotext = ['Upplysning', 'Laddtjänsten kommer att stängas av på grund av att Eways AB inte uppfyller sina skyldigheter genom att betala för tjänsten. Ägaren av er anläggning ombeds därför kontakta EPspot via länken nedan eller via email info@epspot.com.', 'https://forms.gle/Unp3fJCut5UQCezN7'];
            if (i18n.language === 'en') {
                infotext = ['Information', 'The charging service will be shut down because Eways AB is not fulfilling its obligations by paying for the service. The owner of your facility is therefore requested to contact EPspot via the link below or via email at info@epspot.com.'];
            }
            if (outlet.providerRef === 'd7b7aba9-f5d7-4900-ac62-8b514919c430') {
                setInfoModal([infotext]);
            } 
            // End Eways temporary fix
            
            setScannedOutlet(outlet);
            setLoadingText('');
            setLoading(false);
            hideInitScreen(); 
        } catch (error) {
            //console.log(error);
            // we could not get outlet, so we try to get the provider so we can show correct support info
            let provider = null;
            try {
                provider = await Api.getProviderForOutlet(outletId);
            } catch (error) 
            { 
                console.log(error);   
            }
            console.log(error);
            if(onlineStatus === false) setSupportModal([[t("components:supportModal.no_network")], null ]);
            else if (error.code === 1) setSupportModal([[t('components:supportModal.user_must_log_in')], provider ]);
            else if (error.code === 18008) setSupportModal([[t('components:supportModal.user_must_log_in')], provider ]);
            else if (error.code === 125) setSupportModal([[t('components:supportModal.stated_outlet_not_exist')], provider ]);
            else if (error.code === 18000) setSupportModal([[t('components:supportModal.stated_outlet_turned_off')], provider]); // disabled outlet
            else if (error.code === 18001) setSupportModal([[t('components:supportModal.stated_outlet_member_only_unreg'), t('components:supportModal.press_for_contact_info')], provider] );  // anonymous user
            else if (error.code === 18002) setSupportModal([[t('components:supportModal.stated_outlet_member_only'), t('components:supportModal.press_for_contact_info')], provider] ); // logged in but not member
            else if (error.code === 18003 || error.code === 18004) setSupportModal([[t('components:supportModal.stated_outlet_access_only'), t('components:supportModal.press_for_contact_info')], provider] ); // member but no access
            else if (error.code === 1018) setSupportModal([ [t('components:supportModal.stated_outlet_on_disabled_provider')], null] );
            else setSupportModal([ [t('components:supportModal.stated_outlet_unreachable')], provider] );
            
            setScannedOutlet(null);
            hideInitScreen();
            setLoading(false);
        }
    }


    const checkOutletStatus = async () => {
        if ( !scannedOutlet || !scannedOutlet.outletStatus) {
            //setOutletStatus(null);
            setViewMode(VIEW_MODE.NO_OUTLET_SELECTED);
            return;
        }
        if(scannedOutlet.outletStatus.status === 'STATUS_DISABLED'){
            setSupportModal([[t("components:supportModal.stated_disabled")], scannedOutlet.basicProvider] ); 
            console.log('OUTLET DISABLED');
        }
        else if (scannedOutlet.outletStatus.status === 'STATUS_CONTACT_LOST') {
            setSupportModal([[t("components:supportModal.stated_no_contact")], scannedOutlet.basicProvider] ); 
            console.log('CONTACT LOST WITH OUTLET');
        }
        else if (scannedOutlet.outletStatus.status === 'STATUS_STATION_FAULTED') {
            setSupportModal([[t("components:supportModal.stated_outlet_station_faulted"), t("components:supportModal.contact_manufacturer")], scannedOutlet.basicProvider] );
        } 
        else {
            if (!scannedOutlet.outletStatus.plugInserted) {
                console.log('Outlet not inserted');
                setViewMode(VIEW_MODE.OUTLET_UNINSERTED);
            }
            else if (scannedOutlet.outletStatus.status === 'STATUS_AVAILABLE') {
                setViewMode(VIEW_MODE.OUTLET_INSERTED);
            }
            else if (scannedOutlet.outletStatus.status === 'STATUS_BUSY') {
                if (activeSessions) {
                    let activeSession = activeSessions.find((session) => session.outletId === scannedOutlet.id);
                    if (activeSession) {
                        setSupportModal([[t("components:supportModal.stated_session_already_running")], scannedOutlet.basicProvider] ); 
                    }
                    else {
                       // some other session
                        setSupportModal([[t("components:supportModal.stated_outlet_busy")], scannedOutlet.basicProvider] ); 
                        console.log('CANNOT OPEN OUTLET WITH SESSION ONGOING'); 
                    }   
                } 
                else { // some other session
                    setSupportModal([[t("components:supportModal.stated_outlet_busy")], scannedOutlet.basicProvider] ); 
                    console.log('CANNOT OPEN OUTLET WITH SESSION ONGOING');
                }
            }
            else if (scannedOutlet.outletStatus.status === 'STATUS_STATION_FINISHING') {
                setSupportModal([[t("components:supportModal.stated_outlet_station_finishing"), t("components:supportModal.reconnect_cable")], scannedOutlet.basicProvider] );
            }
            else {
                console.log('TODO: Support this:');
                console.log(scannedOutlet.outletStatus.status);
            }
        }
    }


    const handleOutletChange = (outletId) => {
        loadOutlet(outletId);
    }

    const DEBUG_handleSessionChange = async (sessionId) => {
        sessionStarted(sessionId);
    }

    const disconnectOutlet = () => {
        let prevSession = window.localStorage.getItem('sessionReferenceId');
        if (prevSession) window.localStorage.setItem('lastSessionRef', prevSession);
        window.localStorage.removeItem('sessionReferenceId'); 
       setScannedOutlet(null);
    }


   

    const showMenu = () => {
        toggleMenu(true);
    }

    const sessionStarted = async (outletSessionid) => {
        console.log('session started');
        console.log(outletSessionid);
        try {
            setLoadingText(t('views:home.fetch_session_info'));
            setLoading(true);
            const outletSessionInfo = await Api.getOutletSession(outletSessionid);
            setLoading(false);
            setLoadingText('');
            console.log(outletSessionInfo);
            if (outletSessionInfo && outletSessionInfo.session) {
                //setOutletSession(outletSessionInfo.session);
                //setOutletState(STATE.SESSION_ONGOING);
            }
            else {
                console.log('HANDLE ERROR');
            }
            
        } catch (e) {
            console.log(e);
        }
        
        
    }

    const getTitle = () => {
        if (viewMode === VIEW_MODE.OUTLET_INSERTED) return t('views:home.start_session');
        if (viewMode === VIEW_MODE.OUTLET_UNINSERTED) return t('views:home.start_session');
        if (viewMode === VIEW_MODE.NO_OUTLET_SELECTED) return t('views:home.connect_to_outlet');
        if (viewMode === VIEW_MODE.SESSION_ONGOING) return t('views:home.session_ongoing');
        return '';
    }

   

  return (
    <div id="homeView" className="home-view">
        
     <TitleBar key={titleBarKey} icon={faBars} clickCB={showMenu} title={getTitle()}/>
        

      <div className="content">    
      { (viewMode === VIEW_MODE.OUTLET_INSERTED) ? <StartChargingView scannedOutlet={scannedOutlet} disconnectOutletCB={disconnectOutlet} sessionStartedCB={sessionStarted}/> : null }
      { (viewMode === VIEW_MODE.OUTLET_UNINSERTED) ? <UninsertedOutletView disconnectOutletCB={disconnectOutlet}/> : null }
      { (viewMode === VIEW_MODE.NO_OUTLET_SELECTED) ? <ScanOutletView reloadTitleBarCB={reloadTitleBar} outletChangedCB={handleOutletChange} DEBUG_sessionChangedCB={DEBUG_handleSessionChange}/> : null }
      { /*(viewMode === VIEW_MODE.SESSION_ONGOING) ? <SessionOngoingView  initialOutletSession={selectedSession} disconnectOutletCB={disconnectOutlet}/> : null */}

      { (loading) ? <LoadingModal loadText={loadingText}/> : null }  

        </div>
    </div>
  );
}

