import moment from 'moment';
import HelperService from "services/helpers.js";

//let STRIPE_SERVICE_URL = 'https://v25-dot-stripe-war-dot-epspot-production.appspot.com/services/';
let STRIPE_SERVICE_URL = 'https://stripe-war-dot-epspot-production.appspot.com/services/';
//let INFRASTRUCTURE_SERVICE_URL = 'https://infrastructure-service-dot-epspot-production.appspot.com/';



//let INFRASTRUCTURE_SERVICE_URL = 'https://v3-196olatest-dot-infrastructure-service-dot-epspot-production.appspot.com/';
let INFRASTRUCTURE_SERVICE_URL = 'https://infrastructure.epspot.com/';
//INFRASTRUCTURE_SERVICE_URL = 'http://localhost:8080/';


if (HelperService.getDevOptionsKey('usePlayground')) {
    STRIPE_SERVICE_URL = 'https://stripe-war-dot-epspot-production.appspot.com/services/';
    INFRASTRUCTURE_SERVICE_URL = 'https://infrastructure-service-dot-epspot-playground.appspot.com/';
}


const ProductionApi = {
    stripeUrl: STRIPE_SERVICE_URL,
    infrastructureUrl: INFRASTRUCTURE_SERVICE_URL,
    accessToken: null,
    userSettings: null,
    flutterVersion: null,

    _getVersion: function () {
        let version = null;
        if (process && process.env) version = process.env.REACT_APP_VERSION;
        if (Api.flutterVersion) version += ` (${Api.flutterVersion})`;
        return version;
    },

    logIn: async function (username, password) {
        let credentials = {userName: username, password: password};
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/login';
        const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'com-epspot-version': Api._getVersion()
                },
                body: JSON.stringify(credentials)
            });

        const jsonRes = await response.json()
        if (response.status === 200 || response.status === 400) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        }
    },

    aliasLogin: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/superadmin/aliasLogin/'+userId;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion(),
                'Authorization': 'Bearer '+Api.accessToken,
            }
        });
        let jsonRes = await response.json();
        console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    forgotPw: async function (username, password) {
        let credentials = {userName: username, password: password};
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/forgotpw';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify(credentials)
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    registerUser: async function (body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/register';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
              body: JSON.stringify(body)
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    confirmRequest: async function (confirmationCode) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/confirmcode/' + confirmationCode;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
            }
        });
        let jsonRes = await response.json();
        console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getSwishQrCode: async function (swishToken) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/payment/swishQrCode';

        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify({swishToken: swishToken})
        });
        console.log(response);
        let qr = await response.arrayBuffer();
        if (response.status === 200) {
            return qr;
        }
    },
    
    getConfig: async function () {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/config';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getUserMe: async function () {
       // console.log('SLEEP. REMOVE!!');
       // await new Promise(r => setTimeout(r, 60000));

        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getMyUsage: async function () {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/usage';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    updateUserMe: async function (me) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/update';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify(me)
        });
        let jsonRes = await response.json();
        console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    updateUserMeNewsletterPreference: async function (receiveNewsletter) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/updatenewsletterpreference';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify({receiveEmail: receiveNewsletter})
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    getStripeCode: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/payment/stripeintentcode';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
       // console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    initiatePrepaidSession: async function (body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/payment/initiatePrepaidSession';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify(body)
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    cancelStripePayment: async function (body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/payment/card/cancelPaymentIntent';
        console.log(body);
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify(body)
        });
        
        if (response.status === 200) {
            return;
        }
        else {
            let jsonRes = await response.json();
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getPaymentCards: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/payment/cards';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
       // console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    removePaymentCard: async function (userId, paymentMethodId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/payment/card/'+paymentMethodId+'/delete';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        if (response.status === 204) {
            return;
        }
        else {
            console.log(response);
            throw response;
        } 
    },

    // set default card and return updated list of payment cards, with updated default setting
    setDefaultPaymentCard: async function (userId, paymentMethodId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/payment/card/'+paymentMethodId+'/setdefault';
        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    topupAccount: async function (userId, body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/payment/topupaccount';
        const response = await fetch(url, {  
            method: 'POST',
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
             body: JSON.stringify(body)
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getPaymentStatus: async function (paymentId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/payment/'+paymentId;
        const response = await fetch(url, {  
            method: 'GET',
            cache:'no-store',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
              
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            if (jsonRes.code !== 10) throw jsonRes;
        } 
    },

    //idArray = [ida, idb, idc, ...]
    getUnregisteredPaymentReceipts: async function (idArray) {
        let filter = 'id EQ ['+idArray.toString()+']';
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/paymentReceipts?filter=' + filter;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getUnregisteredPaymentReceiptById: async function (id) {  
        
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/paymentReceipts/' + id;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            //console.log(jsonRes);
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getPaymentReceipts: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/paymentReceipts';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getTransactions: async function () {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/transactions';
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    /*
    Get a generated PDF receipt which can be displayed in the browser
    */
    getReceiptAsPdf: async function (receiptId, lang) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/paymentReceipts/'+receiptId+'/pdf';
        if (lang) url += '?lang='+lang;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(response);

        } 
    },

    getProvider: async function (providerId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/' + providerId;

        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    getProviderNames: async function () {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/providers/list_names';

        let options = {method: 'POST'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getLocation: async function (providerId, plantId, locationId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/' + providerId + '/plant/' + plantId + '/location/' + locationId;
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getMemberships: async function () {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/me/memberships';
        let options = {method: 'GET'};
        options.headers = { 
            'Authorization': 'Bearer '+Api.accessToken,
            'com-epspot-version': Api._getVersion()  
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
       // console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    /** Get an outlet based on the outlet id. */
    getOutlet: async function (outletId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/outletv2/'+outletId;
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    /** Get an outlet based on the outlet id. */
    getOutlet_LEGACY: async function (outletId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/outlet/'+outletId;
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    /** Get an outlet based on the outlet id. */
    getProviderForOutlet: async function (outletId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/outlet/' + outletId + '/provider';
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getOutletStatus: async function (outletId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/outlet/' + outletId + '/status';
        // To force cache misses all the time, this should however not be needed, we want cache hits if status is unchanged 
        // but I added to ensure that statle data isn't returned and keep it here if I need to test once more
        //url += '?t=' + (new Date()).getTime();
        // Also add header: 'Cache-Control': 'no-store'
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getOutletStatus2: async function (outletId, plantId, providerId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/' + providerId + '/plant/' + plantId + '/outlet/' + outletId + '/status';
        // To force cache misses all the time, this should however not be needed, we want cache hits if status is unchanged 
        // but I added to ensure that statle data isn't returned and keep it here if I need to test once more
        //url += '?t=' + (new Date()).getTime();
        // Also add header: 'Cache-Control': 'no-store'
        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion()
            };
        }
        const response = await fetch(url, options);
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    getSession: async function (sessionId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/session/' + sessionId;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    getSessionV2: async function (sessionId, providerId) {
        let url = INFRASTRUCTURE_SERVICE_URL + `infrastructure/provider/${providerId}/session/${sessionId}/getv2`;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getLocationOutlets: async function (providerId, plantId, locationId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/' + providerId + '/plant/' + plantId + '/location/'+locationId+'/outlets';

        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    getLocationStatus: async function (locationId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/location/'+locationId+'/status';

        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getStatusForLocations: async function (locations) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/locations/status';

        const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
            body: JSON.stringify({locations: locations}),  
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getNearbyLocations: async function (lat, long, distance) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/locations?includeParents=true&lat='+lat+'&long='+long;
        if (distance) url += '&distance='+distance;
        url += '&limit=1000';

        let options = {method: 'GET'};
        if (Api.accessToken) {
            options.headers = { 
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
            };
        }
        else {
            options.headers = { 
                'com-epspot-version': Api._getVersion() 
            };
        }
        const response = await fetch(url, options);

        let jsonRes = await response.json();
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    //Example: {"items":[{"id":"765730011","status":"ACTIVE","startTime":"2020-12-07T10:59:03.991Z","duration":2548,"outletId":"000-000-678","outletName":"Garage 48","totalEnergy":3076,"debitedCost":0,"kind":"Session"}]}
    getActiveSessions: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/sessions?filter=status EQ ACTIVE';
        const response = await fetch(url, {  
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
              },
        });
        let jsonRes = await response.json();
        //console.log('GET CTIVE SESSIONS');
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getActiveSessionsV2: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/sessionsv2';
        const response = await fetch(url, {  
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
              },
        });
        let jsonRes = await response.json();
        //console.log('GET CTIVE SESSIONS');
        //console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    //router.get('/user/:userId/endedsessions', EndedSessionController.list);
    
    getCompletedSessionsV2: async function (userId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/endedsessions';
        const response = await fetch(url, {  
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    // /provider/:providerId/endedsession/:endedSessionId/get
    getEndedSessionV2: async function (providerId, sessionId) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/'+providerId+'/endedsession/'+sessionId+'/get';
        const response = await fetch(url, {  
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion() 
              },
        });
        let jsonRes = await response.json();
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    // Get completed sessions in a one month span, from - (from-30days)
    getCompletedSessions: async function (userId, endDate) {
        //filter=status EQ ENDED&filter=startTime EQ 2020-12-19T24:00Z&filter=endTime EQ 2021-01-19T24:00Z
        let beginDate = moment(endDate).subtract(12,'month').format();
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+userId+'/sessions?filter=status EQ ENDED';
        url += '&filter=startTime EQ '+beginDate;
        url += '&filter=endTime EQ '+endDate;
        const response = await fetch(url, {  
            method: 'GET',
            cache: "no-store",
            headers: {
                'Authorization': 'Bearer '+Api.accessToken,
                'com-epspot-version': Api._getVersion()
              },
        });
        let jsonRes = await response.json();
     //   console.log(jsonRes);
        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },
    
    startSession: async function (userId, body) {
         let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/'+ userId +'/session/start';
        if(!userId) url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/session/start'; 

        const response = await fetch(url, {  
            method: 'POST',
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ Api.accessToken,
                'com-epspot-version': Api._getVersion() 
              },
             body: JSON.stringify(body)
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    startSessionV2: async function (body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/session/start';
        const response = await fetch(url, {  
            method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ Api.accessToken,
                'com-epspot-version': Api._getVersion() 
                },
                body: JSON.stringify(body)
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    startParkingSession: async function (body) {
        let url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/session/startparkingticketsession';
        const response = await fetch(url, {  
            method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion() 
                },
                body: JSON.stringify(body)
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log('ERROR HERE');
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    stopSession: async function (sessionId, userId ) {
        let url = null;
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'com-epspot-version': Api._getVersion() 
          };
        if (userId) {
            url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/user/session/'+sessionId+'/stop';
            headers.Authorization = 'Bearer '+Api.accessToken;
        }
        else {
            url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/session/'+sessionId+'/stop';
        }
        
        const response = await fetch(url, {  
            method: 'POST',
             headers: headers,
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    stopSessionV2: async function (providerId, sessionId ) {
        let url = null;
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'com-epspot-version': Api._getVersion() 
          };
        
          url = INFRASTRUCTURE_SERVICE_URL + 'infrastructure/provider/' +providerId+ '/session/' +sessionId+ '/stop';  
        
        const response = await fetch(url, {  
            method: 'POST',
             headers: headers,
        });
        
        let jsonRes = await response.json();

        if (response.status === 200) {
            return jsonRes;
        }
        else {
            console.log(jsonRes);
            throw jsonRes;
        } 
    },

    getOutletInfo: async function (outletId) {
        let url = STRIPE_SERVICE_URL + 'stripe/outlet/' + outletId;
        const response = await fetch(url, {  
            method: 'GET',
            headers: {
                'com-epspot-version': Api._getVersion() 
            },
        });
        let jsonRes = await response.json();
        if (response.status === 200 || response.status === 400) {
            return jsonRes;
        }
        else {
            throw new Error(jsonRes.code);
        } 
    },
    paySwish: async function (swishPayment) {
        let url = STRIPE_SERVICE_URL + 'swish/payment';
         const response = await fetch(url, {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion() 
              },
            body: JSON.stringify(swishPayment)
        });
        var jsonRes = [];
        if (response.status === 200) {
            jsonRes = await response.json();
            return jsonRes;
        }
        return null;
    },

    payWithCard: async function (cardPayment) {        
        let url = STRIPE_SERVICE_URL + 'stripe/checkout2';
          const response = await fetch(url, {  
             method: 'POST',
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion() 
              },
             body: JSON.stringify(cardPayment)
         });
         var jsonRes = [];
        if (response.status === 200) {
            jsonRes = await response.json();
        }
        console.log(jsonRes);
        return jsonRes;
     },

     payWithCardOld: async function (cardPayment) {        
        let url = STRIPE_SERVICE_URL + 'stripe/checkout';
          const response = await fetch(url, {  
             method: 'POST',
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'com-epspot-version': Api._getVersion() 
              },
             body: JSON.stringify(cardPayment)
         });
         var jsonRes = [];
        if (response.status === 200) {
            jsonRes = await response.json();
        }
        console.log(jsonRes);
        return jsonRes;
     },

     getSessionReference: async function (sessionRequestId) {
        let url = STRIPE_SERVICE_URL + 'stripe/sessionref/' + sessionRequestId;
          const response = await fetch(url, {  
             method: 'GET',
             headers: {
                'com-epspot-version': Api._getVersion() 
              },
         });
            var jsonRes = [];
            if (response.status === 200) {
                jsonRes = await response.json();
            }
            return jsonRes;
     },
     getOutletSession: async function (outletSessionId) {
        let url = STRIPE_SERVICE_URL + 'stripe/session/' + outletSessionId;
          const response = await fetch(url, {  
             method: 'GET',
             headers: {
                'com-epspot-version': Api._getVersion() 
              },
         });
            var jsonRes = [];
            if (response.status === 200) {
                jsonRes = await response.json();
            }
            return jsonRes;
     }
};




const Api = ProductionApi;
export default Api;