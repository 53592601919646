import React, { useState, useEffect, useContext, useRef } from 'react';
import DataStoreContext from 'dataStoreContext';
import 'react-rangeslider/lib/index.css';
import 'moment-duration-format';
import OutletBaseInfo from 'js/components/outletBaseInfo.js';
import Api from 'services/api.js';
//import { usePageVisibility } from 'react-page-visibility';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SessionInputPower from 'js/components/sessionInputPower.js';
import SessionInputHeater from 'js/components/sessionInputHeater.js';
import SessionInputSlotPeriod from 'js/components/sessionInputSlotPeriod.js';
import SessionInputUnitPeriod from '../components/sessionInputUnitPeriod';
import ProgressButton from 'js/components/progressButton';
import CardPaymentView from 'js/views/cardPaymentView.js';
import HelperService from 'services/helpers.js';
import PaymentReceipt from 'js/components/paymentReceipt.js';
import RadioButton from '../components/radioButton';
import 'rc-time-picker/assets/index.css';
import { registerLocale } from 'react-datepicker';
import Nav from 'react-bootstrap/Nav';
import cloneDeep from 'lodash/cloneDeep';
import sv from 'date-fns/locale/sv';
import Tariff from 'js/components/tariff.js';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form';
import Toggle from 'react-toggle';

import { IconPlug, IconCarHeater, IconCarCharge } from 'resources/icons.js';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import 'styles/startChargingView.scss';
import 'styles/common.scss';
import { FormatService } from '../../services/helpers';


export default function StartChargingView({ scannedOutlet, disconnectOutletCB, sessionStartedCB }) {
  const { userMe, activeSessions, setActiveSessions, setHelpTextModal, setInfoModal, paymentReceipts, setPaymentReceipts, notificationToken, isFlutterApp, sendFlutterNotification, appInForeground } = useContext(DataStoreContext);
  //const isVisible = usePageVisibility();
  const [showCardPaymentDialog, setShowCardPaymentDialog] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState(''); //'swish' or 'card'

  /* initSessionProps contains all data needed to start a session
   * limitType: TIME | POWER | NONE
   * limit: kWh or time (s) value at which the session shall stop, ignored if limitType=NONE
   * cost: the estimated/agreed cost (sek) for the session, this is only used for swish/stripe payment
   * delayedStartTime: set if session shall start later (for car heater)
   * allowDisconnect: true if session can be interrupted and then resumed (remove cable doesnt stop session)
   * sessionType: CHARGE | HEAT | POWER
   * metadata: {regNo: xxx} // null if no reg no is used
   */
  const [initSessionProps, setInitSessionProps] = useState({});
  const [pendingReceiptId, setPendingReceiptId] = useState(null);
  const [swishPaymentOngoing, setSwishPaymentOngoing] = useState(false);
  const [sessionStartOngoing, setSessionStartOngoing] = useState(false);
  const [paymentReceipt, setPaymentReceipt] = useState(null);
  const [swishQrUrl, setSwishQrUrl]  = useState(null);
  
  // selectedPaymentMethod is for the "parking ticket" scenario, which allows the user to select between normal payment or to 
  // get it on the parking ticket. The user can only select payment method if the tariff has both CSV and one more payment method 
  //(per the tariffSupportsPaymentMethodChoice method) 
  const [selectedPaymentMethod, setSelectedPaymentMethod]  = useState(null); 
  const [paymentMethodRegNo, setPaymentMethodRegNo]  = useState(''); 
  const [showPaymentInfoModal, setShowPaymentInfoModal]  = useState(true); 
  const [showPaymentInfoModalToggle, setShowPaymentInfoModalToggle]  = useState(false); 

  const [activeTab, setActiveTab] = useState('carTab'); // carTab || heaterTab || powerTab
  const { t, i18n } = useTranslation('views');

  const timerRef = useRef(null);
  const counterRef = useRef(0);

  registerLocale('sv', sv);

  useEffect(() => {
    let us = window.localStorage.getItem("userSettings");
    if (us) {
        us = JSON.parse(us);
        if (us.hidePaymentInfoModal) setShowPaymentInfoModal(false);
    }
  }, []);

  useEffect(() => {
    //console.log('isVisible = '+isVisible);
    if (pendingReceiptId) {
      setSwishPaymentOngoing(true);
      checkPendingReceipt();
    }
    
     //Car pool demo, trigger reg no payment only option
     if(HelperService.isCarPoolDemo(scannedOutlet))
        setSelectedPaymentMethod('CSV_REGISTERED_PAYMENT');
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInForeground, swishQrUrl]);

  const paySwish = async () => {
    let body = {};
    body.paymentMethod = 'SWISH';
    body.totalAmount = parseFloat(initSessionProps.cost.toFixed(2));
    body.sessionData = { outletId: scannedOutlet.id };
    body.sessionData.autoOffType = initSessionProps.limitType;
    body.sessionData.autoOffValue = initSessionProps.limit;
    body.sessionData.sessionType = initSessionProps.sessionType;
    if (initSessionProps.delayedStartTime) body.sessionData.startTime = initSessionProps.delayedStartTime;
    if (initSessionProps.allowDisconnect) body.sessionData.allowDisconnect = initSessionProps.allowDisconnect;

    try {
      setSwishPaymentOngoing(true);
      let intent = await Api.initiatePrepaidSession(body);
      if (!intent || !intent.paymentIntentId) throw new Error('The server could not initiate a swish payment');

      //setLoading(false);
      if (intent && intent.paymentIntentId) {
        setSwishPaymentOngoing(false);
        setPendingReceiptId(intent.paymentIntentId);

        let swishUrl = 'swish://paymentrequest?token=' + intent.secret;

        if (isFlutterApp) {
            swishUrl += '&callbackurl=https://power.epspot.com';
            let payload = '{"command": "LAUNCH_URL", "url":"' + swishUrl + '"}';
            sendFlutterNotification(payload);
        }
        else {
            window.location = swishUrl;
        }
        
        /*
                setTimeout(function() {
                    setInfoModal(["Vi hittar ingen swish-app, prova att betala med kort istället."]);
                    setPendingReceiptId(null);
                    setSwishPaymentOngoing(false);
                  }, 200);

                  window.location = "swish://paymentrequest?token=" + intent.secret + "&callbackurl=merchant%253A%252F%252F";
                  */
      } else {
        throw new Error('The server could not initiate a swish payment');
      }
    } catch (error) {
      console.log(error);
      setInfoModal([[t("startChargingView.swishPaymentFailed")]]);
      setSwishPaymentOngoing(false);
    }
  };


  const handlePcSwish = async () => {
    let body = {};
    body.paymentMethod = 'SWISH';
    body.totalAmount = parseFloat(initSessionProps.cost.toFixed(2));
    body.sessionData = { outletId: scannedOutlet.id };
    body.sessionData.autoOffType = initSessionProps.limitType;
    body.sessionData.autoOffValue = initSessionProps.limit;
    body.sessionData.sessionType = initSessionProps.sessionType;
    if (initSessionProps.delayedStartTime) body.sessionData.startTime = initSessionProps.delayedStartTime;
    if (initSessionProps.allowDisconnect) body.sessionData.allowDisconnect = initSessionProps.allowDisconnect;

    try {
        setSwishPaymentOngoing(true);
        let intent = await Api.initiatePrepaidSession(body);
        if (!intent || !intent.paymentIntentId) throw new Error('The server could not initiate a swish payment');
  
        //setLoading(false);
        if (intent && intent.paymentIntentId) {
            
            setPendingReceiptId(intent.paymentIntentId);

            let qr = await Api.getSwishQrCode(intent.secret);
            const blob = new Blob( [ qr ] );
            const url = URL.createObjectURL( blob );
            
            console.log(url);
            setSwishQrUrl(url);
            setShowCardPaymentDialog(true);  
            setSwishPaymentOngoing(false);
  
          
        } else {
          throw new Error('The server could not initiate a swish payment');
        }
      } catch (error) {
        console.log(error);
        setInfoModal([t("startChargingView.swishPaymentFailed")]);
        setSwishPaymentOngoing(false);
      }

    /*

        setAddTopupAccountError(null);
        let body = {
            paymentType: "SWISH",
            amount: amount,
            currency: "SEK"
        }
        try {
            setProcessingTopup(true);
            let receipt = await Api.topupAccount(userMe.userName, body);
            if (receipt && receipt.receiptMetadata && receipt.receiptMetadata.swishToken) {
                setPendingReceiptId(receipt.id);
                let qr = await Api.getSwishQrCode(receipt.receiptMetadata.swishToken);
                const blob = new Blob( [ qr ] );
                const url = URL.createObjectURL( blob );
                setSwishQrUrl(url);  
                //checkPendingReceipt();
                
                setProcessingTopup(false);
            }
        } catch (error) {
            console.log(error);
            setProcessingTopup(false);
            setAddTopupAccountError(t("views:profileView.paymentFailed") + '('+error.message+')' + t("views:profileView.tryAgain"));
        }
        */
    }

    const handlePcSwishCancel = async () => {
        setSwishPaymentOngoing(false);
        setSwishQrUrl(null);
        setShowCardPaymentDialog(false);
        setPendingReceiptId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        counterRef.current = 0;
    }

  /* The state of the swish payment is checked on visibility (when this view gets focus again) and 
    then periodically after that */
  const checkPendingReceipt = async () => {
    if (!pendingReceiptId) return;
    //console.log('Will check Pending receipt: ' + pendingReceiptId);

    if (counterRef.current > 20) {
        console.log('swish payment timed out');
        setPendingReceiptId(null);
        setSwishQrUrl(null);
        setShowCardPaymentDialog(false);
        setInfoModal([t("startChargingView.swishTimeOut")]);
        counterRef.current = 0;
        setSwishPaymentOngoing(false);
        return;
    }

    try {
     // console.log('Will fetch payment status: ' + pendingReceiptId);
     // console.log('counterRef.current = ' + counterRef.current);

      const receipt = await Api.getPaymentStatus(pendingReceiptId);
      if (receipt && receipt.paymentStatus === 'SUCCESS') {
        if (timerRef.current) clearTimeout(timerRef.current);
        setPendingReceiptId(null);
        counterRef.current = 0;
        handleSuccessfulPayment(receipt);
        setSwishPaymentOngoing(false);
      } else if (receipt && (receipt.paymentStatus === 'PENDING' || receipt.paymentStatus === 'UNKNOWN')) {
      //  console.log('payment pending');
        counterRef.current++;
        if (timerRef.current) clearTimeout(timerRef.current);
        if (appInForeground) timerRef.current = setTimeout(checkPendingReceipt, 3000);
      } else {
        console.log('Payment cancelled');
        setPendingReceiptId(null);
        counterRef.current = 0;
        setSwishPaymentOngoing(false);
        setInfoModal([[t("startChargingView.swishCancelByUser")]]);
      }
    } catch (error) {
      console.log(error);
      setPendingReceiptId(null);
      counterRef.current = 0;
      setSwishPaymentOngoing(false);
      if (error.code === 125) setInfoModal([[t("startChargingView.swishCancelByUser")]]);
      else setInfoModal([[t("startChargingView.swishPaymentUnknownStatus")]]);
    }
  };

  const handleSuccessfulPayment = async (receipt) => {
    // Add the receipt to the persistent list of receipts on this device
    HelperService.addReceiptToUnregisteredPaymentReceipts(receipt.id);
    setPaymentReceipt(receipt);

    // Add this receipt to the top of the receipt history list
    let paymentReceiptsCopy = cloneDeep(paymentReceipts);
    if (paymentReceiptsCopy && paymentReceiptsCopy.length > 0) paymentReceiptsCopy.unshift(receipt);
    else paymentReceiptsCopy = [receipt];
    setPaymentReceipts(paymentReceiptsCopy);

    // Get the new session
    if (receipt.receiptMetadata && receipt.receiptMetadata.session) {
      let providerId = null;
      let activeSession = null;
      if (receipt.receiptMetadata.kind === 'V2Session') {
        providerId = receipt.receiptMetadata.session.providerId;
        activeSession = await Api.getSessionV2(receipt.receiptMetadata.session.id, providerId);
      }  
      else {
        activeSession = await Api.getSession(receipt.receiptMetadata.session.id);
      }
      
      HelperService.addSessionToUnregisteredActiveSessions({sessionId: activeSession.id, providerId: providerId});
      let activeSessionsCopy = [];
      if (activeSessions && activeSessions.length > 0) {
        activeSessionsCopy = activeSessions.map((i) => ({ ...i }));
      }
      activeSessionsCopy.push(activeSession);
      setActiveSessions(activeSessionsCopy);
    }
  };

  const openUrl = (url) => {
    if (isFlutterApp) {
        let payload = '{"command": "LAUNCH_URL", "url":"' + url + '"}';
        sendFlutterNotification(payload);
    }
    else {
        window.open(url);
    }
  };

  const stripeCompletedHandler = async (receipt) => {
    setShowCardPaymentDialog(false);
    setSelectedPaymentType(null);
    handleSuccessfulPayment(receipt);
  };

  const stripeCanceledHandler = () => {
    setSelectedPaymentType(null);
    setShowCardPaymentDialog(false);
  };

  const payCard = async () => {
    try {
      setSelectedPaymentType('card');
      setShowCardPaymentDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  const swapOutlet = () => {
    disconnectOutletCB();
  };

  const handleInitSessionPropsChange = (props) => {
    //console.log(props);
    setInitSessionProps(props);
  };

  const putInQueueHandler = async (sessionData) => {
      try {
        let newActiveSessions = null;
        sessionData.putInQueue = true;
        if (scannedOutlet.useNewModel) {
            await Api.startSessionV2(sessionData);
            newActiveSessions = await Api.getActiveSessionsV2(userMe.userName);
        } 
        else {
            await Api.startSession(userMe.userName, sessionData);
            newActiveSessions = await Api.getActiveSessions(userMe.userName);
        }
        
        setActiveSessions(newActiveSessions.items);
        cancelQueueHandler();
      } catch (error) {
        console.log(error);
        setInfoModal([[t('startChargingView.sessionStartError')]]);
        setSessionStartOngoing(false);
      }
        
    };

    const cancelQueueHandler = () => {
        setSessionStartOngoing(false);
        disconnectOutletCB(); 
      };

    const handleSelectedPaymentMethodChange = (e) => {
        setSelectedPaymentMethod(e.target.value)
      };  

    /* NOTE: Parking ticket sessions are only supported in the legacy model.  
    */
      const startParkingSession = async (body) => {
        let result = await Api.startParkingSession(body);
        if (!result.id) {
            console.log('Error in startParkingSession');
            return;
        }
        let activeSession = await Api.getSession(result.id);
        HelperService.addSessionToUnregisteredActiveSessions({sessionId: activeSession.id});
        let activeSessionsCopy = [];
        if (activeSessions && activeSessions.length > 0) {
            activeSessionsCopy = activeSessions.map((i) => ({ ...i }));
        }
        activeSessionsCopy.push(activeSession);
        setActiveSessions(activeSessionsCopy);
    }
  
    /**
     * This method is a bit of a mess since it now handles 4 different scenarios. 
     * 1. Starting a "standard" session. 2. Starting sessions for modules using the new data/session model, which still is WIP
     * 3. starting the "parking-ticket" session, which itself is a beta/demo feature and 4. Queueing sessions for old fuses which relly just is used by Scania
     */
    const startSession = async () => {
        let body = {};
        body.outletId = scannedOutlet.id;
        
        if(!HelperService.isCarPoolDemo(scannedOutlet)){
          body.autoOffType = initSessionProps.limitType;  
        }
        else{
          body.autoOffType = 'NONE'
        }
        
        body.sessionType = initSessionProps.sessionType;
        
        if (initSessionProps.limit) body.autoOffValue = initSessionProps.limit;
        if (initSessionProps.delayedStartTime) body.startTime = initSessionProps.delayedStartTime;
        if (initSessionProps.allowDisconnect) body.allowDisconnect = initSessionProps.allowDisconnect;
        if (initSessionProps.metadata) body.metadata = initSessionProps.metadata;
        if (notificationToken) body.notificationToken = notificationToken;
        body.notificationLanguage = i18n.language;

        // if selectedPaymentMethod is explicitly set to CSV we have a "parking ticket" session and 
        // must add the regNo to the payload
        if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
            if (body.metadata) body.metadata.regNo = paymentMethodRegNo;
            else body.metadata = { regNo: paymentMethodRegNo };
        }
        try {
            setSessionStartOngoing(true);
            let startdata = null;
            if (scannedOutlet.useNewModel) {
                body.paymentMethod = selectedPaymentMethod;
                if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
                    if (paymentMethodRegNo && paymentMethodRegNo.length > 1) {
                        body.providerCollectAuthenticationMethod = 'LICENSE_PLATE';
                        body.providerCollectAuthenticationValue = paymentMethodRegNo;
                    }
                    else {
                        body.providerCollectAuthenticationMethod = 'EMAIL';
                        // CSV+EMAIL is only allowed for logged in users so the condition below is 
                        // not needed, but just in case...
                        body.providerCollectAuthenticationValue = userMe ? userMe.userName : null;
                    }
                }       
                startdata = await Api.startSessionV2(body);
            }
            else {
                if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
                    if (userMe && !HelperService.isCarPoolDemo(scannedOutlet)) await startParkingSession({userRef: userMe.userName,  ...body});
                    else await startParkingSession(body);
                    setSessionStartOngoing(false);
                    disconnectOutletCB();
                    return;
                }
                else {
                    startdata = await Api.startSession(userMe.userName, body);
                }
            }

            if (!startdata.id && startdata.hasOwnProperty('queuePosition')) {
                setInfoModal([[t('startChargingView.no_capacity_get_in_queue') + (startdata.queuePosition + 1) + ')'],
                () => () => { putInQueueHandler(body) },
                () => () => { cancelQueueHandler() }
                ]);
            }
            else {
                let newActiveSessions = null;
                if (scannedOutlet.useNewModel) {
                    newActiveSessions = await Api.getActiveSessionsV2(userMe.userName);
                }
                else {
                    newActiveSessions = await Api.getActiveSessions(userMe.userName);
                }
                setActiveSessions(newActiveSessions.items);
                setSessionStartOngoing(false);
                disconnectOutletCB();
            }
        } catch (error) {
            console.log(error);
            let maxInitialDebit = 0;
            try {
                maxInitialDebit = await HelperService.calculateMaxInitialDebit(scannedOutlet, scannedOutlet.tariff);    
            } catch (error) {}

            if (error.code === 2003 && scannedOutlet.tariff && scannedOutlet.tariff.paymentMethods  && 
                scannedOutlet.tariff.paymentMethods.includes('EPSPOT_ACCOUNT_PAYMENT') && userMe.account[0].balance < maxInitialDebit) {
                setInfoModal([[t('startChargingView.low_balance')]]);
                setSessionStartOngoing(false);
            }
            if (error.code === 1009) {
                setInfoModal([[t('startChargingView.low_balance')]]);
                setSessionStartOngoing(false);
            } 
            else if (error.code === 7001 && error.message === 'startTime cannot be in the past') {
                let date = new Date(initSessionProps.delayedStartTime);
                let hours = date.getHours();
                let minutes = "0" + date.getMinutes();
                let formattedTime = hours + ':' + minutes.substr(-2);
                setInfoModal([[t('startChargingView.could_not_start_time') + formattedTime + t('startChargingView.is_before_now')]]);
                setSessionStartOngoing(false);
            } else if (error.code === 2001) { // unsupported payment method
                setInfoModal([[t('startChargingView.unsupported_payment_method')]]);
                setSessionStartOngoing(false);
            } else {
                setInfoModal([[t('startChargingView.sessionStartError')]]);
                setSessionStartOngoing(false);
            }
        }
    };

  /* Check if we should show a START button (if user is logged in and therefore dont pay upfront
   *   or if the cost/tariff is 0 and noone has to pay) or payemnt buttons.
   */
/*  const showStartButton = () => {
    //return (!!userMe ||  HelperService.isZeroTariff(scannedOutlet.tariff))
    return !!userMe;
  };
  */

  if (!scannedOutlet || !scannedOutlet.tariff) return null;

  /* Check if this tariff supports that the user selects which payment method to use. 
     Currently just supported for reg-no/parking ticket use case  
  */
  const tariffSupportsPaymentMethodChoice = () => {
    if (!scannedOutlet.tariff.paymentMethods.includes('CSV_REGISTERED_PAYMENT')) return false;
    // if we only have one payment method nothing to chose between
    if (scannedOutlet.tariff.paymentMethods.length <= 1) return false;
    if (scannedOutlet.tariff.paymentMethods.includes('EPSPOT_ACCOUNT_PAYMENT') || scannedOutlet.tariff.paymentMethods.includes('SINGLE_SESSION_PAYMENT')) {
        if (scannedOutlet.tariff.providerCollectRequiredAuth && scannedOutlet.tariff.providerCollectRequiredAuth.includes('LICENSE_PLATE')) return true;
    }
    return false;
  }

  const formatBalance = (account) => {
    if (!account) return '0';
    let sekAccount = account.find((acc) => acc.currency === 'SEK');
    if (!sekAccount) return '0';
    return FormatService.formatCurrency(sekAccount.balance, 2,  'SEK');
    
}
  


  const renderPaymentMethodsPicker = () => {
    if (!selectedPaymentMethod) {
        if (!!userMe) setSelectedPaymentMethod('EPSPOT_ACCOUNT_PAYMENT');
        else setSelectedPaymentMethod('SINGLE_SESSION_PAYMENT');
    }
    return (
        <>
          <div className="line-divider-with-title">
            <div className="line" />
            <span>{t('startChargingView.select_payment_method')}</span>
            <div className="line" />
          </div>

          <div className="selected-payment-radio-container">
            <div className="selected-payment-radios">       
              
                {
                    !!userMe ? 
                    <RadioButton text={t('startChargingView.with_credits')} name="epspotAccount" value="EPSPOT_ACCOUNT_PAYMENT" checked={selectedPaymentMethod === 'EPSPOT_ACCOUNT_PAYMENT'} onChange={handleSelectedPaymentMethodChange}/>
                    :
                    <RadioButton text={t('startChargingView.pay_upfront')} name="singleSession" value="SINGLE_SESSION_PAYMENT" checked={selectedPaymentMethod === 'SINGLE_SESSION_PAYMENT'} onChange={handleSelectedPaymentMethodChange}/>
                }
                
                <RadioButton text={t('startChargingView.pay_lic_plate')} name="preferredLanguage" value="CSV_REGISTERED_PAYMENT" checked={selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT'} onChange={handleSelectedPaymentMethodChange}/>
            </div>
          </div>
          
        </>
    );
  }

  const renderRunningTariffView = () => {
    
    return (
      <>
        <Nav fill defaultActiveKey="carTab" onSelect={(selectedKey) => setActiveTab(selectedKey)}>
          <Nav.Item>
            <Nav.Link eventKey="carTab">
              <div className="nav-header">
                <IconCarCharge />
                <span className="header-text">{t('startChargingView.chargeCar')}</span>
              </div>
            </Nav.Link>
          </Nav.Item>
          {scannedOutlet.allowDelayedStart ? (
            <Nav.Item>
              <Nav.Link eventKey="heaterTab">
                <div className="nav-header">
                  <IconCarHeater />
                  <span className="header-text">{t('startChargingView.heater')}</span>
                </div>
              </Nav.Link>
            </Nav.Item>
          ) : null}
          <Nav.Item>
            <Nav.Link eventKey="powerTab">
              <div className="nav-header">
                <IconPlug />
                <span className="header-text">{t('startChargingView.power')}</span>
              </div>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:'20px'}}>
        <Tariff loggedInView={!!userMe} tariff={scannedOutlet.tariff}/>
        </div>


        { tariffSupportsPaymentMethodChoice() ? renderPaymentMethodsPicker() : null }

        {activeTab === 'carTab' ? <SessionInputPower outlet={scannedOutlet} sessionType="CHARGE" initSessionPropsCB={handleInitSessionPropsChange}></SessionInputPower> : null}
        {activeTab === 'heaterTab' ? <SessionInputHeater outlet={scannedOutlet} initSessionPropsCB={handleInitSessionPropsChange}></SessionInputHeater> : null}
        {activeTab === 'powerTab' ? <SessionInputPower outlet={scannedOutlet} sessionType="POWER" initSessionPropsCB={handleInitSessionPropsChange}></SessionInputPower> : null}
      </>
    );
  };

  const renderSlotTariffView = () => {
    return (
      <>
        {scannedOutlet.tariff.slotPeriod === 24 ? (
          <div className="slot-charging-header">
            <IconPlug />
            <span className="header-text">{t('startChargingView.24hPurchase')}</span>
          </div>
        ) : null}
        {scannedOutlet.tariff.slotPeriod !== 24 ? (
          <div className="slot-charging-header">
            <IconPlug />
            <span className="header-text">{t('startChargingView.periodPurchase')}</span>
          </div>
        ) : null}

        <SessionInputSlotPeriod outlet={scannedOutlet} initSessionPropsCB={handleInitSessionPropsChange}></SessionInputSlotPeriod>
      </>
    );
  };



  const showHelp = (infoText) => {
    if (!infoText) return;
    if (infoText.sv && !infoText.sv.title) infoText.sv.title = scannedOutlet.tariff.unitName;
    if (infoText.en && !infoText.en.title) infoText.en.title = scannedOutlet.tariff.unitName;
    setHelpTextModal([infoText]);
  }

  const getUnitTitle = () => {
    if (!scannedOutlet || !scannedOutlet.tariff || !scannedOutlet.tariff.unitAppTitle) return null;
    return scannedOutlet.tariff.unitAppTitle[i18n.language];
  }

  const getUnitDescription = () => {
    if (!scannedOutlet || !scannedOutlet.tariff || !scannedOutlet.tariff.unitAppDesc) return null;
    if (!scannedOutlet || !scannedOutlet.tariff || !scannedOutlet.tariff.unitAppTitle) return null;
    return {
        sv: {
            title: scannedOutlet.tariff.unitAppTitle.sv,
            text: scannedOutlet.tariff.unitAppDesc.sv
        },
        en: {
            title: scannedOutlet.tariff.unitAppTitle.en,
            text: scannedOutlet.tariff.unitAppDesc.en
        }
      };
  }

  const renderUnitTariffView = () => {
    let unitTitle = getUnitTitle();
    scannedOutlet.tariff.unitInfoText = {
        sv: {
            title: "tvätt",
            text: [
                "Här väljer du om du vill ta emot EpSpots nyhetsbrev.",
                "Nyhetsbrevet skickas sällan och bara när vi har viktig information att förmedla så vi rekommenderar att du väljer att ta emot det."
            ]
        },
        en: {
            title: "wash",
            text: [
                "Here you opt in to the EpSpot Newsletter.",
                "The newsletter is rarely sent and only on occasions when we have important information to share, therefore we recommend you opt in to the newsletter."
            ]
        }
      };

      //scannedOutlet.tariff.unitAppDesc[i18n.language]

    return (
      <>
        <div className="slot-charging-header">
            <span className="header-text">{t('startChargingView.unitPurchase')}</span>
        </div>

        {
            scannedOutlet.tariff.unitAppDesc ? 
            <div className="unit-name" onClick={()=>showHelp(getUnitDescription())}>
                {unitTitle}
                <span style={{marginLeft:'20px', fontSize:'16px'}} className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span>
            </div>
            : <div className="unit-name">{unitTitle}</div>
        }
        

        <SessionInputUnitPeriod outlet={scannedOutlet} initSessionPropsCB={handleInitSessionPropsChange}></SessionInputUnitPeriod>
      </>
    );
  };  
 

  const startIsDisabled = () => {
    // if the user explicitly have set selectedPaymentMethod to CSV it means we require a reg no
    // thus we only enable start to the reg no is valid 
    if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
        if (paymentMethodRegNo.length < 6 || paymentMethodRegNo.length > 8) return true;
    }
      if (userMe || HelperService.isCarPoolDemo(scannedOutlet)) {
        if (initSessionProps.limit === 0) return true;
        return false;
      }
      else {
          if (initSessionProps.limit > 0 || initSessionProps.limitType === 'NONE') return false;
          return true;
      }
    
  };

  // was not able to use current InfoModal
  const renderModal = () => {
    return (
      <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show onHide={() => {}}>
        <Modal.Body className="info-modal">
          <p>{t("startChargingView.loginToUse")}</p>
          <div className="button-row">
            <Button onClick={disconnectOutletCB}>OK</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const notLoggedInWithZeroTariff = () => {
    if (!userMe && scannedOutlet.tariff.type === 'RUNNING' && (scannedOutlet.tariff.feeHour + scannedOutlet.tariff.feeKwh + scannedOutlet.tariff.singlePaymentOpeningFee) === 0) {
      return true;
    }
    else if (!userMe && scannedOutlet.tariff.type === 'SLOT' && scannedOutlet.tariff.feeTimeslot === 0) {
        return true;
    }
    return false;
  };

  const handleRegNoChange = (e) => {
    setPaymentMethodRegNo(e.target.value.toUpperCase().trim());
    }

    const handleRegNoBlur = (e) => {
        
    }


  const renderRegisteredUserPaymentButtons = () => {
    
    if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
        return (
            <div className="unregistered-payment-buttons">
                <Form.Group>
                    <Form.Label>{t('startChargingView.enter_lic_plate')}</Form.Label>
                    <Form.Control size="lg" id="regNo" name="regNo" type="text" placeholder="ABC123" value={paymentMethodRegNo}
                        onChange={handleRegNoChange} onBlur={handleRegNoBlur}/>
                </Form.Group>
                <ProgressButton disabled={startIsDisabled()} className="pay-button" text={t('startChargingView.start')} loading={sessionStartOngoing} clickCB={startSession} />
            </div>
        );
    }
    else return (
        <ProgressButton disabled={startIsDisabled()} className="pay-button" text={t('startChargingView.start')} loading={sessionStartOngoing} clickCB={startSession} />
    );
  }

  const renderUnregisteredUserPaymentButtons = () => {
    
   
    if (selectedPaymentMethod === 'CSV_REGISTERED_PAYMENT') {
        return (
            <div className="unregistered-payment-buttons">
                <Form.Group>
                    <Form.Label>{t('startChargingView.enter_lic_plate')}</Form.Label>
                    <Form.Control size="lg" id="regNo" name="regNo" type="text" placeholder="ABC123" value={paymentMethodRegNo}
                        onChange={handleRegNoChange} onBlur={handleRegNoBlur}/>
                </Form.Group>
                <ProgressButton disabled={startIsDisabled()} className="pay-button" text={t('startChargingView.start')} loading={sessionStartOngoing} clickCB={startSession} />
            </div>
        );
    }
    else {
        return (
            <>
              <div className="line-divider-with-title">
                <div className="line" />
                <span>{t("startChargingView.payWith")}</span>
                <div className="line" />
              </div>
    
              <ProgressButton disabled={(startIsDisabled() || initSessionProps.cost < 1)} className="pay-button" text="Swish" loading={swishPaymentOngoing} clickCB={paySwish} />
                 
              <Button disabled={(startIsDisabled() || initSessionProps.cost < 3)} className="pay-button" onClick={payCard}>
                {t("startChargingView.card")}
              </Button>
              
              {(startIsDisabled() || initSessionProps.cost < 1) ? null : 
                    <div className="swish-from-other">{t("startChargingView.swisch_from_other_device")} 
                        <span onClick={()=>{ handlePcSwish() }}>{t("startChargingView.click_here")} </span>
                    </div> 
                }
                <div className="swish-from-other">{t("startChargingView.unreg_terms_text")}  
                        <span className="link" onClick={()=>openUrl('https://www.epspot.com/tos')}> {t("startChargingView.unreg_terms_link")} </span>
                </div> 
            </>
        );
    }
    
  }

  

    const paymentInfoModal = () => {

        const handleNoShow = () => {
            let toggleOn = !showPaymentInfoModalToggle;
            setShowPaymentInfoModalToggle(toggleOn);
            
            let us = window.localStorage.getItem("userSettings");
            let usCopy = null;
            if (us) {
                us = JSON.parse(us);
                usCopy = cloneDeep(us);
            }
            else {
                usCopy = {};
            }

            if (!toggleOn) {
                delete usCopy.hidePaymentInfoModal;
            }
            else {
                usCopy.hidePaymentInfoModal = 'yes';
            }
            window.localStorage.setItem("userSettings", JSON.stringify(usCopy));
        }
        
    let loggedInWithEpspotAccountpayment = !!userMe;
    if (loggedInWithEpspotAccountpayment) {
        loggedInWithEpspotAccountpayment = scannedOutlet.tariff && scannedOutlet.tariff.paymentMethods.includes('EPSPOT_ACCOUNT_PAYMENT');
    }
    return (
        <Modal
            size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show
            onHide={() => { setShowPaymentInfoModal(false) }}
        >
            <Modal.Body className="help-text-modal">
                {
                    loggedInWithEpspotAccountpayment ?
                        <div>
                            <p><span>{t("paymentInfoModal.account_balance")}</span> <span>{formatBalance(userMe.account)}</span>.<span> {t("paymentInfoModal.fill_up")}</span></p>
                            <p>{t("paymentInfoModal.need_receipt")}</p>
                        </div>
                        : null
                }
                {
                    !userMe ?
                        <div>
                            <p>{t("paymentInfoModal.refund")}</p>
                            <p>{t("paymentInfoModal.ep_acc")}</p>
                        </div>
                        : null
                }
                <div className="button-row" style={{alignItems:'flex-end'}}>

                    <Toggle checked={showPaymentInfoModalToggle}
                                icons={{
                                    checked: <span className="track-text"></span>,
                                    unchecked: <span className="track-text"></span>,
                                }}
                                onChange={() => { handleNoShow() }} />
                                <span style={{fontSize:'14px', marginLeft:'5px'}}>{t("paymentInfoModal.no_show")}</span>

                    <Button className="ok-btn" onClick={() => { setShowPaymentInfoModal(false) }}>OK</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}


  return (
    <div className="start-charging-view">
      <OutletBaseInfo outlet={scannedOutlet} swapOutletCB={swapOutlet} />
      
      { scannedOutlet.tariff.type === 'RUNNING' ? renderRunningTariffView() : null }
      { scannedOutlet.tariff.type === 'SLOT' ? renderSlotTariffView() : null }
      { scannedOutlet.tariff.type === 'UNIT' ? renderUnitTariffView() : null }
        
      { notLoggedInWithZeroTariff() && !HelperService.isCarPoolDemo(scannedOutlet) ? renderModal() : 
            !!userMe ? renderRegisteredUserPaymentButtons() : renderUnregisteredUserPaymentButtons()
      }

      {showCardPaymentDialog && (selectedPaymentType === 'card' || swishQrUrl) ? <CardPaymentView qrUrl={swishQrUrl} pcSwishCancelCB={handlePcSwishCancel} sessionData={initSessionProps} outlet={scannedOutlet} stripeCompletedCB={stripeCompletedHandler} stripeCanceledCB={stripeCanceledHandler} /> : null}

      {paymentReceipt ? (
        <PaymentReceipt
          receipt={paymentReceipt}
          modal={true}
          closeCB={() => {
            disconnectOutletCB();
          }}
        />
      ) : null}

      {showPaymentInfoModal ? paymentInfoModal() : null}

    </div>
  );
}
