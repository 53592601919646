import React from 'react';

//import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';

const NetworkErrorComponent = ({showNetworkError, closeCB, text}) => {

    /*function Alert(props) {
        return <MuiAlert elevation={6} variant="standard" {...props} />;
    }*/

    return (
        <div>
            NW ERROR
        {/*<Snackbar open={showNetworkError} autoHideDuration={6000}>
            <Alert severity="warning" onClose={closeCB}>
                {text}
            </Alert>
        </Snackbar>
    */}
        </div>
    )
}

export default NetworkErrorComponent;