import React, {useState} from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import ProgressButton from 'js/components/progressButton'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
//import DataStoreContext from 'dataStoreContext'
import ReactCodeInput from 'react-code-input';
import Api from "services/api.js";
import Spinner from 'react-bootstrap/Spinner'
import 'styles/loginForm.scss';
import 'styles/registrationView.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email("Detta är inte en giltig mailadress")
        .required("Du måste ange en mailadress"),
    password: Yup.string("Lösenordet måste var minst 8 tecken långt och innehålla både stor/liten bokstav, siffra och specialtecken.")
        .required("Lösenordet måste var minst 8 tecken långt och innehålla både stor/liten bokstav, siffra och specialtecken.")
        .min(8, "För kort lösenord. (Lösenordet måste var minst 8 tecken långt och innehålla både stor/liten bokstav, siffra och specialtecken.)")
        .matches(/(?=.*[0-9])/, "lösenordet måste innehålla en siffra. (Lösenordet måste var minst 8 tecken långt och innehålla både stor/liten bokstav, siffra och specialtecken.)"),
  });

export default function ForgotPwForm({loginData, showLogin, modal=false, cancelCB}) {
    //const { setUserMe, setPaymentCards, setPaymentReceipts, setActiveSessions, setCompletedSessions} = useContext(DataStoreContext);

    const [pendingActivationCode, setPendingActivationCode]  = useState(false); 
    const [activationCallOngoing, setActivationCallOngoing]  = useState(false);  
    const [accountReady, setAccountReady]  = useState(false); 
    const [confirmationCodeError, setConfirmationCodeError]  = useState(null); 

    const { t } = useTranslation("components");

    const handleActivationInput = async (value) => {
        if (value && value.length === 4) {
            setActivationCallOngoing(true);
            try {
                let response = await Api.confirmRequest(value);
                console.log(response);
                setActivationCallOngoing(false);
                setAccountReady(true);
            } catch (error) {
                console.log('CONFIRMATION FAILED');
                console.log(error);
                if (error.code===1241) {
                    setConfirmationCodeError(t("forgotPwForm.invalid_code"));
                }else {
                    setConfirmationCodeError(t("forgotPwForm.something_went_wrong"));
                }
                setActivationCallOngoing(false);
            }
        }
        else {
            if (confirmationCodeError) setConfirmationCodeError(null);
        } 

        

    }
    

    const handleSubmitClick = async (values, setSubmitting, setErrors) => {
        try {
            await Api.forgotPw(values.email.trim(), values.password);
            setSubmitting(false);
            setPendingActivationCode(true);
        } catch (error) {
            console.log(error);
            if (error.code === 125) setErrors({email: t("forgotPwForm.user_not_registered")});
            else setErrors({email: t("forgotPwForm.unknown_error")});
            setSubmitting(false);
        }
    }

    const renderFormik = (disableEmail=false) => {
        return (
            <Formik
                    initialValues={{ email: loginData ? loginData.email : "", password: "" }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        handleSubmitClick(values, setSubmitting, setErrors);
                    }}
                    validationSchema={LoginSchema}
                >
                    { props => {
                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, status, setFieldValue } = props;

                        if (!pendingActivationCode) {

                        return (
                            <>
                            <Form  style={{marginTop:'30px'}} onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{t("forgotPwForm.existing_account")}</Form.Label>
                                    <Form.Control disabled={disableEmail} size="lg" id="email" name="email" type="text" placeholder={t("forgotPwForm.email_placeholder")} value={values?.email?.trim()}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email && "error"} />
                                    {errors.email && touched.email && (<Form.Text className="input-feedback"> {errors.email} </Form.Text>)}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("forgotPwForm.new_password")}</Form.Label>
                                    <InputGroup>
                                       
                                        
                                     

                                    <Form.Control size="lg" id="password" name="password" type={values.showPassword ? "text": "password"} placeholder={t("forgotPwForm.pw_placeholder")} value={values.password}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.password && touched.password && "error"}/>
                                     <Form.Text className="showPasswordIcon" name="showPassword"> {values.showPassword ? <FontAwesomeIcon onClick={ () => setFieldValue("showPassword",false) } icon={faEyeSlash}/>:<FontAwesomeIcon onClick={ () => setFieldValue("showPassword", true) } icon={faEye}/>} </Form.Text>
                                        {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}     
                                    </InputGroup>
                                     {!!status && (<Form.Text className="input-feedback"> {status} </Form.Text>)}  
                                     {errors.password && touched.password && (<Form.Text className="input-feedback password"> {errors.password} </Form.Text>)}   
                                </Form.Group>
                                

                                <div className="confirm-cancel-button-row">
                                    <ProgressButton disabled={( !(values && values.email && values.email.length > 0) || errors.email) || (!touched.password || errors.password) } 
                                        className="login-button" text={t("forgotPwForm.change_pw")} loading={isSubmitting} clickCB={handleSubmit}/>
                                    <Button className="cancel-button" type="cancel" onClick={cancelCB}>{t("forgotPwForm.cancel")}</Button>    
                                </div>
                                <Form.Text style={{textAlign:'center', marginTop:'-30px', marginBottom:'10px', color:'#555555'}}> {t("forgotPwForm.activation_email_after_change_pw_request")}</Form.Text>    
                            </Form>

                            
                            </>
                        );
                        }
                        else {
                            return renderActivationCodeInput(values);
                        }
                    }}
                </Formik>
        );
    }

    const renderActivationCodeInput = (values) => {
        return (
            <div className="code-input-container">
                <p className="welcome-text">{t("forgotPwForm.enter_code")}</p>        
                <ReactCodeInput initialFocus={true} disabled={ accountReady || activationCallOngoing } className="react-code-input2" placeholder="" type='text' fields={4} onChange={handleActivationInput}/>
                { activationCallOngoing ? <Spinner className="activation-call-spinner" animation="border" /> : null }
                { confirmationCodeError ? <Form.Text className="input-feedback" style={{color:'red'}}> {confirmationCodeError} </Form.Text> : null }
                {(!accountReady && !activationCallOngoing) ? <Form.Text style={{marginTop:'20px', textAlign:'center',maxWidth:'300px', color:'#555555'}} className="input-feedback">{t("forgotPwForm.no_email")}</Form.Text> : null }
                
                {(accountReady) ?
                    <div className="account-ready-text">
                        <div>{t("forgotPwForm.password_activated")}</div>
                        { modal ? <Button style={{minWidth:'80px'}} onClick={cancelCB}>Ok</Button> : <Button onClick={() => showLogin({email: values.email, password: values.password})}><FontAwesomeIcon icon={ faSignInAlt } />{t("forgotPwForm.log_in")}</Button>}                 
                    </div>
                :    <div >
                        <span className="cancel-change-pw-link" onClick={cancelCB }>{t("forgotPwForm.cancel_password_change")}</span>
                    </div>
                } 
                
            </div>
        );
    }

    if (modal) {
        return (
            <Modal className="login-form" show aria-labelledby="contained-modal-title-vcenter" onHide={()=>{}}>
                <div className="content">
                    <h1>{t("forgotPwForm.change_pw")}</h1>
                { renderFormik(true) }
                </div>
            </Modal>
        );
    }
    else {
        return (
            <div className="login-form">
                    <div className="photo-header-bg"> <div className="cover-color"/> </div>                 
                    <div className="content-container">    
                        <div className="button-heading">
                            <h1>{t("forgotPwForm.change_pw")}</h1>
                        </div>
                    
                { renderFormik(false) }
                </div>
            </div>
        );
    }
    
}

// {(pendingActivationCode) ? renderActivationCodeInput() 