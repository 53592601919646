import React from 'react'

const DataStoreContext = React.createContext({
    scannedOutlet: null,
    setScannedOutlet: () => {},

    externalOutletToOpen: null,
    setExternalOutletToOpen: () => {},

    userSettings: null,
    setUserSettings: () => {},
    infoModal: null,
    setInfoModal: () => {},
    supportModal: null,
    setSupportModal: () => {},
    historicalSession: null,
    setHistoricalSession: () => {},
    // all payment related data for the user, eg. paymentData.cards
    paymentCards: null,
    setPaymentCards: () => {},
    transactions: null,
    setTransactions: () => {},
    paymentReceipts: null,
    setPaymentReceipts: () => {},
    memberships: null, //the memberships and accesses for this user
    setMemberships: () => {},
    nearbyLocations: null, //locations close to the user
    setNearbyLocations: () => {},
    userMe: null,
    setUserMe: () => {},
    userPosition: null, //the user's [lat, lng] position
    setUserPosition: () => {},
    config: null, // the server side system wide config, currently only containing swishServiceFee (which is not used any more!!)
    setConfig: () => {},
    activeSessions: null, // the active sessions for this user
    setActiveSessions: () => {},
    completedSessions: null, // the completed sessions for this user
    setCompletedSessions: () => {},
    notificationToken: null, // the notification token for the current device
    setNotificationToken: () => {},
    flutterAppVersion: null, // the flutter app version
    setFlutterAppVersion: () => {},
    isFlutterApp: null,
    setIsFlutterApp: () => {},
    sendFlutterNotification: null,
    setSendFlutterNotification: () => {},
    appInForeground: null,
    setAppInForeground: () => {},
  })

export const DataStoreProvider = DataStoreContext.Provider
//export const DataStoreConsumer = DataStoreContext.Consumer

/*
MIN_INIT_SCREEN_VISIBILITY: 3000,
 */

export const Constants = {
    START_FEE: 500,
    MIN_INIT_SCREEN_VISIBILITY: 3000,
};


export default DataStoreContext