import React, {useContext} from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form'
import HelperService from "services/helpers.js";
import Button from 'react-bootstrap/Button'
import ProgressButton from 'js/components/progressButton'
import DataStoreContext from 'dataStoreContext'
import Api from "services/api.js";
import validator from 'validator';
import RadioButton from 'js/components/radioButton.js'
import 'styles/profileForm.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';

const ProfileSchema = Yup.object().shape({
    firstName: Yup.string()
    .required("Du måste ha ett förnamn")
    .min(2, "Förnamnet är för kort")
    .max(50, "Förnamnet är för långt"),
  lastName: Yup.string()
    .required("Du måste ha ett efternamn")
    .min(2, "Efternamnet är för kort")
    .max(50, "Efternamnet är för långt"),
    phone: Yup.string()
        .min(5, "Mobilnumret är för kort")
        .max(20, "Mobilnumret är för långt")
        .test("phoneValidation", "Ogiltigt mobilnummer", 
            function(value) {
                if (value) return validator.isMobilePhone(value);
                else return true;
            }),
    preferredlanguage: Yup.string(),
    subscribedToNewsletter: Yup.string()       
  });

export default function ProfileForm({user, cancelCB}) {

    const initialValues = { firstName: user.firstName, lastName: user.lastName, phone: user.phone, preferredLanguage: user.preferredLanguage, subscribedToNewsletter: user.subscribedToNewsletter ? 'true' : 'false'};

    const { setUserMe} = useContext(DataStoreContext);

    const { t, i18n } = useTranslation('components');

    /*const getModifiedProps = (original, modified) => {
        let delta = reduce(original, function(result, value, key) {
            return isEqual(value, modified[key]) ?
                result : result.concat(key);
        }, []);
        let modifiedProps = {};
        for (let i = 0; i < delta.length; i++) {
            modifiedProps[delta[i]] = modified[delta[i]];
        }
        return modifiedProps;
    }*/
    

    const handleSubmitClick = async (values, setSubmitting, setErrors) => {
        console.log('HANDLE SUBMIT');
        let delta = HelperService.getModifiedProps(initialValues, values);
        console.log(delta);

        try {
            if (delta.hasOwnProperty('subscribedToNewsletter')) {
                await Api.updateUserMeNewsletterPreference(delta.subscribedToNewsletter === 'true');
            }
            const userMe = await Api.updateUserMe(delta);
            
            if (userMe) {
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage);       
            }
            setSubmitting(false);
            cancelCB();
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            cancelCB();
        }
    }

// 

    return (
            <div className="profile-form">
                                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting, setErrors}) => { handleSubmitClick(values,  setSubmitting, setErrors); }}
                    validationSchema={ProfileSchema} >
                    { props => {
                        const { values, isValid, dirty, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;

                        return (
                            <>
                            <Form  onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{t("profileForm.first_name")}</Form.Label>
                                    <Form.Control size="lg" id="firstName" name="firstName" type="text" placeholder={t("profileForm.first_name_placeholder")} value={values.firstName}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.firstName && touched.firstName && "error"} />
                                    {errors.firstName && touched.firstName && (<Form.Text className="input-feedback"> {errors.firstName} </Form.Text>)}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("profileForm.last_name")}</Form.Label>
                                    <Form.Control size="lg" id="lastName" name="lastName" type="text" placeholder={t("profileForm.last_name_placeholder")} value={values.lastName}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.lastName && touched.lastName && "error"}/>
                                     {errors.lastName && touched.lastName && (<Form.Text className="input-feedback"> {errors.lastName} </Form.Text>)}   
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("profileForm.mobile")}</Form.Label>
                                    <Form.Control size="lg" id="phone" name="phone" type="text" placeholder={t("profileForm.mobile_placeholder")} value={values.phone}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.phone && touched.phone && "error"}/>
                                     {errors.phone && touched.phone && (<Form.Text className="input-feedback"> {errors.phone} </Form.Text>)}   
                                </Form.Group>

                                <Form.Group>
                                <Form.Label className="profile-language-label" style={{display:'block'}}>{t("profileForm.language")}</Form.Label>
                                    <RadioButton text={t("profileForm.sv")} name="preferredLanguage" value="sv" checked={values.preferredLanguage === 'sv'} onChange={handleChange}/>
                                    <RadioButton text={t("profileForm.en")} name="preferredLanguage" value="en" checked={values.preferredLanguage === 'en'} onChange={handleChange}/>
                                </Form.Group>

                                <Form.Group>
                                <Form.Label className="profile-language-label" style={{display:'block'}}>{t("profileForm.newsletter")}</Form.Label>
                                    <RadioButton text={t("profileForm.yes")} name="subscribedToNewsletter" value={'true'} checked={values.subscribedToNewsletter === 'true'} onChange={handleChange}/>
                                    <RadioButton text={t("profileForm.no")} name="subscribedToNewsletter" value={'false'} checked={values.subscribedToNewsletter === 'false'} onChange={handleChange}/>
                                </Form.Group>

                                <div className="button-row">
                                <ProgressButton disabled={!(isValid && dirty)} className="submit-button" text={t("profileForm.update")} loading={isSubmitting} clickCB={handleSubmit}/>
                                <Button className="cancel-button" disabled={isSubmitting} onClick={cancelCB}>{t("profileForm.cancel")}</Button>
                                </div>
                            </Form>  
                            </>
                        );
                    }}
                </Formik>
                </div>
    );
}