
import React, {useState} from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form'
import { faChevronDown, faChevronUp,faSignInAlt, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import ProgressButton from 'js/components/progressButton'
import Api from "services/api.js";
import Toggle from 'react-toggle';
import validator from 'validator';
import RadioButton from 'js/components/radioButton.js'
import Spinner from 'react-bootstrap/Spinner'
import ReactCodeInput from 'react-code-input';
import 'styles/registrationView.scss';
import 'styles/common.scss';

// Translation
import { useTranslation } from 'react-i18next';
//const { detect } = require('detect-browser');
//const browser = detect();

export default function RegistrationView({showLogin}) {
    const [termsAccepted, setTermsAccepted]  = useState(false);  
    const [pendingActivationCode, setPendingActivationCode]  = useState(false);  
    const [activationCallOngoing, setActivationCallOngoing]  = useState(false);  
    const [accountReady, setAccountReady]  = useState(false);  
    const [confirmationCodeError, setConfirmationCodeError]  = useState(null);  
    const [registrationError, setRegistrationError]  = useState(null);  
    const [key, setKey] = useState(null)


    // Translation
    const { t } = useTranslation('views');

    const RegistrationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email(t("registrationView.emailInvalid"))
            .required(t('registrationView.emailRequired')),
        password: Yup.string(t("registrationView.passwordRequired"))
            .required(t("registrationView.passwordRequired"))
            .min(8, t("registrationView.passwordShort"))
            .matches(/(?=.*[0-9])/, t("registrationView.passwordNumber"))
            .matches(/(?=.*[A-Z])/, t("registrationView.passwordLetter")),
        firstName: Yup.string()
        .trim()
        .required(t("registrationView.nameRequired"))
        .min(2, t("registrationView.nameShort"))
        .max(50, t("registrationView.nameLong")),
      lastName: Yup.string()
        .trim()
        .required(t("registrationView.lastnameRequired"))
        .min(2, t("registrationView.lastnameShort"))
        .max(50, t("registrationView.lastnameLong")),
    phone: Yup.string()
        .min(5, t("registrationView.numberShort"))
        .max(20, t("registrationView.numberLong"))
        .test("phoneValidation", t("registrationView.numberInvalid"), 
            function(value) {
                if (value) return validator.isMobilePhone(value);
                else return true;
            }),
        preferredlanguage: Yup.string()           
      });



    const handleSubmitClick = async (values, setSubmitting, setErrors) => {
        console.log('Submit');
        let payload = {
            userName: values.email.trim(),
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            preferredLanguage: values.preferredLanguage
        };
        if (values.phone && values.phone.length > 0) payload.phone = values.phone.replace(/ /g, '');

        try {
            let response = await Api.registerUser(payload);
            setPendingActivationCode(true);
            setSubmitting(false);
            console.log(response);

        } catch (error) {
            console.log('REG FAILED');
            console.log(error);
            setSubmitting(false);
            setRegistrationError(error.code);
        }
        setPendingActivationCode(true);
        setSubmitting(false);

    }

    const handleActivationInput = async (value) => {
        if (value && value.length === 4) {
            setActivationCallOngoing(true);
            try {
                let response = await Api.confirmRequest(value);
                console.log(response);
                setActivationCallOngoing(false);
                setAccountReady(true);
            } catch (error) {
                console.log('CONFIRMATION FAILED');
                console.log(error);
                if (error.code===1241) {
                    setConfirmationCodeError(t("registrationView.badCode"));

                }else {
                    setConfirmationCodeError(t("registrationView.somethingWentWrong"));
                }
                // currently no support to clear all all fields, this is a temporary ugly solution
                setKey(key + 1);
                setActivationCallOngoing(false);
            }
        }
        else {
            if (confirmationCodeError) setConfirmationCodeError(null);
        } 

        

    }

    /** quick hack to cover for the case when user already has received his code earlier. We should use a better step wiward implementation 
     *  for registration to not have to use viewport scrolling, but this will have to do for now...
     */
    const showActivationInput = () => {
        setPendingActivationCode(true);
        var top = document.getElementById('page-3').offsetTop; 
        
        document.getElementById('page-1').style.display = 'none'; 
        document.getElementById('page-2').style.display = 'none'; 
        window.scrollTo(0, top);          
    }


    const gotoPage = (page) => {
        document.getElementById(page).scrollIntoView(true);
        /*if (browser && browser.name.toLowerCase() === "safari" || browser.name.toLowerCase() === "ios") {
            document.getElementById(page).scrollIntoView(true);
        } else {
            document.getElementById(page).scrollIntoView({ behavior: "smooth" });
        }*/
    }

    const renderTermsView = (isSubmitting, handleSubmit) => {
        return (
        <>
            <div className="top-center">
                <Button className="prev-page-button" onClick={() => gotoPage('page-2')}><FontAwesomeIcon icon={ faChevronUp } /> {t("registrationView.previous")}</Button>
            </div> 

            <Form.Group>
            <h3 className="terms-header">{t("registrationView.ourTermsOfUse")}</h3>
                <div style={{display:'flex', alignItems: 'center'}}>
                <Toggle name="acceptTos" icons={{ checked: null, unchecked: null }} onChange={()=>{setTermsAccepted(!termsAccepted)}} />
                <span style={{marginLeft: '10px'}}>{t("registrationView.iAccept")} <a href="https://www.epspot.com/tos/" target="_blank" rel="noopener noreferrer">{t("registrationView.termsOfUse")}</a> {t("registrationView.and")} <a href="https://www.epspot.com/integritetspolicy/"  target="_blank" rel="noopener noreferrer">{t("registrationView.privacyPolicy")}</a></span>
                </div>
            </Form.Group>

            
            <div className="confirm-cancel-button-row">
                <ProgressButton disabled={!termsAccepted} className="registration-button" text="Skapa Konto" loading={isSubmitting} clickCB={handleSubmit}/>
                <Button className="cancel-button" type="cancel" onClick={showLogin}>{t("registrationView.cancel")}</Button>    
            </div>
            
            <Form.Text style={{textAlign:'center', fontSize: '13px', color:'#555555'}}> {t("registrationView.activationEmailAfterRegistration")}</Form.Text>    
        </>
        );
    }

    const renderActivationCodeInput = (values) => {
        return (
            <div className="code-input-container">
                <p className="welcome-text">{t("registrationView.enterCode")}</p>        
                <ReactCodeInput autoFocus={true} disabled={ accountReady || activationCallOngoing } className="react-code-input2" placeholder="" type='text' fields={4} key={key} forceUppercase={true} onChange={handleActivationInput}/>
                { activationCallOngoing ? <Spinner className="activation-call-spinner" animation="border" /> : null }
                { confirmationCodeError ? <Form.Text className="input-feedback" style={{color:'red'}}> {confirmationCodeError} </Form.Text> : null }
                {(!accountReady && !activationCallOngoing) ? <Form.Text style={{marginTop:'20px', fontSize:'13px', marginBottom:'30px', textAlign:'center',maxWidth:'400px', color:'#555555'}} className="input-feedback">{t("registrationView.noEmail")}</Form.Text> : null }
                
                {(accountReady) ?
                    <div className="account-ready-text">
                        <div>{t("registrationView.accountActivated")}</div>
                        <Button onClick={() => showLogin({email: values.email, password: values.password})}><FontAwesomeIcon icon={ faSignInAlt } />{t("registrationView.logIn")}</Button>
                    </div>
                :   <div >
                        <span className="link-to-activation-input" onClick={showLogin }> {t("registrationView.cancelRegistration")} </span>
                    </div> 
                } 
                
            </div>
        );
    }

    const renderRegistrationError = (code, values) => {
        if (code === 124) {
            return (
                <div className="error-text">
                    <p className="welcome-text">{t("registrationView.emailInUse")}</p>        
                    
                    <Button onClick={() => showLogin({email: values.email, password: values.password})}><FontAwesomeIcon icon={ faSignInAlt } /> {t("registrationView.logIn")}</Button>
                    
                </div>
            );
        }
        else {
            return (
                <div>
                    {t("registrationView.error")}
                </div>    
            );
        }
        
    }

    
    
    return (
            <div className="registration-view">
                <div className="photo-header-bg"> <div className="cover-color"/> </div>

                <div className="content-container">

                <div className="button-heading">
                    <h1>{t("registrationView.registerAccount")}</h1>
                    <div className="edit-button-with-label">
                        <span>{t("registrationView.haveAccount")}</span>
                    <Button className="edit-button" onClick={showLogin}><FontAwesomeIcon icon={ faSignInAlt } /> {t("registrationView.logIn")}</Button>
                    </div>
                </div>

                <div className="step-container" id="step-container">
                
                
                <Formik
                    initialValues={{ email: "", password: "", firstName: "", lastName: "", phone: "", preferredLanguage: "sv" ,showPassword: false}}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        handleSubmitClick(values, setSubmitting, setErrors);
                    }}
                    validationSchema={RegistrationSchema}
                >
                    { props => {
                        const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = props;


                        return (
                            <>
                            <Form  onSubmit={handleSubmit}>

                            <div className="page-1" id="page-1">
                                <div className="page-container">

                            <p className="welcome-text">{t("registrationView.needCredentials")} 
                            &nbsp;<span className="link-to-activation-input" onClick={()=>{showActivationInput()}}>{t("registrationView.click")}</span>.
                            </p>

                                <div className="name-form">
                                    <Form.Group>
                                        <Form.Label>{t("registrationView.firstName")}</Form.Label>
                                        <Form.Control size="lg" id="firstName" name="firstName" type="text" placeholder={t("registrationView.firstName")} value={values.firstName}
                                            onChange={handleChange} onBlur={handleBlur} className={errors.firstName && touched.firstName && "error"} />
                                        {errors.firstName && touched.firstName && (<Form.Text className="input-feedback"> {errors.firstName} </Form.Text>)}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{t("registrationView.lastName")}</Form.Label>
                                        <Form.Control size="lg" id="lastName" name="lastName" type="text" placeholder={t("registrationView.lastName")} value={values.lastName}
                                            onChange={handleChange} onBlur={handleBlur} className={errors.lastName && touched.lastName && "error"}/>
                                        {errors.lastName && touched.lastName && (<Form.Text className="input-feedback"> {errors.lastName} </Form.Text>)}   
                                    </Form.Group>
                                </div>

                                <Form.Group>
                                    <Form.Label>{t("registrationView.email")}</Form.Label>
                                    <Form.Control size="lg" id="email" name="email" type="text" placeholder={t("registrationView.email_placeholder")} value={values?.email?.toLowerCase().trim()}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.email && touched.email && "error"} />
                                    {errors.email && touched.email && (<Form.Text className="input-feedback"> {errors.email} </Form.Text>)}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("registrationView.password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control size="lg" id="password" name="password" type={values.showPassword ? "text": "password"} placeholder={t("registrationView.password_placeholder")} value={values.password}
                                                      onChange={handleChange} onBlur={handleBlur} className={errors.password && touched.password && "error"}/>
                                        <Form.Text name="showPasswordIcon" className="showPasswordIcon"> {values.showPassword ? <FontAwesomeIcon onClick={ () => setFieldValue("showPassword",false) } 
                                                   icon={faEyeSlash}/>:<FontAwesomeIcon onClick={ () => setFieldValue("showPassword", true) } icon={faEye}/>} </Form.Text>
                                    </InputGroup>
                                    {errors.password && touched.password && (<Form.Text className="input-feedback"> {errors.password} </Form.Text>)}   
                                </Form.Group>

                                
                                    <div className="bottom-center">
                                        <Button className="next-page-button" onClick={() => gotoPage('page-2')}
                                            disabled={(!touched.firstName || errors.firstName) || 
                                                (!touched.lastName || errors.lastName) ||
                                                (!touched.email || errors.email) || 
                                                (values.password.length < 3  || errors.password) 
                                        }><FontAwesomeIcon icon={ faChevronDown } /> {t("registrationView.next")}</Button>
                                    </div>
                                    </div>    
                                </div>

                                <div className="page-2" id="page-2">
                                <div className="page-container">
                                    <div className="top-center">
                                        <Button className="prev-page-button" onClick={() => gotoPage('page-1')}><FontAwesomeIcon icon={ faChevronUp } /> {t("registrationView.previous")}</Button>
                                    </div> 

                                    <p className="welcome-text">{t("registrationView.mobileNumberInfo")}</p>

                                    <Form.Group>
                                    <Form.Label>{t("registrationView.mobile")}</Form.Label>
                                    <Form.Control size="lg" id="phone" name="phone" type="text" placeholder="+46700000000" value={values.phone}
                                        onChange={handleChange} onBlur={handleBlur} className={errors.phone && touched.phone && "error"}/>
                                     {errors.phone && touched.phone && (<Form.Text className="input-feedback"> {errors.phone} </Form.Text>)}   
                                </Form.Group> 

                                <Form.Group>
                                <Form.Label style={{display:'block', marginTop:'40px'}}>{t("registrationView.lng")}</Form.Label>
                                    <Form.Text className="sub-title"> {t("registrationView.chooseLanguage")} </Form.Text>
                                    <RadioButton text={t("registrationView.radio_text_sv")} name="preferredLanguage" value="sv" checked={values.preferredLanguage === 'sv'} onChange={handleChange}/>
                                    <RadioButton text={t("registrationView.radio_text_en")} name="preferredLanguage" value="en" checked={values.preferredLanguage === 'en'} onChange={handleChange}/>
                                </Form.Group>  

                                    <div className="bottom-center">
                                        <Button className="next-page-button" onClick={() => gotoPage('page-3')} ><FontAwesomeIcon icon={ faChevronDown } /> {t("registrationView.next")}</Button>
                                    </div>   
                                </div>
                                </div>

                                <div className="page-3" id="page-3">
                                <div className="page-container">
                                    {(!pendingActivationCode) ? renderTermsView(isSubmitting, handleSubmit)
                                    :
                                    (!registrationError) ? renderActivationCodeInput(values) : renderRegistrationError(registrationError, values)
                                    } 
                                </div>
                                </div>

                            </Form>

                            
                            </>
                        );
                    }}
                </Formik>
                </div>
                </div>
            </div>
    );
}