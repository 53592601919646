import React, {useState, useContext} from 'react';
import DataStoreContext from 'dataStoreContext';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {ReceiptBackground} from 'resources/icons.js';
import HelperService, {FormatService} from 'services/helpers.js';
import visa from 'resources/visa.png';
import mastercard from 'resources/mastercard.png';
import moment from 'moment';
import ProgressButton from 'js/components/progressButton'
import Api from 'services/api';
import 'moment/locale/sv'
import 'moment-duration-format';

import 'styles/paymentReceipt.scss';

import { useTranslation } from 'react-i18next';


export default function PaymentReceipt({receipt, modal, closeCB}) {
    
    const { t, i18n } = useTranslation("components");
    const [generatingPdf, setGeneratingPdf]  = useState(false);
    moment.locale(i18n.language);
    //receiptMetadata:{newBalance:5000,cardBrand:"visa",last4:"4242"}
    const { isFlutterApp, sendFlutterNotification } = useContext(DataStoreContext);

    const openPdf = async (receipt) => {
        try {
            setGeneratingPdf(true);
            let pdfUrl = null;
            if (receipt.pdfUrl && receipt.pdfUrl[i18n.language]) {
                pdfUrl = receipt.pdfUrl;
            }
            else {
                pdfUrl = await Api.getReceiptAsPdf(receipt.id, i18n.language);
            }
            
            //var file = new Blob([pdf], {type: 'application/pdf'});
            //var fileURL = URL.createObjectURL(file);
            if (!pdfUrl || !pdfUrl[i18n.language]) throw new Error('PDF Receipt not generated');
            if (isFlutterApp) {
                let payload = '{"command": "LAUNCH_URL", "url":"' + pdfUrl[i18n.language] + '"}';
                //sendFlutterNotification(pdfUrl[i18n.language]);
                sendFlutterNotification(payload);
            }
            else {
                window.open(pdfUrl[i18n.language]);
            }
            setGeneratingPdf(false);
        } catch (error) {
            console.log(error);
            setGeneratingPdf(false);
        }
    }

    const getFormatedVatValue = (receipt) => {
        const deductionVat = (receipt.vat/( 100 + receipt.vat )) * 100;

        if ( !receipt.hasRefund ) {
            return FormatService.formatCurrency(deductionVat * receipt.totalAmount, 2, 'SEK');
        }
        else {
            let refund = HelperService.getRefundAmount(receipt);
            let paidSum = receipt.totalAmount - refund;
            return FormatService.formatCurrency(deductionVat * paidSum, 2, 'SEK');
        }
        
    }

    const getDescriptionText = () => {
        if (!receipt || !receipt.receiptMetadata || !receipt.receiptMetadata.description) return null;
        if (i18n.language === 'en') {
            if (receipt.receiptMetadata.description.en && receipt.receiptMetadata.description.en.length > 0) return receipt.receiptMetadata.description.en;
            if (receipt.receiptMetadata.description.sv && receipt.receiptMetadata.description.sv.length > 0) return receipt.receiptMetadata.description.sv;
            return null;
        }
        if (i18n.language === 'sv') {
            if (receipt.receiptMetadata.description.sv && receipt.receiptMetadata.description.sv.length > 0) return receipt.receiptMetadata.description.sv;
            if (receipt.receiptMetadata.description.en && receipt.receiptMetadata.description.en.length > 0) return receipt.receiptMetadata.description.en;
            return null;
        }
    }

    const renderPaymentCard = (cardMetadata) => {
        return (
            <div className="payment-card">
                { (cardMetadata.cardBrand === 'mastercard') ? <img src={mastercard} alt="Mastercard" /> : <img src={visa} alt="Visa" />}
                <div className="details">
                    <div className="last4"><span className="dots">...</span><span>{cardMetadata.last4}</span></div>
                </div>
            </div>
        );
    }

    const renderServiceFee = () => {
        return (
            <>
                <div className="tuple">
                        <div className="tuple-title">
                            {t("paymentReceipt.service_fee")}
                    </div>
                        <div className="tuple-value">
                            {FormatService.formatCurrency(receipt.serviceFee * 100, 2, 'SEK')}
                        </div>
                </div>

                <div className="tuple">
                        <div className="tuple-title">
                        {t("paymentReceipt.total_amount")}
                    </div>
                        <div className="tuple-value">
                            {FormatService.formatCurrency((receipt.totalAmount + receipt.serviceFee) * 100, 2, 'SEK')}
                        </div>
                </div>

            </>
        );
    }

    const renderPaidAmount = (receipt) => {
        if (!(receipt.hasRefund && receipt.receiptMetadata && receipt.receiptMetadata.refunds)) {
            return (
                <>
                    {FormatService.formatCurrency(receipt.totalAmount * 100, 2, 'SEK')}

                </>
            );
        }
        else {
            let refund = HelperService.getRefundAmount(receipt);
            let paidSum = receipt.totalAmount - refund;
            return (
                <div style={{position:'relative'}}>
                    <span>{FormatService.formatCurrency(paidSum * 100, 2, 'SEK')}</span>
                    <span className="amount-before-refund">{FormatService.formatCurrency(receipt.totalAmount * 100, 2, 'SEK')}</span>

                </div>
            );
        }
    }

    

    const renderRefunds = (receipt) => {
        if (!receipt) return '-';
        let e = [];
    
        if (receipt.receiptMetadata && receipt.receiptMetadata.refunds) {
            for (let i = 0; i < receipt.receiptMetadata.refunds.length; i++) {
                const refund = receipt.receiptMetadata.refunds[i];
                e.push(
                    <div key={'refund-data-id-'+i} className="prop-group">
                            <div className="refund-details">
                                <span>{moment(refund.createdOn).format('YY-MM-DD')}</span> 
                                <span className="time-val">{moment(refund.createdOn).format('HH:mm')}</span> 
                                <span className="amount-val">{FormatService.formatCurrency(refund.amount * 100, 2, 'SEK')}</span>
                            </div>
                        </div>
                );
            }
        }
        return e;    
    }

    const renderCreditInfo = () => {
        if (!receipt) return null;
        let desc = getDescriptionText();
        return (
            <>
            <div  className="tuple" style={{textAlign:'center'}}> {t("paymentReceipt.epspot_deducted_credits")}
                &nbsp;{FormatService.formatCurrency(receipt.totalAmount * 100, 2, 'SEK')}.
            </div>

            {
                !desc ? null :
                <div  className="tuple">
                <div  className="tuple-title">{desc}</div>
            </div>
            }

            </>
        );
    }

    const renderManualTopup = () => {
        if (!receipt) return null;
        let desc = getDescriptionText();
        return (
            <>
            <div  className="tuple" style={{textAlign:'center'}}> {t("paymentReceipt.epspot_topup_credits")}
                &nbsp;{FormatService.formatCurrency(receipt.totalAmount * 100, 2, 'SEK')}.
            </div>

            {
                !desc ? null :
                <div  className="tuple">
                <div  className="tuple-title">{desc}</div>
            </div>
            }

            </>
        );
    }

    

    const renderStandardReceipt = (isTopupReceipt) => {
        return (
            <>
                <div className="tuple">
                    <div className="tuple-title">
                        {isTopupReceipt ? t("paymentReceipt.refill") : t("paymentReceipt.sep")}
                    </div>
                    <div className="tuple-value">
                        {renderPaidAmount(receipt)}
                    </div>
                </div>

                {hasServiceFee ? renderServiceFee() : null}

                <div className="tuple">
                    <div className="tuple-title">
                        {t("paymentReceipt.vat")} ({receipt.vat}%)
                    </div>
                    <div className="tuple-value">
                        {getFormatedVatValue(receipt)}
                    </div>
                </div>

                <div className="tuple">
                    <div className="tuple-title">
                        {t("paymentReceipt.payment_method")}
                    </div>
                    <div className="tuple-value">
                        {(receipt.paymentMethod === 'CARD') ? renderPaymentCard(receipt.receiptMetadata) : <div className="swish-logo"></div>}
                    </div>
                </div>
            </>
        );
    }

    const hasServiceFee = receipt.serviceFee && receipt.serviceFee > 0;
    const isTopupReceipt = receipt.receiptType === 'TOPUP';
    const isCreditReceipt = receipt.receiptType === 'DEDUCT';
    const isManualPayment = receipt.paymentMethod === 'EPSPOT_MANUAL';

    //<div className="payment-receipt" style={ hasServiceFee ? {height:'660px'} : {height:'600px'} }>
    const renderReceipt = () => {
        return (
            <div className="payment-receipt">
                <ReceiptBackground fill='white' />
                <div className="content">
                    <div className="logo"><div /></div>
                    <h2>{ isCreditReceipt ?  t("paymentReceipt.credit_receipt_epSpot") : t("paymentReceipt.receipt_epSpot")}</h2>
                    <div className="creation-date">{moment(receipt.createdOn).format('llll')}</div>

                    {
                        isCreditReceipt ? 
                        renderCreditInfo() : 
                        <> 
                        {
                            isManualPayment ? 
                            renderManualTopup() : 
                            renderStandardReceipt(isTopupReceipt)
                        }
                        </>
                        
                    }

                    <div className="tuple">
                        <div className="tuple-title">
                        {t("paymentReceipt.receipt_nr")}
                    </div>
                        <div className="tuple-value">
                            {receipt.receiptNumber}
                        </div>
                    </div>

                    { receipt.hasRefund ? 
                        <div className="tuple refund-list">
                        <div className="tuple-title">
                        {t("paymentReceipt.refunds")}
                        </div>
                            <div className="tuple-value">
                                {renderRefunds(receipt)}
                            </div>
                        </div>
                    : null}

                    
                    { modal ? 
                        <> 
                            <Button className="close-button" onClick={()=>{closeCB()}}>{t("paymentReceipt.close")}</Button>

                            <div className="info-text">
                            {t("paymentReceipt.more_history")}
                            </div>
                        </>
                        :<> 
                            <ProgressButton className="close-button" text="PDF" disabled={isManualPayment} loading={generatingPdf} clickCB={()=>{openPdf(receipt)}}/>
                            {
                                !isManualPayment ? null :
                                <div className="tuple" >
                                    <div className="tuple-title" style={{textAlign:'center', width:'100%'}}>
                                    {t("paymentReceipt.no_pdf_text")}
                                </div>
                                </div>
                            }
                        </>
                    }

                    <div className="bottom-line">
                            <span>www.epspot.com</span><span>org. nr: 556965-5391</span>
                        </div>

                </div>

            </div>
          );

    }
    
    //<div className="creation-date">Org. nr: 556965-5391</div>
    if (!receipt) return null;
    

    if (modal) return (
        <Modal dialogClassName="receipt-modal" show aria-labelledby="contained-modal-title-vcenter" onHide={()=>{}}>
            {renderReceipt()}
        </Modal>
    )
    else return renderReceipt();
}

// 