import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faChevronLeft, faCrosshairs, faFilter, faGlobeEurope} from '@fortawesome/free-solid-svg-icons'
import TitleBar from 'js/components/titleBar.js';
import MapInfoWindow from 'js/components/mapInfoWindow';
import DataStoreContext from 'dataStoreContext';
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";
import queryString from 'query-string';
import Toggle from 'react-toggle'
import 'styles/reactToggle.scss'

import 'styles/common.scss';
import 'styles/mapView.scss';
import cloneDeep from 'lodash/cloneDeep';
import { useInterval } from 'services/hooks.js'
import Api from "services/api.js";
import HelperService from '../../services/helpers';

// Translation
import { useTranslation } from 'react-i18next';

const Marker = ({ children }) => children;

const GOOGLE_API_DEV_KEY = 'AIzaSyDOoajYzRR2ONYf4b67EiedZm9HH4ryCA0';
// eslint-disable-next-line no-unused-vars
const GOOGLE_API_PROD_KEY = 'AIzaSyDrOGTUbsI1ekdsZFtSQ671C1cJxMMDLHo';
let GOOG_MAP_KEY = GOOGLE_API_DEV_KEY;
GOOG_MAP_KEY = GOOGLE_API_PROD_KEY;


export default function MapView(props) {
    let history = useHistory();
    const { nearbyLocations, setHelpTextModal } = useContext(DataStoreContext);

    // Translation
    const { t } = useTranslation('views');

    useEffect(() => {
        props.toggleMenu(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);



    const handleBackClick = () => {
        history.push("/");
    }

    return (
        <div className="sliding-view map-view">
            <div className="bg"></div>
            <div className="cover"></div>
            <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={t("mapView.map")} />

            <div className="content">
                {(nearbyLocations) ? <MapView2 nearbyLocations={nearbyLocations} setHelpTextModal={setHelpTextModal} /> : null}
                {(!nearbyLocations) ? <div className="map-loading"><div className="loading-info"><FontAwesomeIcon icon={faGlobeEurope} /><span>{t("mapView.loading")}</span></div></div>: null}
            </div>
        </div>
    );
}

/** Since useSupercluster must be placed on the function itself without any conditionals we need a MapView2 function 
 * which is invoked when nearbyLocations is fully initialized.
*/
function MapView2({ nearbyLocations, setHelpTextModal }) {

    const mapRef = useRef();
    const mapsRef = useRef();
    const parkingMapPolygons = useRef([]);
    const visibleLocations = useRef([]);
    let initialLocation = { lat: 59.328930, lng: 18.064910 };
    let location = useLocation();

    const { userPosition, setUserPosition, isFlutterApp, sendFlutterNotification, appInForeground } = useContext(DataStoreContext);

    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(10);
    // selectedLocation is a qualified id on form: ["V2Provider", "fc6bc21d-3d30-4556-ad83-6f93fc312d73", "V2Plant", "39cb1e3a-f80a-4d1d-9d7b-c3b067fd1ec5", "V2Location", "f4e6c0cb-8ffe-4354-8dc9-7f8bd8aeb7e4"]
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [isPositioning, setIsPositioning] = useState(false);
    //const [userPosition, setUserPosition] = useState([]);
    const [outletStatusList, setOutletStatusList] = useState([]);
    const [selectedOutletStatusList, setSelectedOutletStatusList] = useState(false); // Contains status for the selected outlet to be viewed in mapInfoWindow
    // outlets in selected (clicked) location
    const [outletList, setOutletList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [includePublicParkings, setIncludePublicParkings] = useState(true);
    const [includeRestrictedLocations, setIncludeRestrictedLocations] = useState(true);
    // eslint-disable-next-line
    const [useGreyStyle, setUseGreyStyle] = useState(false);
    const [initialPosFromUrl, setInitialPosFromUrl] = useState(null);

    const points = cloneDeep(nearbyLocations);

    //translation
    const { t } = useTranslation('views');



    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        //options: { radius: 100, maxZoom: 15 }
        options: { radius: 100, maxZoom: 0 }
    });


    useEffect(() => {
        //59.333811007613896, 18.098783454949334 livgardet
        const queryParams = queryString.parse(location.search);
        if (queryParams.lat && queryParams.lng) {
            console.log('Setting map pos from query params');
            setInitialPosFromUrl({ lat: parseFloat(queryParams.lat), lng: parseFloat(queryParams.lng) });
        }
        

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        
        clearParkingMaps();
        //fetchVisibleOutlets();

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [zoom, bounds]);


    /* NOT WORKING YET 
    useEffect(() => {
        console.log('AAAAA');
        if (mapRef && mapRef.current) {
            console.log('BBB');
            mapRef.current.panBy(1,1);
            mapRef.current.setZoom(18);
            positionMe();
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [initialPosFromUrl]); */
    

    useEffect(() => {
        /*console.log('bounds changed!!!');
        console.log(mapsRef.current);
        */
        if (!bounds|| !mapsRef.current) return;

        visibleLocations.current = [];
        if (zoom < 13) {
            if (outletStatusList.length > 0) setOutletStatusList([]);
            return;
        }
        
        let sw = new mapsRef.current.LatLng(bounds[0], bounds[1]);
        let ne = new mapsRef.current.LatLng(bounds[2], bounds[3]);
        let gBounds = new mapsRef.current.LatLngBounds(sw, ne);
       
        //console.log('iterating POINTS');
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            if ( gBounds.contains({lat: point.geometry.coordinates[0], lng: point.geometry.coordinates[1]}) ) {
                console.log(point.properties.location);
                // qualifiedId on form: ["V2Provider", "fc6bc21d-3d30-4556-ad83-6f93fc312d73", "V2Plant", "39cb1e3a-f80a-4d1d-9d7b-c3b067fd1ec5", "V2Location", "f4e6c0cb-8ffe-4354-8dc9-7f8bd8aeb7e4"]
                let qualifiedId = point.properties.location.parents.concat(['V2Location', point.properties.location.id]);
                visibleLocations.current.push(qualifiedId);
            }
        }

        updateLocationStatus();

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [bounds]);

    useEffect(() => {
        
        clearParkingMaps();
        updateSelectedLocationStatus();
        //fetchVisibleOutlets();

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [selectedLocation]);

    useEffect(() => {
        if (mapRef && mapRef.current) {
            mapRef.current.panTo({lat: userPosition[0], lng: userPosition[1]});
            setIsPositioning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userPosition]);

  /*  useEffect(() => {
        if (appInForeground) setStart(true);
        else setStart(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [appInForeground]);
    */
    
    useInterval(
        () => {
            updateLocationStatus();
        },
        (appInForeground && visibleLocations.current && visibleLocations.current.length > 0) ? 60000 : null
        //10000 
      );


    const positionMe = () => {
        if (navigator.geolocation) {
            try {
                setIsPositioning(true);
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setUserPosition([position.coords.latitude, position.coords.longitude]);
                    },
                    (e) => {
                        //handleLocationError(true, infoWindow, map.getCenter());
                        // Android causes an exception for geolocation, so then we use native code to do positioning 

                        if (isFlutterApp) {
                            let msg = '{"command": "GET_USER_POSITION"}';
                            sendFlutterNotification(msg);
                            // give android device 10s to find position
                            setTimeout(()=>{setIsPositioning(false)}, 10000);
                        }
                        else {
                            console.log('Is Flutter App. ' + isFlutterApp);
                            console.log('positioning error in navigator.geolocation.getCurrentPosition');
                            console.log(e.code);
                            console.log(e.message);
                        } 
                        
                    },
                    { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
                );
            } catch (error) {
                console.log(error);
                setIsPositioning(false);
            }
        }
        else {
            //console.log('drumdrum');
        }
    }

    const handleApiLoaded = (map, maps) => {
        mapRef.current = map;
        mapsRef.current = maps;
        
        
        
        // need to force a bounds change to get location statuses right away
        mapRef.current.panBy(1,1);
        positionMe();

        if (initialPosFromUrl) {
            mapRef.current.setZoom(18);
            mapRef.current.panTo({ lat: initialPosFromUrl.lat, lng: initialPosFromUrl.lng }); 
            setInitialPosFromUrl(null);
        }
        
        //setUserPosition([59.333811007613896, 18.098783454949334]);
        /* initialLocation = { lat: 59.333811007613896, lng: 18.098783454949334 }
        setZoom(8); */
        /* mapRef.current.setZoom(18);
        mapRef.current.panTo({ lat: 59.333811007613896, lng: 18.098783454949334 });
        */
    }


    const fetchOutletsForLocation = async (qualifiedId) => {
        //if the outlets already have been fetched we just return, but first we fetch the provider
        // we should cache the providers just like we do for outlets to not have to fetch the same one mutiple times... TODO
        let providerId = qualifiedId[qualifiedId.indexOf('V2Provider') + 1];
        let provider = await Api.getProvider(providerId);
        setSelectedProvider(provider);
        let foundIndex = outletList.findIndex(item => HelperService.idPathsAreEqual(item.location, qualifiedId));
        if (foundIndex >= 0) return;

        try {
            //updateLocationStatus(locationId);
            setIsLoading(true);
            
            let plantId = qualifiedId[qualifiedId.indexOf('V2Plant') + 1];
            let locationId = qualifiedId[qualifiedId.indexOf('V2Location') + 1];
            let result = await Api.getLocationOutlets(providerId, plantId, locationId);
            //await new Promise(r => setTimeout(r, 6000));
            
            if (result.items) {
                // result has arrived, we add the actual outlets
                let outletListCopy = cloneDeep(outletList);
                outletListCopy.push({location: qualifiedId, outlets: result.items });
                setOutletList(outletListCopy);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }


    }

    const updateLocationStatus = async () => {
        //console.log('UPDATE LOCATION STATUS');
        if (!visibleLocations || visibleLocations.current.length === 0) return;
        let localStatus = [];
        let statusChanged = false;
        try {
                let result = await Api.getStatusForLocations(visibleLocations.current);
                if (result.items) {
                    for (let j = 0; j < result.items.length; j++) {
                        // result.items[j]: {"locationIdPath":["V2Provider", "1d9ca81e-37c1-4309-8eb8-e7a351c13862", "V2Plant", "203627f0-0a30-4c2b-af5c-e9d7e9d6fe77", "V2Location", "b6846a16-11fc-49e2-a902-1b6ffbb64d3e"],"status": [{ "status": "STATUS_BUSY", "outletId": "000-000-015" }, ...]}
                        let location = outletStatusList.find(loc =>  HelperService.idPathsAreEqual(loc.locationIdPath, result.items[j].locationIdPath));
                        if (location) { //location was already in view, we check if it has changed
                            //if (!location.status.some(item => (item.status === result.items[j].status.status && item.outletId === result.items[j].status.outletId))) {
                            if (!location.status.some(item => (item.status === result.items[j].status.status && HelperService.idPathsAreEqual(item.locationIdPath, result.items[j].status.locationIdPath)))) {
                                statusChanged = true;
                            }
                        }
                        else { // new location in view, we add this status
                            statusChanged = true;
                        }
                        localStatus.push(result.items[j]);
                    }
                }
        } catch (e) { console.log(e); }

        if (statusChanged) {
            //console.log('setting outlet status');
            setOutletStatusList(localStatus);
        }
    }

    const updateSelectedLocationStatus = async () => {
        if(!selectedLocation) return null;
        let localStatus = [];
        try {
            let result = await Api.getStatusForLocations([selectedLocation]);
            localStatus.push(result.items[0]);
        } catch (e) { console.log(e); }
        setSelectedOutletStatusList(localStatus);
    }


    const gray_style = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ];
    const createMapOptions = (maps) => {
        let options = {
            fullscreenControl: false,
            zoomControlOptions: { position: maps.ControlPosition.RIGHT_TOP },
            clickableIcons: false,
        };
        if (useGreyStyle) options.styles = gray_style;
        else options.styles = null;
        return options; 
    }

    


    const clearParkingMaps = () => {
        for (let i = 0; i < parkingMapPolygons.current.length; i++) {
            parkingMapPolygons.current[i].polygon.setMap(null);
        }
        parkingMapPolygons.current = [];
    }

    const expandCluster = (clusterId, lat, lng) => {
        const expansionZoom = Math.min(
            supercluster.getClusterExpansionZoom(clusterId),
            20
        );
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({ lat: lat, lng: lng });
    }

    const showParkingMap = (location) => {
        setSelectedLocation(null);
        mapRef.current.setZoom(18);
        mapRef.current.panTo({ lat: location.latitude, lng: location.longitude });
    }

    const getOutletStatus = (location, outletId) => {
        let qualifiedId = location.parents.concat(['V2Location', location.id]);
        let locStat = outletStatusList.find(loc =>  HelperService.idPathsAreEqual(loc.locationIdPath, qualifiedId));
        if (locStat) {
            let status = locStat.status.find((item => item.outletId === outletId));
            if (status) return status.status;
        }
        
        return 'UNASSIGNED';
    }

    const getLocationStatus = (qualifiedId) => {
        //let locStat = outletStatusList.find((item => item.locationId === locationId));

        let locStat = outletStatusList.find((item => HelperService.idPathsAreEqual(item.locationIdPath, qualifiedId)));
        let statusFree = 0;
        if (locStat) {

            for (let i = 0; i < locStat.status.length; i++) {
                if (locStat.status[i].status === 'STATUS_AVAILABLE') statusFree++;
            }
            if (statusFree === 0) return 'STATUS_FULL';
            if ( statusFree < 0.25*locStat.status.length) return 'STATUS_CROWDED';
            else return 'STATUS_FREE';
        }
        return 'STATUS_UNKNOWN';
    }

    const getOutletsForLocation = (qualifiedId) => {
        //let item = locationOutlets.current.find((item => item.location === locationId));
        //let item = outletList.find((item => item.location === locationId));
        let item = outletList.find((item => HelperService.idPathsAreEqual(item.location, qualifiedId)));
        if (item) return item.outlets;
        return null;
    }

    const isPublicLocation = (location) => {
        if (location && location.accessPolicies && location.accessPolicies.length > 0 && (location.accessPolicies[0].policy !== 'PUBLIC' && location.accessPolicies[0].policy !== 'RESTRICTED_PUBLIC')) {
            return false;
        }
        return true;
    }

    const getLocationMarkerClassNames = (hasParkingMap, status, accessPolicies) => {
        //{hasParkingMap ? "button-bg with-parking-map z"+zoom + " status_"+status: "button-bg z"+zoom + " status_"+status}
        const hasRestriction = accessPolicies && accessPolicies.length > 0 && accessPolicies[0].policy === 'RESTRICTED_PUBLIC';
        let style = "button-bg z"+zoom + " status_"+status;
        if (hasParkingMap) style += ' with-parking-map';
        if (hasRestriction) style += ' with-restriction';
        return style;
    }

    const renderParkingMaps = (location) => {
        console.log('render parking maps for: ' + location.name);
        //console.log(location.parkingMaps);
        for (let ii = 0; ii < location.parkingMaps.length; ii++) {
            const parkingMap = location.parkingMaps[ii];
            let corners = parkingMap.corners;
            const corner4 = [(corners[2][0] - corners[1][0]) + corners[0][0],
            (corners[2][1] - corners[1][1]) + corners[0][1]];
            corners.push(corner4);

            let deltaLat = corners[1][0] - corners[0][0];
            let deltaLng = corners[1][1] - corners[0][1];

            if (deltaLat === 0 || deltaLng === 0) {
                console.log('ignoring corrupt parking map, map at position: '+ii);
                continue;
            }

            let numParkings = parkingMap.assignedOutlets.length;
            let stepLng = deltaLng / numParkings;

            for (let i = 1; i < numParkings + 1; ++i) {
                let howFar_o = ((i - 1) * stepLng) / deltaLng;
                let howFar = (i * stepLng) / deltaLng;
                let status = getOutletStatus(location, parkingMap.assignedOutlets[i - 1]);
                let fill;
                //console.log(status);
                if (status === 'STATUS_AVAILABLE') fill = '#0f0';
                else if (status === 'UNASSIGNED') fill = '#aaa';
                else fill = '#f00'

                var parkingSpot = new mapsRef.current.Polygon({
                    strokeColor: '#000',
                    strokeWeight: 2.0,
                    strokeOpacity: 0.8,
                    fillColor: fill,
                    fillOpacity: 0.6,
                    paths: [
                        { lat: corners[0][0] + (deltaLat * howFar_o), lng: corners[0][1] + ((i - 1) * stepLng) },
                        { lat: corners[0][0] + (deltaLat * howFar), lng: corners[0][1] + (i * stepLng) },
                        { lat: corners[3][0] + (deltaLat * howFar), lng: corners[3][1] + (i * stepLng) },
                        { lat: corners[3][0] + (deltaLat * howFar_o), lng: corners[3][1] + ((i - 1) * stepLng) }
                    ]
                });
                parkingSpot.setMap(mapRef.current);
                parkingMapPolygons.current.push({ polygon: parkingSpot, id: parkingMap.assignedOutlets[i - 1] });

            }
        }

        return null;
    }



    if (!nearbyLocations) return null;
    return (
        <>
        <div className={showFilterPanel ? "filter-panel show" : "filter-panel"}>
            <h3>{t("mapView.filterParking")}</h3>
            <div className="settings-prop">
                <Toggle checked={!includePublicParkings} onChange={()=>{setIncludePublicParkings(!includePublicParkings)}} icons={{
                        checked: <span className="track-text"></span>,
                        unchecked: <span className="track-text"></span> }} />
                <div className="label">{t("mapView.includePublicParking")}
                <span className="help-text-button" onClick={()=>setHelpTextModal(['map_view:public_location'])}><FontAwesomeIcon icon={faQuestionCircle} /></span>
                </div>        
            </div>
            {
                includePublicParkings ? 
                <div className="settings-prop">
                    <Toggle checked={!includeRestrictedLocations} onChange={()=>{setIncludeRestrictedLocations(!includeRestrictedLocations)}} icons={{
                            checked: <span className="track-text"></span>,
                            unchecked: <span className="track-text"></span> }} />
                    <div className="label">{t("mapView.includeRestrictedParking")}
                        <span className="help-text-button" onClick={()=>setHelpTextModal(['map_view:restricted_location'])}><FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </div>        
                </div>
                : <div className="info-text">{t("mapView.only_charge_spots_where_you_are_member")}</div>
            }
            
            {/*<div className="settings-prop">
                <Toggle checked={useGreyStyle} onChange={()=>{setUseGreyStyle(!useGreyStyle)}} icons={{
                        checked: <span className="track-text"></span>,
                        unchecked: <span className="track-text"></span> }} />
                <div className="label">{t("mapView.discreetMapStyle")}</div>        
            </div>                
            */}
        </div>
            <div className={showFilterPanel ? "map-section shrunken" : "map-section"}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOG_MAP_KEY,
                    libraries: ['geometry']
                }}
                defaultCenter={initialLocation}
                center={initialLocation}
                options={createMapOptions}
                defaultZoom={13}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    console.log(zoom);
                    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
                }} >
                {                    
                    clusters.map(cluster => {
                        const [longitude, latitude] = cluster.geometry.coordinates;
                        const { cluster: isCluster, point_count: pointCount } = cluster.properties;
                        const hasParkingMap = (cluster.properties.location && cluster.properties.location.parkingMaps);
                        const accessPolicies = (cluster.properties.location) ?  cluster.properties.location.accessPolicies : null; 

                        //processClusterStatus();


                        if (isCluster) {
                            //clearParkingMaps();
                            return renderCluster(cluster, latitude, longitude, pointCount);
                        }
                        else if (zoom > 17 && hasParkingMap) {
                            if (parkingMapPolygons.current.length === 0) return renderParkingMaps(cluster.properties.location);
                            else return null;
                        }
                        else {
                            //clearParkingMaps();
                            return renderLocation(cluster, latitude, longitude, hasParkingMap, accessPolicies);
                        }
                    })
                }
                { userPosition && userPosition.length > 0 ? renderUserLocation() : null }


            </GoogleMapReact>

            <div className={isPositioning ? "square-button center pulsate" : "square-button center"} onClick={positionMe}><FontAwesomeIcon icon={faCrosshairs} /></div>
            <div className={showFilterPanel ? "square-button filter selected" : "square-button filter "+zoom} onClick={()=>{setShowFilterPanel(!showFilterPanel)}}><FontAwesomeIcon icon={faFilter} /></div>
            
            
            {/*<div style={{backgroundColor:'white', position:'absolute', top:'65px'}}>ZOOM: {zoom}</div>*/}


            </div>
        </>
    );

    function renderCluster(cluster, latitude, longitude, pointCount) {
        return <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
            <div className="cluster-marker"
                onClick={() => expandCluster(cluster.id, latitude, longitude)}
                style={{
                    width: `${40 + (pointCount / points.length) * 20}px`,
                    height: `${40 + (pointCount / points.length) * 20}px`
                }}>
                {pointCount}
            </div>
        </Marker>;
    }



    function renderLocation(cluster, latitude, longitude, hasParkingMap, accessPolicies) {

        /* //DEBUG CODE
        if (cluster.properties.location.id !== 'f4e6c0cb-8ffe-4354-8dc9-7f8bd8aeb7e4') return null;
        else console.log('render Notander');
        console.log(cluster.properties.location);
        */

        if (!includePublicParkings && isPublicLocation(cluster.properties.location)) return null;
        if (!includeRestrictedLocations && accessPolicies && accessPolicies.length>0 && accessPolicies[0].policy === 'RESTRICTED_PUBLIC') return null;
        let qualifiedId = cluster.properties.location.parents.concat(['V2Location', cluster.properties.location.id]);
        let status = getLocationStatus(qualifiedId);

        return (
        <Marker key={`loc-${cluster.properties.location.id}`}
            lat={latitude} lng={longitude} >

            <button className={"location-marker"} onClick={() => {
                if (HelperService.idPathsAreEqual(selectedLocation, qualifiedId)) {
                    setSelectedLocation(null);
                    setSelectedProvider(null);
                }
                else {
                    setSelectedLocation(qualifiedId);
                    fetchOutletsForLocation(qualifiedId);
                }
            }}>
                <div className={getLocationMarkerClassNames(hasParkingMap, status, accessPolicies)}>
                    <div className="epspot-map-icon" />
                </div>
            </button>

            {HelperService.idPathsAreEqual(selectedLocation, qualifiedId) ?
                <MapInfoWindow provider={selectedProvider} location={cluster.properties.location} loadingData={isLoading} outlets={getOutletsForLocation(qualifiedId)} 
                outletStatuses={selectedOutletStatusList} showParkingMapCB={(hasParkingMap)?showParkingMap : null} 
                closeCB={()=>{setSelectedLocation(null); setSelectedProvider(null);}}/>
                : null}

        </Marker>

        );
    }
 

        function renderUserLocation() {
            return (
                <Marker key={`loc-user-position`}
                    lat={userPosition[0]} lng={userPosition[1]} >
                        <div className={`user-marker z${zoom}`}>
                            <div/>
                        </div>
                </Marker>
            )}
}
