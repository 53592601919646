import React, {useContext, useState, useEffect, useRef} from 'react';
import DataStoreContext from 'dataStoreContext'
import { useInterval } from 'services/hooks.js'
import HelperService, {FormatService} from "services/helpers.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopCircle, faBolt, faClock, faChevronDown, faHourglassHalf} from '@fortawesome/free-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Api from "services/api.js";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-toggle';
import 'styles/bottomOverlayView.scss';
import 'styles/common.scss';

import {IconPlug, IconCarHeater, IconCarCharge, IconQuestionCircle, IconHourglass} from 'resources/icons.js';

// Translation
import { useTranslation } from 'react-i18next';


export default function BottomOverlayView() {

    const outletNameWidth = useRef(null);
    

      const VIEW_MODE = {
        HIDDEN: 'hidden-overlay',
        SMALL: 'small-overlay',
        FULL: 'full-overlay'
      };  

    const { userMe, setUserMe, activeSessions, setActiveSessions, setInfoModal, setCompletedSessions, appInForeground} = useContext(DataStoreContext);  
    const [viewMode, setViewMode]  = useState(VIEW_MODE.SMALL);
    const [requestCount, setRequestCount] = useState(0);
    const [displayData, setDisplayData] = useState(null);
    const [start, setStart] = useState(false);
    const [numOfActiveSessions, setNumOfActiveSessions] = useState(0);
    const [updateFrequency, setUpdateFrequency] = useState(2); // we check every 2nd second during session inititation, then each 10s for ongoing active sessions
    const [hasShowedEndSessionInfoModal, setHasShowedShowEndSessionInfoModal]  = useState(false); // if shown in this app session 
    const [showEndSessionInfoModal, setShowEndSessionInfoModal]  = useState(false); 
    const [showEndSessionInfoModalToggle, setShowEndSessionInfoModalToggle]  = useState(false); 
    // Translation
    const { t } = useTranslation('views');
 

    useEffect(() => {
        // TODO: see comment below at expandToFullView()
     /*   const el = document.querySelector('.bottom-overlay');

          el.addEventListener('transitionend', function() {
            const containerElement = document.querySelector('.containr');
            if (containerElement) containerElement.style.overflow = null;
          }); 
          */

      }, []);

    useEffect(() => {
        if (appInForeground) setStart(true);
        else setStart(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [appInForeground]);    

    useEffect(() => {
        if (activeSessions && !hasShowedEndSessionInfoModal) {
            let us = window.localStorage.getItem("userSettings");
            let showInfoModal = true;
            if (us) {
                us = JSON.parse(us);
                if (us.hideEndSessionInfoModal) showInfoModal = false;
            }

            if (showInfoModal) setShowEndSessionInfoModal(true);
            setHasShowedShowEndSessionInfoModal(true);
        }
        
        if (!(activeSessions && activeSessions.length > 0)) {
            setViewMode(VIEW_MODE.HIDDEN);
            if (numOfActiveSessions > 0) {
                handleSessionFinish();
                setNumOfActiveSessions(0);
            }
            setStart(false);
        }
        else {
            if (viewMode === VIEW_MODE.HIDDEN) setViewMode(VIEW_MODE.SMALL);
            
            if (!start) {
                setStart(true);
            }
            
            if (activeSessions.length < numOfActiveSessions) {
                //A previously running session has stopped, we must update session history
                handleSessionFinish();
            }
            setNumOfActiveSessions(activeSessions.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [activeSessions]);


    // we update bottom panel every second since we want the time to tick with second frequencey. Then for each 
    // 10th update we also request the status from the server
    useInterval(
        () => {
             updateSessions()
        },
        start ? 1000 : null
      );

    /* called when a session has completed. For the registered case we update the 
    * completed session in the context. For the unregistered case we do nothing since we moved the active session to 
    * the UNRGISTERED_COMPLETED_SESSIONS already in hte updateSessions method (to not have to call server again here)  
    */ 
    const handleSessionFinish = async () => {
        if (userMe) {
            let from = moment().format();
            let completedSessions = await Api.getCompletedSessions(userMe.userName, from);
            setCompletedSessions(HelperService.sortCompletedSessions(completedSessions.items));
        }
    };

    const updateUnregisteredSessions = async () => {
        let sessions = HelperService.getUnregisteredActiveSessions();
        let updatedUnregisteredSessions = [];
                    
        for (let i = 0; i < sessions.length; i++) { 
            let sessionItem = sessions[i];
            let activeSession = null;
            if (sessionItem.providerId) {
                try {
                    activeSession = await Api.getSessionV2(sessionItem.sessionId, sessionItem.providerId);
                } catch (error) {
                    // silent catch since api throws error for missing session :-()
                }
            }
            else {
                try {
                    activeSession = await Api.getSession(sessionItem.sessionId);
                } catch (error) {
                    // silent catch since api throws error for missing session :-()
                }
            }
            
            if (activeSession && 
                (activeSession.status === 'WAITING_FOR_ACTIVATION' || activeSession.status === 'ACTIVE' || activeSession.status === 'IN_QUEUE')) {
                updatedUnregisteredSessions.push(activeSession);
            }
            else { //this session is finished, lets move in local store 
                HelperService.removeSessionFromUnregisteredActiveSessions(sessionItem.sessionId);
                if (activeSession) HelperService.addSessionToUnregisteredCompletedSessions(activeSession);
            }
        }
        return updatedUnregisteredSessions;
    }

    const updateSessions = async () => {
        if (requestCount % updateFrequency === 0) {
            let updatedActiveSessions = [];
            if (userMe) {
                let activeSessionsV2 = null;
                let activeSessionsLegacy = null;
                try {
                    activeSessionsV2 = await Api.getActiveSessionsV2(userMe.userName); 
                    activeSessionsLegacy = await Api.getActiveSessions(userMe.userName);
                } catch (error) {
                    // silent catch since api throws exception even for empty lists :-(
                }
                if (activeSessionsV2 && activeSessionsV2.items) updatedActiveSessions = updatedActiveSessions.concat(activeSessionsV2.items);
                if (activeSessionsLegacy && activeSessionsLegacy.items) updatedActiveSessions = updatedActiveSessions.concat(activeSessionsLegacy.items);
            }
            else { //unregistered user, getting locally stored sessions
                updatedActiveSessions = await updateUnregisteredSessions();
            }
            if (updatedActiveSessions && updatedActiveSessions.length === 0) updatedActiveSessions = null;
            // if both are null we change one to [] to ensure the [activeSessions] effect is triggered. If not, an app
            // without active sessions starts to periodically update if it is moved in from background.
            if (activeSessions === null && updatedActiveSessions === null) updatedActiveSessions = [];
            setActiveSessions(updatedActiveSessions);
        }
        setRequestCount(requestCount+1);
        let display = [];
        let hasSessionStartingUp = false;
        
        for (let i = 0; i < (activeSessions ? activeSessions.length : 0); i++) {
            //console.log(activeSessions[i]);
            //console.log(userMe);
            let session = activeSessions[i];
            let displaySession = {};
            displaySession.id = session.id;
            displaySession.status = session.status;
            displaySession.outletId = session.outletId;
            displaySession.name = session.outletName;
            displaySession.totalEnergy = session.totalEnergy;
            displaySession.currentCost = session.currentCost;
            displaySession.sessionType = session.sessionType;
            
            // we set manualStopAllowed to true by default since we don't seem to get it always... 
            displaySession.manualStopAllowed = true;
            if (session.hasOwnProperty('manualStopAllowed') && !session.manualStopAllowed) displaySession.manualStopAllowed = false;
            
            // NEW MODEL MODIFICATION. NOTE: This is just a temporary hack for the new model. 
            // we must rewrite this and use the status sockets for the session status instead.
            if (session.infrastructureInfo) { // this is a new model session
                displaySession.name = session.infrastructureInfo.outletName;
                displaySession.provider = {name: session.infrastructureInfo.providerName};
                displaySession.moduleRequest = session.moduleRequest;
                displaySession.currentCost = session.debitedCostSum;
                displaySession.phaseMapping = session.phaseMapping;
            }
            else {
                displaySession.provider = session.provider;
                displaySession.load = session.load;
                displaySession.power = session.power;
            }

            if (session.startTime) {
                let now = moment();
                let start = moment(session.startTime);
                displaySession.duration = now.diff(start, 'seconds');
            }
            else if (session.status !== 'IN_QUEUE') {
                displaySession.duration =  t("bottomOverlayView.sessionDurationUnknown");
                hasSessionStartingUp = true;
            }

            if (session.createdOn) {
                let now = moment();
                let start = moment(session.createdOn);
                displaySession.queueTime = now.diff(start, 'seconds');
            }

            if (session.autoOffType) {
                displaySession.autoOffType = session.autoOffType;
                displaySession.autoOffValue = session.autoOffValue;
            } else {
                displaySession.autoOffType = "null";
                displaySession.autoOffValue = "null";
            }

            
            display.push(displaySession);
        }
        // if at least one of the active sessions has an session that is starting up we 
        // check every 2nd second, otherwise each 10th
        if (hasSessionStartingUp && updateFrequency === 10) {
            setUpdateFrequency(2);
        }
        else if (updateFrequency === 2) {
            setUpdateFrequency(10);
        }
        setDisplayData(display);
    };

    const showEndSessionModal = (e, sessionId) => {
        e.stopPropagation();
        setInfoModal([[
             t("bottomOverlayView.stopConfirmation")], 
            () => () => {endSession(sessionId)},
            () => () => {}
        ]);
    }

    const endSession = async (sessionId) => {
        //e.stopPropagation();
        try {
            let localActiveSessions = [];
            let sessionToStop = activeSessions.find(s => s.id === sessionId);
            let useNewModel = sessionToStop.hasOwnProperty('infrastructureInfo');

            if (userMe) {
                if (useNewModel) await Api.stopSessionV2(sessionToStop.providerRef, sessionId);
                else await Api.stopSession(sessionId, userMe.userName);
                
                let activeSessionsV2 = await Api.getActiveSessionsV2(userMe.userName); 
                let activeSessionsLegacy = await Api.getActiveSessions(userMe.userName);
                if (activeSessionsV2 && activeSessionsV2.items) localActiveSessions = localActiveSessions.concat(activeSessionsV2.items);
                if (activeSessionsLegacy && activeSessionsLegacy.items) localActiveSessions = localActiveSessions.concat(activeSessionsLegacy.items);
            }
            else {
                if (useNewModel) await Api.stopSessionV2(sessionToStop.providerRef, sessionId);
                else await Api.stopSession(sessionId);
                localActiveSessions = await updateUnregisteredSessions();
                
            }
            if (localActiveSessions && localActiveSessions.length === 0) localActiveSessions = null;
            setActiveSessions(localActiveSessions);

            // since the server-side update of favorite outlets isn't instant we just add a local item here to get 
            // direct feedback to the user. 
            if (useNewModel && userMe && userMe.usage) {
                let temporaryLatestOutlet = {count: -1, userId: sessionToStop.userRef, outletId: sessionToStop.outletId, 
                                            lastUsed: new Date(), name: sessionToStop.infrastructureInfo.outletName, 
                                            locationName: sessionToStop.infrastructureInfo.locationName}; 
                let userMeCopy = cloneDeep(userMe);
                if (userMeCopy.usage.latestUsedOutlets) {
                    userMeCopy.usage.latestUsedOutlets.unshift(temporaryLatestOutlet);
                    setUserMe(userMeCopy);
                }
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    const toKwh = (number) => {
        let n = number / 1000000;
        if(n === 0) return "0"
        if( n > 0 && n < 0.01) return "< 0.01"
        return n.toFixed(2);
    }

    const totalSum = (obj) => {
        return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
    }

    const renderSessionType = (session) => {
        if (!session) return null;
        if (session.status === 'IN_QUEUE') return <IconHourglass/>;
        if (session.sessionType === 'CHARGE') return <IconCarCharge/>;
        if (session.sessionType === 'HEAT') return <IconCarHeater/>;
        if (session.sessionType === 'POWER') return <IconPlug/>;
        return <IconQuestionCircle/>
    }

    const renderTimeIcon = (session) => {
        if (session.status === 'IN_QUEUE' || session.status === 'WAITING_FOR_ACTIVATION') {
            return (
                <FontAwesomeIcon icon={faHourglassHalf} /> 
            );
        }
        else {
            return (
                <FontAwesomeIcon icon={faClock} /> 
            );
        }
        
    }
    
    const renderTime = (session) => {
        if (session.status === 'IN_QUEUE') {
            return (
                <>{FormatService.formatSecondsCompact(session.queueTime) }</>
            );
        }
        else if (session.status === 'WAITING_FOR_ACTIVATION') {
            return (
                <>{FormatService.formatSecondsCompact(-session.duration) }</>
            );
        }
        else {
            return (
                <>{FormatService.formatSecondsCompact(session.duration) }</>
            );
        }
        
    }

    /*const renderPower = (session) => {
        if(session.power) return <>{ t('bottomOverlayView.power')}: <span>{(totalSum(session.power) / 1000000).toFixed(1)} kW</span></> 
        return null;
    }*/

    const renderPower2 = (session) => {
        let kWvalue = 0;
        if(session.power) { // Old model
            kWvalue = totalSum(session.power) / 1000000;
        }
        else if (session.moduleRequest) { // useNewModel
            kWvalue = HelperService.getCurrentPower(session.moduleRequest) / 1000000;
        }
        if (kWvalue < 0.1) return (<>{(1000 * kWvalue).toFixed(1)} <span>W</span></>);  
        else return <>{kWvalue.toFixed(1)} <span>kW</span></> 
    }


    const renderPhaseLoad = (session, phase) => {
        let ampereVal = 0;
        if (session.power) {
            let phaseLoad = session.load[phase];
            ampereVal = phaseLoad / 1000; 
            if (ampereVal > 0 && ampereVal < 0.1) return <span>{'< 0.1'}</span>
        }
        else if (session.moduleRequest) { // useNewModel
            ampereVal = HelperService.getPhaseAmpValue(session.moduleRequest, session.phaseMapping, phase);
        }
        return <span>{ampereVal.toFixed(1)}</span>
        
    } 

    const ellipsifyName = (name) => {
        // very roughly we assume each char is 8 px wide with the font+size we currently use, can be made more accurate of course
        const charWidth = 7;
        let charsToFit = 14;
        
        if (!outletNameWidth.current) {
            const nameEl = document.querySelector('.outlet-name');
            if (nameEl && nameEl.offsetWidth && nameEl.offsetWidth > 0) {
                outletNameWidth.current = nameEl.offsetWidth;
            }
        }
        
        if (outletNameWidth.current) {
            charsToFit = outletNameWidth.current/charWidth;
        }
        //
        let ellipsed = null;
        if (name && name.length > charsToFit) {
            ellipsed = name.substring(0,charsToFit - 7) + '...' + name.substring(name.length-3);
        }
        else ellipsed = name;
        return ellipsed;
    }
    
    const ellipsifyProvider = (name) => {
        const charWidth = 7;
        let charsToFit = 14;
        
        if (!outletNameWidth.current) {
            const nameEl = document.querySelector('.outlet-name');
            if (nameEl && nameEl.offsetWidth && nameEl.offsetWidth > 0) {
                outletNameWidth.current = nameEl.offsetWidth;
            }
        }
        
        if (outletNameWidth.current) {
            charsToFit = outletNameWidth.current/charWidth;
        }
        //
        let ellipsed = null;
        if (name && name.length > charsToFit) {
            ellipsed = name.substring(0,charsToFit - 3) + '...';
        }
        else ellipsed = name;
        return ellipsed;
    }

    // TODO: To prevent the underlying view from jumping on desktop we should turn off overflow (i.e. the scrollbar) when 
    // the overlay is starting to expand. But only if the scrollbar is not already showing.  
    const expandToFullView = () => {
        /*
        const containerElement = document.querySelector('.containr');
        if (containerElement) containerElement.style.overflow = 'hidden';
        */
        setViewMode(VIEW_MODE.FULL);
    }

    const renderSmallOverlay = () => {
        // two or more sessions running
        if (displayData && displayData.length > 1)
        //multiple sessions running
        return (
            <div className="content-area" onClick={()=>expandToFullView()}>
                { (displayData && displayData.length > 0) ? 
                        <div className="flex-view">
                        <IconPlug/>
                        <div>
                            <div className="outlet-name">{displayData.length} { t('bottomOverlayView.sessionsActive')}</div>
                        </div>
                        <div className="show-all-button">{t('bottomOverlayView.show_all')}</div>       
                        </div>
                : null }
            </div>
        );
        //one session running
        return (
            <div className="content-area" onClick={()=>expandToFullView()}>
                { (displayData && displayData.length > 0) ? 
                    <>
                        <div className="flex-view">
                        {renderSessionType(displayData[0])}
                        
                        <div className="outlet-name-group">
                                <div className="outlet-name">{ellipsifyName(displayData[0].name)}</div> 
                                <div className="provider-name">{displayData[0].provider ? ellipsifyProvider(displayData[0].provider.name) : null}</div>
                        </div>

                        

                            <div className="charge-info">
                                <div>{renderTimeIcon(displayData[0])} <span>{renderTime(displayData[0])}</span></div>
                                <div className="energy"><FontAwesomeIcon icon={faBolt} /> {toKwh(displayData[0].totalEnergy)} <span>kWh</span></div>
                            </div>

                        { displayData[0].manualStopAllowed ? 
                            <div className="stop-button" onClick={(e)=>showEndSessionModal(e, displayData[0].id)}><FontAwesomeIcon icon={faStopCircle} /></div>
                        : null }  
                        </div>
                        <div className="overlay-view">
                            <div className="power">
                                {renderPower2(displayData[0])}
                            </div>
                        </div>
                    </>    
                : null }
            </div>
        );
    }

    const renderFullOverlay = () => {
        return (
            < >
                <div className="close-button" onClick={() => setViewMode(VIEW_MODE.SMALL)}>
                    <FontAwesomeIcon icon={faChevronDown} />
                    <div className="text">{t("topOverlayView.close")}</div>
                </div>
                <Accordion >
                    { displayData.map(sessionItem => {
                        //console.log(sessionItem);
                        return (
                            <Card className="session-card" key={sessionItem.id}>
                                <Accordion.Toggle className="session-summary" as={Card.Header} eventKey={sessionItem.id}>
                                    <div className="content-area">
                                        <div className="top-row">
                                            {renderSessionType(sessionItem)}
                                            <div className="power">
                                                {renderPower2(sessionItem)}
                                            </div>
                                            {sessionItem.manualStopAllowed ?
                                                <div className="stop-button" onClick={(e) => showEndSessionModal(e, sessionItem.id)}>
                                                    <div className="button-dot"></div>
                                                    <div className="button-text">{ sessionItem.status === 'IN_QUEUE' ? t('bottomOverlayView.leave_queue') : t('bottomOverlayView.cancel_session')}</div>
                                                    </div>
                                                : null}
                                        </div>
                                        <div className="second-row">
                                            <div className="outlet-name">{sessionItem.name}</div> 
                                            <div className="provider-name">{sessionItem.provider ? sessionItem.provider.name : null}</div>
                                            <div className="readings-row">
                                                <div className="readings-item">
                                                    <div className="reading-title">
                                                        {t('bottomOverlayView.cost')}
                                                    </div>
                                                    <div className="reading-value">
                                                    {FormatService.formatCurrency(sessionItem.currentCost ? (sessionItem.currentCost * 100) : 0, 2, 'SEK')}
                                                    </div>
                                                </div>
                                                <div className="readings-item">
                                                    <div className="reading-title">
                                                        {t('bottomOverlayView.energy')}
                                                    </div>
                                                    <div className="reading-value">
                                                        {toKwh(sessionItem.totalEnergy)} kWh
                                                    </div>
                                                </div>
                                                <div className="readings-item">
                                                    <div className="reading-title">
                                                        {t('bottomOverlayView.time')}
                                                    </div>
                                                    <div className="reading-value">
                                                        {renderTime(sessionItem)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={sessionItem.id}>
                                    <Card.Body>
                                        <div className="content-area">
                                        <div className="separator"/>
                                        <div className="details-row">
                                        <div className="readings-item">
                                                <div className="reading-title">
                                                    {t('bottomOverlayView.outlet_id')}:
                                                </div>
                                                <div className="reading-value">
                                                    {sessionItem.outletId}
                                                </div>
                                            </div>
                                        {sessionItem.load || sessionItem.moduleRequest ?
                                            <div className="readings-item">
                                                <div className="reading-title">
                                                    {t('bottomOverlayView.ampere')}:
                                                </div>
                                                <div className="reading-value">
                                                    [{renderPhaseLoad(sessionItem,'L1')}, {renderPhaseLoad(sessionItem,'L2')}, {renderPhaseLoad(sessionItem,'L3')}]
                                                </div>
                                            </div> : null}
                                        
                                        {sessionItem.autoOffType === "TIME" ?
                                            <>
                                                <div className="readings-item">
                                                    <div className="reading-title">
                                                        {t('bottomOverlayView.stop_limit')}
                                                    </div>
                                                    <div className="reading-value">
                                                        {/*FormatService.formatSecondsCompact(sessionItem.autoOffValue)*/}
                                                        <span>{moment.duration(sessionItem.autoOffValue, 'seconds').format('HH[h:]mm[m]')}</span>
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                        {sessionItem.autoOffType === "KWH" ?
                                            <>
                                                <div className="readings-item">
                                                    <div className="reading-title">
                                                        {t('bottomOverlayView.stop_limit')}
                                                    </div>
                                                    <div className="reading-value">
                                                        {toKwh(sessionItem.autoOffValue)} kWh
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                        </div>    
                                        </div>    
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>);
                    }
                    )}
                </Accordion>
            </>
        );
    }

    /*
    <span>{moment.duration(sliderData.time, 'seconds').hours()}</span>
                                <span>h</span>
                                <span>{(moment.duration(sliderData.time, 'seconds').minutes()).toString().padStart(2, '0')}</span>
                                <span>m</span>
     */

    const endSessionInfoModal = () => {

        const handleNoShow = () => {
            let toggleOn = !showEndSessionInfoModalToggle;
            setShowEndSessionInfoModalToggle(toggleOn);

            let us = window.localStorage.getItem("userSettings");
            let usCopy = null;
            if (us) {
                us = JSON.parse(us);
                usCopy = cloneDeep(us);
            }
            else {
                usCopy = {};
            }

            if (!toggleOn) {
                delete usCopy.hideEndSessionInfoModal;
            }
            else {
                usCopy.hideEndSessionInfoModal = 'yes';
            }
            window.localStorage.setItem("userSettings", JSON.stringify(usCopy));
        }

        return (
            <Modal 
                className='end-session-info-modal'
                size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show
                onHide={() => { setShowEndSessionInfoModal(false)  }}
            >
                <Modal.Body className="help-text-modal">
                <div>
                                <p>{t('endSessionInfoModal.session_started')}</p>
                                <p>{t('endSessionInfoModal.to_end')}</p>
                            </div>
                    <div className="button-row" style={{ alignItems: 'flex-end' }}>

                        <Toggle checked={showEndSessionInfoModalToggle}
                            icons={{
                                checked: <span className="track-text"></span>,
                                unchecked: <span className="track-text"></span>,
                            }}
                            onChange={() => { handleNoShow() }} />
                        <span style={{ fontSize: '14px', marginLeft: '5px' }}>{t('endSessionInfoModal.no_show')}</span>

                        <Button className="ok-btn" onClick={() => { setShowEndSessionInfoModal(false) }}>OK</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }


    
    return (
    <div className={'bottom-overlay ' + viewMode}>
            { (viewMode === VIEW_MODE.SMALL)? renderSmallOverlay() : null }
            { (viewMode === VIEW_MODE.FULL)? renderFullOverlay() : null }
        
        {showEndSessionInfoModal ? endSessionInfoModal() : null}
    </div>
  );
}