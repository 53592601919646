import React, { useState, } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressButton from 'js/components/progressButton'
import 'styles/profileForm.scss';
import 'styles/common.scss';

import { useTranslation } from 'react-i18next';


export default function AddVehicleForm({submitCB, cancelCB, submitting}) {

    const [regNo, setRegNo]  = useState(''); 
    const [regNoError, setRegNoError]  = useState(false); 
    const [name, setName]  = useState(''); 
    
    
    
    const { t } = useTranslation('components');

    


    /*const getModifiedProps = (original, modified) => {
        let delta = reduce(original, function(result, value, key) {
            return isEqual(value, modified[key]) ?
                result : result.concat(key);
        }, []);
        let modifiedProps = {};
        for (let i = 0; i < delta.length; i++) {
            modifiedProps[delta[i]] = modified[delta[i]];
        }
        return modifiedProps;
    }*/

    const handleRegNoChange = (e) => {
        if (regNoError) setRegNoError(false);
        setRegNo(e.target.value.toUpperCase());
    }

    const handleNameChange = (e) => {
        if (e.target.value > 13) return;
        setName(e.target.value);
    }

    const handleRegNoBlur = (e) => {
        if (e.target.value && e.target.value.length > 0 && 
            (e.target.value.length < 2 || e.target.value.length > 7) ) {
            setRegNoError(true);
        }
    }

    const handleSubmit = () => {
        let v = {regNo: regNo};
        if (name && name.length > 0) v.name = name;

        submitCB(v);

        /*
        let modifiedVehicles = [v];
        if (user.vehicles && user.vehicles.length > 0) modifiedVehicles = modifiedVehicles.concat(user.vehicles);
        modifiedVehicles = modifiedVehicles.filter((v,i,a)=>a.findIndex(t=>(t.regNo===v.regNo))===i); 
        console.log(modifiedVehicles);
        try {
            if (delta.hasOwnProperty('subscribedToNewsletter')) {
                await Api.updateUserMeNewsletterPreference(delta.subscribedToNewsletter === 'true');
            }
            const userMe = await Api.updateUserMe(delta);
            
            if (userMe) {
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage);       
            }
            setSubmitting(false);
            cancelCB();
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            cancelCB();
        }
        */
        
    }
    

    /*const handleSubmitClick = async (values, setSubmitting, setErrors) => {
        console.log('HANDLE SUBMIT');
        let delta = HelperService.getModifiedProps(initialValues, values);
        console.log(delta);

        try {
            if (delta.hasOwnProperty('subscribedToNewsletter')) {
                await Api.updateUserMeNewsletterPreference(delta.subscribedToNewsletter === 'true');
            }
            const userMe = await Api.updateUserMe(delta);
            
            if (userMe) {
                let usage = await Api.getMyUsage();
                userMe.usage = usage;
                setUserMe(userMe);
                if (userMe.preferredLanguage) i18n.changeLanguage(userMe.preferredLanguage);       
            }
            setSubmitting(false);
            cancelCB();
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            cancelCB();
        }
        
    }*/

// 

return (
    <div className="profile-form">
    <Form  onSubmit={handleSubmit}>
        <Form.Group>
            <Form.Label>{t("addVehicleForm.license_no")} *</Form.Label>
            <Form.Control size="lg" id="regNo" name="regNo" type="text" placeholder="ABC123" value={regNo}
                onChange={handleRegNoChange} onBlur={handleRegNoBlur}/>
            {regNoError && (<Form.Text className="input-feedback" style={{color:'red'}}> {t("addVehicleForm.reg_no_minimum_six")}</Form.Text>)}
        </Form.Group>

        <Form.Group>
            <Form.Label>{t("addVehicleForm.vehicle_name")}</Form.Label>
            <Form.Control size="lg" id="name" name="name" type="text" placeholder={t("addVehicleForm.name_placeholder")} value={name}
                onChange={handleNameChange} />
        </Form.Group>

        <div className="button-row">
        <ProgressButton disabled={!regNo || regNo.length < 2 || regNo.length > 7 } className="submit-button" text={t("addVehicleForm.add")} loading={submitting} clickCB={handleSubmit}/>
        <Button className="cancel-button" disabled={false} onClick={cancelCB}>{t("profileForm.cancel")}</Button>
        </div>
    </Form>  
    </div>
);
}