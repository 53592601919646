import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronRight, faChevronDown, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import HelperService from '../../services/helpers';
import 'styles/common.scss';
import 'styles/mapInfoWindow.scss';

import { useTranslation } from 'react-i18next';

export default function MapInfoWindow({provider, location, outlets, outletStatuses, loadingData, showParkingMapCB, closeCB}) {

    const { t, i18n } = useTranslation("components")
    //console.log(location);

    const [showSupport, setShowSupport] = useState(false);
    

    const getStreetAddress = (address) => {
        if (address) return address.streetAddress;
        else return null;
    }

    const getStatusSummary = (groups) => {

        let available = 0;
        let total = 0;
        
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].count) total += groups[i].count; 
            if (groups[i].available) available += groups[i].available; 
        }

        if (available === 0) return 'STATUS_FULL';
        if ( available < 0.25*total) return 'STATUS_CROWDED';
        else return 'STATUS_FREE';
    }

    const renderOutletGroupStatus = (group) => {
        if (!group.available && !group.busy) return null;
        let available = (group.available) ? group.available : 0;
        let busy = (group.busy) ? group.busy : 0;
        return (<span className="stats">{available} {t("mapInfoWindow.available")}, {busy} {t("mapInfoWindow.busy")}</span>);
    }

    const OutletInfo = ({groups}) => {
        if (!groups || groups.length === 0) return t("mapInfoWindow.no_outlets");
        let status = getStatusSummary(groups);
        let text = t("mapInfoWindow.free");
        if (status === 'STATUS_FULL') text = t("mapInfoWindow.no_avail_spots");
        else if (status === 'STATUS_CROWDED') text = t("mapInfoWindow.few_avail_spots");
        return (
        <>
            <div className="outlet-status"><span>Status</span><span className={status}>{text}</span></div>
          {groups.map((group, i) => (
            <div className="group" key={i}>
                <span className="amps"> {group.count} {t("mapInfoWindow.outlets")} {group.V}V/{group.A}A </span>
                { renderOutletGroupStatus(group)}
            </div>
          ))}
        </>
        );
    };

    const groupOutlets = (location, outlets, outletStatuses) => {
        if (!outlets) return [];
        
  /*      console.log('GROUP OUTLETS');
        console.log(outlets);
        console.log(outletStatuses);
        */

        let groups = [];
        for (let i = 0; i < outlets.length; i++) {
            let V = outlets[i].voltage;
            let A = '-';
            let outletStatus = 'STATUS_UNKNOWN'; 
            if (outletStatuses) {
                //let locStatus = outletStatuses.find(loc => loc.locationId === location.legacyId);
                let qualifiedId = location.parents.concat(['V2Location', location.id]);
                let locStatus = outletStatuses.find(loc => HelperService.idPathsAreEqual(loc.locationIdPath, qualifiedId));

                if (locStatus) {
                    /*console.log('found loc status');
                    console.log(locStatus);
                    console.log(outlets[i]);
                    */
                    let status = locStatus.status.find(os => os.outletId === outlets[i].id);
                    //console.log(status);
                    if (status && status.status === 'STATUS_AVAILABLE') outletStatus = 'STATUS_AVAILABLE'; 
                    else if (status && status.status === 'STATUS_BUSY') outletStatus = 'STATUS_BUSY'; 
                } 
                
            }
            if (outlets[i].throughputLimit) A = Math.min(outlets[i].throughputLimit.p1, outlets[i].throughputLimit.p2, outlets[i].throughputLimit.p3);
            else if (outlets[i].maxThroughput) A = Math.min(outlets[i].maxThroughput.p1, outlets[i].maxThroughput.p2, outlets[i].maxThroughput.p3);

            let foundIndex = groups.findIndex(g => (g.V === V && g.A === A));
            if ( foundIndex >= 0 ) {
                groups[foundIndex].count++;
                if (outletStatus === 'STATUS_AVAILABLE') {
                    if (groups[foundIndex].available) groups[foundIndex].available++;
                    else groups[foundIndex].available = 1;
                }
                else { // all other statuses
                    if (groups[foundIndex].busy) groups[foundIndex].busy++;
                    else groups[foundIndex].busy = 1;
                }
            }
            else { 
                let info = {V:V, A:A, count: 1};
                if (outletStatus === 'STATUS_AVAILABLE') info.available = 1;
                else info.busy = 1; // all other statuses
                groups.push(info);
            }
        }
        //console.log(groups);
        return groups;
    }

    const renderFees = (tariff) => {
        if (! tariff) return null;
        let tar = tariff;
        if (!tar.feeKwh) tar.feeKwh = 0;
        if (!tar.feeHour) tar.feeHour = 0;

        let openingFee = tar.openingFee;
        if (!openingFee) openingFee = 0;
        
        if (tar.feeKwh === 0 && openingFee === 0 && tar.feeHour === 0) return (<span>{t("mapInfoWindow.no_fee")}</span>);
        return (
            <>
                {(tar.feeKwh > 0) ? <span>{parseFloat(tar.feeKwh.toFixed(2))} kr/kWh</span> : null}
                {(tar.feeKwh > 0 && tar.feeHour && tar.feeHour> 0) ? ' + ' : null} 

                {(tar.feeHour > 0) ? <span>{parseFloat(tar.feeHour.toFixed(2))} {t("mapInfoWindow.kr_h")} </span> : null}
                
                {((tar.feeKwh > 0) || (tar.feeHour > 0)) && (openingFee > 0) ? ' + ' : null} 

                {(openingFee > 0) ? <span>{parseFloat(openingFee.toFixed(2))} {t("mapInfoWindow.opening")}</span> : null}

                <div style={{fontSize:'11px', color:'#555', marginTop:'5px'}}> {t("mapInfoWindow.tariff_disclaimer")}</div>
            </>
        )
    }
    
    const isRestrictedPublic = () => {
        return (location && location.accessPolicies && location.accessPolicies.length > 0 && location.accessPolicies[0].policy === 'RESTRICTED_PUBLIC');
    }

    const getTariffInfo = (outlets) => {        
        if (!outlets) return null;
        let tariff = null;
        for (let i = 0; i < outlets.length; i++) {
            if (!tariff) {
                tariff = outlets[i].tariff;
                continue;
            }
            if (outlets[i].tariff && tariff.id === outlets[i].tariff.id) continue;
            else if (outlets[i].tariff && tariff.id !== outlets[i].tariff.id) return t("mapInfoWindow.multiple_tariffs")
        }

        if (!tariff) return null;
        return (
            <div className="tariffs">
                <div className="tariffs-title">{t("mapInfoWindow.fee")}</div>
                {renderFees(tariff)}
            </div>
            );
    }

    const renderRestrictions = () => {
        let restrictionText = null;
        if (location && location.publicRestrictionAnnotation) {
            restrictionText = location.publicRestrictionAnnotation[i18n.language];
        }
        return (
            <>
                <div className="restrictions-apply-title">{t("mapInfoWindow.restrictions_apply")}</div>
                { restrictionText ? <p className="restrictions-apply-text">{restrictionText}</p> : null}
            </>
        );
    }

    const hasSupportContact = () => {
        return  provider && provider.supportContact && 
                (   (provider.supportContact.name && provider.supportContact.name.length > 0) || 
                    (provider.supportContact.email && provider.supportContact.email.length > 0) ||
                    (provider.supportContact.phone && provider.supportContact.phone.length > 0)
                );
    }

    const renderSupport = () => {
        return (
            <div className="support-data">
                { provider.supportContact.name ? <div>{provider.supportContact.name}</div> : null }
                { provider.supportContact.phone ? <div><FontAwesomeIcon icon={faPhone}/>{provider.supportContact.phone}</div> : null }
                { provider.supportContact.email ? <div><FontAwesomeIcon icon={faEnvelope}/>{provider.supportContact.email}</div> : null }
            </div>    
        );
    }


  return (
     <div className="map-info-window">
            <div className="title"> {location.name} </div>
            <div className="provider"> {provider ? provider.name : null} </div>
            <div className="address"> {getStreetAddress(location.address)} </div>

            { loadingData ? 
                <div>{t("mapInfoWindow.loading_outlet")}</div> : 
                <div className="outlets">
                    <OutletInfo groups={groupOutlets(location, outlets, outletStatuses)} />
                </div>
            }
            
                {getTariffInfo(outlets)}

                { isRestrictedPublic() ? renderRestrictions() : null }
            
            {
                showParkingMapCB ? 
                <div className="btn-row">
                    <Button className="ovale-button" onClick={()=>{showParkingMapCB(location)}}>{t("mapInfoWindow.parking_map")}</Button>
                </div>
                : null
            }

            {
                hasSupportContact() ? 
                <div className="support-row" onClick={()=>setShowSupport(!showSupport)}>
                    <div className="support-title">Support <span>{showSupport ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}</span></div>
                    {showSupport ? renderSupport() : null}
                </div>
                : null
            }

            <div className="close-button" onClick={() => closeCB()}><FontAwesomeIcon icon={faTimes} /></div>
            
        </div>
      
    
  );
}