import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//

//import { useHistory } from "react-router-dom";

import 'styles/titleBar.scss';

export default function TitleBar(props) {
    const {title, icon, clickCB} = props;
    //let history = useHistory();

    return (
        <div className="nav-bar">  
            <div className="nav-bar-icon" onClick={()=>clickCB()} ><FontAwesomeIcon icon={icon} /></div>
            <div className="title">{title}</div>
            
        </div>
    );
}