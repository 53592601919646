import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import DataStoreContext from 'dataStoreContext'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import TitleBar from 'js/components/titleBar.js';
import cloneDeep from 'lodash/cloneDeep';
import 'styles/settingsView.scss'
import 'styles/reactToggle.scss'
import 'styles/common.scss'

// Translation
//import { useTranslation } from 'react-i18next';

export default function SettingsView(props) {
    let history = useHistory();
    
    //const [darkMode, setDarkMode] = useState(false);
    const [primaryUnitTime, setPrimaryUnitTime] = useState(true);
    const { userSettings, setUserSettings } = useContext(DataStoreContext);

    // Dev panel stuff
    //const [devModeCounter, setDevModeCounter] = useState(0);
/*    const [quickCloseLoadingScreen, setQuickCloseLoadingScreen] = useState(false);
    const [usePlayground, setUsePlayground] = useState(false);
    const [verboseInfo, setVerboseInfo] = useState(false);
*/

    // Translation
    //const { t } = useTranslation('views');


    useEffect(() => {
        props.toggleMenu(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        if (userSettings) {
         //   setDarkMode(userSettings.theme === 'dark');
            setPrimaryUnitTime(userSettings.primaryUnit === 'time');

          /*  if (userSettings.devOptions) {
                if (userSettings.devOptions.quickCloseLoadingScreen) setQuickCloseLoadingScreen(true);
                if (userSettings.devOptions.usePlayground) setUsePlayground(true);
                if (userSettings.devOptions.verboseInfo) setVerboseInfo(true);
            }
            */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userSettings]);

    const handleBackClick = () => {
        history.push("/");
    }

   /* const showDevMode = () => {
        setDevModeCounter(devModeCounter+1);
        
    }*/

    /*

    const handleVerboseInfoChange = () => {
        var userSettingsCopy = cloneDeep(userSettings);
        userSettingsCopy.devOptions.verboseInfo = !verboseInfo;
        setUserSettings(userSettingsCopy);
        setVerboseInfo(!verboseInfo)
    }
    const handleUsePlaygroundChange = () => {
        var userSettingsCopy = cloneDeep(userSettings);
        userSettingsCopy.devOptions.usePlayground = !usePlayground;
        setUserSettings(userSettingsCopy);
        setUsePlayground(!usePlayground);
    }
    const handleQuickCloseLoadingScreenChange = () => {
        var userSettingsCopy = cloneDeep(userSettings);
        userSettingsCopy.devOptions.quickCloseLoadingScreen = !quickCloseLoadingScreen;
        setUserSettings(userSettingsCopy);
        setQuickCloseLoadingScreen(!quickCloseLoadingScreen);
    }
    */


  /*  const handleDarkModeChange = () => {
        var userSettingsCopy = cloneDeep(userSettings);
        if (darkMode) userSettingsCopy.theme = 'light';
        else userSettingsCopy.theme = 'dark';
        setUserSettings(userSettingsCopy);
    }
    */

    // eslint-disable-next-line
    const handlePrimaryUnitChange = () => {
        var userSettingsCopy = cloneDeep(userSettings);
        if (primaryUnitTime) userSettingsCopy.primaryUnit = 'currency';
        else userSettingsCopy.primaryUnit = 'time';
        setUserSettings(userSettingsCopy);
    }

   /* const renderDevPanel = () => {
        return (
            <div className="dev-panel">
                <h3>Developer Panel</h3>
            <div className="settings-prop">
                <div className="label">Verbose Info in Views:</div>
                <Toggle checked={verboseInfo} onChange={handleVerboseInfoChange} icons={{
                        checked: <span className="track-text">ON</span>,
                        unchecked: <span className="track-text">OFF</span> }} />
            </div>
            <div className="settings-prop">
                <div className="label">Use Playground:</div>
                <Toggle checked={usePlayground} onChange={handleUsePlaygroundChange} icons={{
                        checked: <span className="track-text">ON</span>,
                        unchecked: <span className="track-text">OFF</span> }} />
            </div>
            <div className="settings-prop">
                <div className="label">Immediately Close Welcome Screen:</div>
                <Toggle checked={quickCloseLoadingScreen} onChange={handleQuickCloseLoadingScreenChange} icons={{
                        checked: <span className="track-text">ON</span>,
                        unchecked: <span className="track-text">OFF</span> }} />
            </div>


            <h4>Service URLs</h4> 
            <div className="label">Infrastructure Service Url</div>
            <div className="value">{Api.infrastructureUrl}</div>
                

            </div>
        );    
    }*/
    
  
    return (
    <div className="sliding-view settings-view">
        <div className="bg"></div>
        <div className="cover"></div>
        <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={'Inställningar'}/>
        
        <div className="content">
            

            {/*<div className="settings-prop">
                <div className="label">Primär enhet:</div>
                tid
                <Toggle
                    checked={primaryUnitTime}
                    icons={{
                        checked: <span className="track-text"></span>,
                        unchecked: <span className="track-text"></span>,
                      }}
                    onChange={handlePrimaryUnitChange} />
                belopp    
                    </div>*/}
            
            {/* <div className="settings-prop">
                <div className="label" onClick={showDevMode}>Mörkt utseende:</div>
                <Toggle
                    checked={darkMode}
                    icons={{
                        checked: <span className="track-text">PÅ</span>,
                        unchecked: <span className="track-text">AV</span>,
                      }}
                    onChange={handleDarkModeChange} />
            </div> */}

            {/* (devModeCounter >= 5)? renderDevPanel() : null */}
        </div>
    </div>
  );
}