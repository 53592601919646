import React, {useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import TitleBar from 'js/components/titleBar.js';
import helpTexts from 'resources/helpTexts.json';
import 'styles/faqView.scss';
import 'styles/common.scss';



export default function FaqView(props) {
    const { t, i18n } = useTranslation('views');
    let history = useHistory();
    const enFaqs = useRef([]);
    const svFaqs = useRef([]);


    useEffect(() => {
        props.toggleMenu(false);
        console.log('FAQ*****');
        console.log(svFaqs.current);

        for(const category in helpTexts){
            let faqCategoryEn = { title: "", faqs: [] };
            let faqCategorySv = { title: "", faqs: [] };
            
            faqCategoryEn.title = helpTexts[category].en;
            faqCategorySv.title = helpTexts[category].sv;
            for (const question in helpTexts[category]) {
                if (helpTexts[category][question].faqItem) {
                    faqCategoryEn.faqs.push(helpTexts[category][question].en);
                    faqCategorySv.faqs.push(helpTexts[category][question].sv);
                };
            };
            enFaqs.current.push(faqCategoryEn);
            svFaqs.current.push(faqCategorySv);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    const handleBackClick = () => {
        history.push("/");
    }

    /*
    const renderFaq = (faqItems) => {
        if(!faqItems || faqItems.length === 0) return null;
        return (
            <div className="faq-container">
                { faqItems.map((item, index) => {
                    return (
                        <div  className="faq-item" key={index}>    
                    <div  className="faq-question">
                        {item.faqQuestion}
                    </div>    
                    <div  className="faq-answer">
                        {item.text.map((paragraph, i) => 
                                <p key={i}>{paragraph}</p>
                            )}
                    </div>
                    </div>
                    );
                    }
                )}
                </div>
        );
    }
    */

    const renderFaq = (categories) => {
        if (!categories || categories.length === 0) return null;
        return (
            <div className="faq-container">
                {categories.map((category, index) => {
                    return (
                        <div key={"tab"+index} className="tab">
                            <input type="checkbox" id={"chck" + index} />
                            <label className="tab-label" htmlFor={"chck" + index}>{category.title}</label>
                            <div className="tab-content">
                                {category.faqs.map((question, i) => {
                                    return (
                                        <div key={i} className="faq">
                                            <div>
                                                <input type="checkbox" id={"faq-chck" + index + i} />
                                                <label className="faq-label" htmlFor={"faq-chck" + index + i}>{question.faqQuestion}</label>
                                                <div className="faq-content">
                                                    {question.text.map((item, j) => {
                                                        return (
                                                            <p key={j}>{item}</p>
                                                        )
                                                    })}
                                                </div> 
                                            </div>
                                        </div>
                                    )
                                })}
                                <br/>
                            </div>
                        </div>
                    );
                })
                }
            </div>
        );
    }


    

    return (
    <div className="sliding-view faq-view">
        <div className="bg"></div>
        <div className="cover"></div>
        <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={'Faq'}/>
        
        <div className="content">
            <h1>{t('faqView.common_questions')}</h1>
            {i18n.language === 'en' ? renderFaq(enFaqs.current) : renderFaq(svFaqs.current)}
        </div>
    </div>
  );
}