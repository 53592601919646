import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/common.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';

//let stripePromise = loadStripe("pk_test_BapKX9tpXc4Q2pBtjQmkL7BC");
let stripePromise = loadStripe('pk_live_OW5nQaCfjxNH14yRcxfseFaD');

window.flutterApi = {
    setNotificationToken: null,
};


ReactDOM.render(
    <Elements stripe={stripePromise}>
        <React.StrictMode>
            <Router>
                <Suspense fallback="loading">
                    <App />
                </Suspense>
            </Router>
        </React.StrictMode>
    </Elements>,
    document.getElementById('root')
);

/*window.addEventListener('scroll', function(e) {
    let last_known_scroll_position = window.scrollY;
  
    console.log(last_known_scroll_position);
    if(last_known_scroll_position === 114) window.scroll(window.scrollX, 0);
  });

<App ref={(reactApp) => {window.reactApp = reactApp}}/>

*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
