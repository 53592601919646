import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {ReceiptBackground} from 'resources/icons.js';
//import visa from 'resources/visa.png';
//import mastercard from 'resources/mastercard.png';
import moment from 'moment';
import 'moment/locale/sv'
import 'moment-duration-format';

import 'styles/sessionReceipt.scss';
import { useTranslation } from 'react-i18next';

export default function SessionReceipt({session, modal, closeCB}) {
    
    const { t, i18n } = useTranslation("components")
    moment.locale(i18n.language);
    //receiptMetadata:{newBalance:5000,cardBrand:"visa",last4:"4242"}

    const formatDuration = (dur) => {
        let duration = moment.duration(dur, 'seconds');
        let prettyDur = (duration.days() > 0 ? duration.days() + 'd:' : '') + (duration.hours() > 0 ? duration.hours() + 'h:' : '') + duration.minutes() + 'm:' + duration.seconds() + 's' ;
        return prettyDur;
    
    }

    const formatPower = (totalEnergy) => {
        let n = totalEnergy / 1000000;
        if(n === 0) return "0"
        if( n > 0 && n < 0.001) return "< 0.001"
        return n.toFixed(3);
        
    }

    const getProviderName = () => {
        if (!session) return null;
        if (session.provider && session.provider.name) return session.provider.name;
        if (session.infrastructureInfo) return session.infrastructureInfo.providerName;
        return 'unknown provider';
    }

    const renderReceipt = () => {
        return (
            <div className="payment-receipt">
                <ReceiptBackground fill='white' />
                <div className="content">
                    <div className="logo"><div /></div>
                    <h2>{t("sessionReceipt.ended_session")}</h2>
                    <div className="creation-date">{getProviderName()}, {moment(session.startTime).format('LL')}</div>

                    <div className="tuple">
                        <div className="tuple-title">
                        {t("sessionReceipt.outlet")}
                    </div>
                        <div className="tuple-value">
                            {session.outletId} 
                        </div>
                    </div>

                    <div className="tuple">
                        <div className="tuple-title">
                        {t("sessionReceipt.start_time")}
                    </div>
                        <div className="tuple-value">
                            {moment(session.startTime).format('LT') }
                        </div>
                    </div>

                    <div className="tuple">
                        <div className="tuple-title">
                        {t("sessionReceipt.session_duration")}
                    </div>
                        <div className="tuple-value">
                            {formatDuration(session.duration)}
                        </div>
                    </div>

                    <div className="tuple">
                        <div className="tuple-title">
                        {t("sessionReceipt.received_energy")}
                    </div>
                        <div className="tuple-value">
                            {formatPower(session.totalEnergy)} kWh
                        </div>
                    </div>

                    

                    
                    { modal ? 
                        <> 
                            <Button className="close-button" onClick={()=>{closeCB()}}>{t("sessionReceipt.close")}</Button>

                        </>
                        :<> 
                            <div className="thank-you-text">{t("sessionReceipt.thanks")}</div>
                        </>
                    }

                    <div className="info-text">
                        {formatEndStatus(session.endReason)}
                    </div>

                    <div className="bottom-line">
                            <span>www.epspot.com</span><span>org. nr: 556965-5391</span>
                        </div>

                </div>

            </div>
          );

    }

    if (session && session.id === '2cdaf62c-1a62-4143-b889-b84f427582fe') console.log(session);

    const formatEndStatus = (status) => {
        switch (status) {
            case 'ENDED_BY_UNKNOWN_REASON': return t("sessionReceipt.ENDED_BY_UNKNOWN_REASON");
            case 'ENDED_BY_SERVER_ERROR': return t("sessionReceipt.ENDED_BY_SERVER_ERROR");
            case 'ENDED_BY_LOW_BALANCE': return t("sessionReceipt.ENDED_BY_LOW_BALANCE");
            case 'ENDED_BY_PROVIDER': return t("sessionReceipt.ENDED_BY_PROVIDER");
            case 'ENDED_BY_ADMIN': return t("sessionReceipt.ENDED_BY_ADMIN");
            case 'ENDED_BY_USER': return t("sessionReceipt.ENDED_BY_USER");
            case 'ENDED_NORMALLY': return t("sessionReceipt.ENDED_NORMALLY");
            case 'ENDED_BY_FRAUD_SUSPECTION': return t("sessionReceipt.ENDED_BY_FRAUD_SUSPECTION");
            case 'ENDED_CONNECTOR_REMOVED': return t("sessionReceipt.ENDED_CONNECTOR_REMOVED");
            case 'ENDED_TIME_MET': return t("sessionReceipt.ENDED_TIME_MET");
            case 'ENDED_KWH_MET': return t("sessionReceipt.ENDED_KWH_MET");
            case 'ENDED_BY_OUTLET': return t("sessionReceipt.ENDED_BY_OUTLET");
            case 'ENDED_BY_BAD_MEASURE': return t("sessionReceipt.ENDED_BY_BAD_MEASURE");
            case 'ENDED_BY_TOO_LOW_POWER': return t("sessionReceipt.ENDED_BY_TOO_LOW_POWER");
            case 'ENDED_BY_EVSE_DIOD_ERROR': return t("ENDED_BY_EVSE_DIOD_ERROR");
            case 'ENDED_BY_EVSE_VENT_REQUIRED_ERROR': return t("sessionReceipt.ENDED_BY_EVSE_VENT_REQUIRED_ERROR");
            case 'ENDED_BY_POWER_FAILURE': return t("sessionReceipt.ENDED_BY_POWER_FAILURE");
            case 'ENDED_BY_MIN_LOAD_CONDITION_MET': return t("sessionReceipt.ENDED_BY_MIN_LOAD_CONDITION_MET");
            case 'ENDED_BY_CONTACT_LOST': return t("sessionReceipt.EENDED_BY_CONTACT_LOST");
            case 'ENDED_CONNECTOR_REMOVED_WITH_BIG_LOAD': return t("sessionReceipt.ENDED_CONNECTOR_REMOVED_WITH_BIG_LOAD");
            case 'ENDED_BY_EVSE_ENDED_BY_VEHICLE': return t("sessionReceipt.ENDED_BY_EVSE_ENDED_BY_VEHICLE");
            case 'ENDED_BY_EVSE_DC_LEAK_DETECTION': return t("sessionReceipt.ENDED_BY_EVSE_DC_LEAK_DETECTION");
            case 'ENDED_BY_EVSE_EMERGENCY_STOP': return t("sessionReceipt.ENDED_BY_EVSE_EMERGENCY_STOP");
            default: return t("sessionReceipt.ENDED_BY_UNKNOWN_REASON");
        }   
    }
    
    //<div className="creation-date">Org. nr: 556965-5391</div>
    if (!session) return null;
    if (session.id === '679000008') {
        console.log('+++++++++');
        console.log(session);
        let duration = moment.duration(session.duration, 'seconds');
        console.log(duration);
        console.log(duration.hours());
        console.log(duration.minutes());
        let prettyDur = (duration.hours() > 0 ? duration.hours() + 'h:' : '') + duration.minutes() + 'm:' + duration.seconds() + 's' ;
        console.log(prettyDur);
    }
    if (modal) return (
        <Modal dialogClassName="receipt-modal" show aria-labelledby="contained-modal-title-vcenter" onHide={()=>{}}>
            {renderReceipt()}
        </Modal>
    )
    else return renderReceipt();


    
}

// 