import React from 'react';

export const IconQr = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill }>
        <path d="m30 30h90v-30h-120v120h30zm0 0"/>
        <path d="m392 0v30h90v90h30v-120zm0 0"/><path d="m482 482h-90v30h120v-120h-30zm0 0"/><path d="m30 392h-30v120h120v-30h-90zm0 0"/><path d="m61 60v150h150v-90h30v-30h-30v-30zm120 120h-90v-90h90zm0 0"/><path d="m451 450v-150h-60v-30h-30v30h-90v30h30v30h-30v30h30v60zm-120-120h90v90h-90zm0 0"/><path d="m151 270h60v-30h-150v30h60v30h-30v30h30v60h-30v30h30v30h150v-30h-30v-30h-30v30h-60v-30h30v-30h-30zm0 0"/><path d="m121 120h30v30h-30zm0 0"/><path d="m361 120h30v30h-30zm0 0"/><path d="m391 210h60v-150h-150v150h60v30h30zm-60-30v-90h90v90zm0 0"/><path d="m451 270v-30c-7.257812 0-52.691406 0-60 0v30zm0 0"/><path d="m361 360h30v30h-30zm0 0"/><path d="m241 330h30v30h-30zm0 0"/><path d="m181 360h30c0-7.257812 0-52.691406 0-60h-30zm0 0"/><path d="m211 270h30v30h-30zm0 0"/><path d="m91 330h-30v60h30c0-7.257812 0-52.691406 0-60zm0 0"/><path d="m61 420h30v30h-30zm0 0"/><path d="m241 60h30v30h-30zm0 0"/><path d="m241 180h30c0-7.257812 0-52.691406 0-60h-30zm0 0"/><path d="m271 240v-30h-30v60h120v-30zm0 0"/>
     </svg>
  );

  export const IconBattery = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon battery-icon">
        <path d="M176 136V392c0 13.255 10.745 24 24 24l112 0c13.255 0 24-10.745 24-24l0-256c0-13.255-10.745-24-24-24h-8v-4c0-6.627-5.373-12-12-12H220c-6.628 0-12 5.373-12 12v4l-8 0c-13.255 0-24 10.745-24 24zM186 165l142 0"/>
     </svg>
  );

  export const IconClock = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon clock-icon">
        <path d="m256.00001,96c-92.80645,0 -168,72.69305 -168,162.4133s75.19355,162.4133 168,162.4133s168,-72.69305 168,-162.4133s-75.19355,-162.4133 -168,-162.4133zm62.65452,204.9813l0,0l-13.54839,16.37231a10.83871,10.47828 0 0 1 -15.23516,1.63723l0,0l-45.3871,-32.56125a27.09677,26.19569 0 0 1 -10.16129,-20.45229l0,-101.8685a10.83871,10.47828 0 0 1 10.83871,-10.47828l21.67742,0a10.83871,10.47828 0 0 1 10.83871,10.47828l0,94.3045l39.29032,27.83292a10.83871,10.47828 0 0 1 1.68677,14.73508z"/>
     </svg>
  );
  
  export const IconPlug = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon plug-icon">
        <path d="m381,37.25a31.25,31.25 0 0 0 -62.5,0l0,93.75l62.5,0l0,-93.75zm46.875,125l-343.75,0a15.625,15.625 0 0 0 -15.625,15.625l0,31.25a15.625,15.625 0 0 0 15.625,15.625l15.625,0l0,31.25a156.31836,156.31836 0 0 0 125,153.125l0,96.875l62.5,0l0,-96.875a156.31836,156.31836 0 0 0 125,-153.125l0,-31.25l15.625,0a15.625,15.625 0 0 0 15.625,-15.625l0,-31.25a15.625,15.625 0 0 0 -15.625,-15.625zm-234.375,-125a31.25,31.25 0 0 0 -62.5,0l0,93.75l62.5,0l0,-93.75z"/>
     </svg>
  );


export const IconCarCharge = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon car-charge-icon">
        <path d="m488.01969,261.43582l-64.67238,0l-17.47472,-36.48624c-13.30537,-22.17887 -14.79271,4.08897 -16.65082,11.08932c-1.8581,7.00035 -4.18748,65.24066 -134.82224,205.46457l108.3262,2.36358l0,28.06634c0,15.49788 15.47946,28.06635 34.56684,28.06635l34.56683,0c19.08738,0 34.56684,-12.56846 34.56684,-28.06635l0,-47.42334c10.6293,-9.86707 17.28343,-22.62849 17.28343,-36.77568l0,-42.0995c0,-16.85735 -9.34386,-31.81144 -23.84032,-42.09952l21.6799,0c5.95197,0 11.137,-3.28902 12.57368,-7.97259l6.48127,-21.04976c2.04161,-6.63946 -4.14802,-13.07718 -12.58449,-13.07718l-0.00003,0l0,0l0.00001,0zm-90.7271,126.12314c-20.74011,0 -51.85026,2.79787 -51.85026,-13.9893s31.11017,-41.96795 51.85026,-41.96795s34.56682,11.19146 34.56682,27.97864s-13.82673,27.97864 -34.56682,27.97864l0,-0.00001l0,-0.00002z"/>
        <path d="m309.66666,40.05717a29.16666,24.05717 0 0 0 -58.33332,0l0,72.17152l58.33332,0l0,-72.17152zm43.74999,96.2287l-320.83325,0a14.58333,12.02859 0 0 0 -14.58333,12.02859l0,24.05717a14.58333,12.02859 0 0 0 14.58333,12.02859l14.58333,0l0,24.05717a145.8971,120.3385 0 0 0 116.66664,117.88016l0,74.57724l58.33332,0l0,-74.57724a145.8971,120.3385 0 0 0 116.66664,-117.88016l0,-24.05717l14.58333,0a14.58333,12.02859 0 0 0 14.58333,-12.02859l0,-24.05717a14.58333,12.02859 0 0 0 -14.58333,-12.02859zm-218.74995,-96.2287a29.16666,24.05717 0 0 0 -58.33332,0l0,72.17152l58.33332,0l0,-72.17152z"/>
     </svg>
  );

  export const IconQuestionCircle = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon question-icon">
        <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"/>
     </svg>
  );

  export const IconHourglass = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon question-icon">
        <path d="M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-75.078 384H99.08c17.059-46.797 52.096-80 92.92-80 40.821 0 75.862 33.196 92.922 80zm.019-256H99.078C91.988 108.548 88 86.748 88 64h208c0 22.805-3.987 44.587-11.059 64z"/>
     </svg>
  );

  
  

  export const IconCarHeater = ({ fill }) => (
    <svg viewBox="0 0 512 512" fill={ fill } className="ep-icon car-heater-icon">
        <path d="m456.33339,325.60092l-48.1758,0l-13.38977,-28.35515c-13.75991,-29.15264 -46.59864,-47.98564 -83.66995,-47.98564l-102.19353,0c-37.06326,0 -69.91003,18.833 -83.67799,47.98564l-13.38977,28.35515l-48.16775,0c-6.2845,0 -10.89528,5.00304 -9.3664,10.16287l4.82804,16.35875c1.07022,3.63982 4.93265,6.19588 9.3664,6.19588l16.1498,0c-10.80676,7.99534 -17.76719,19.61686 -17.76719,32.71748l0,32.71748c0,10.98762 4.95679,20.90511 12.87478,28.58008l0,36.85488c0,12.04412 11.53097,21.81166 25.74955,21.81166l25.74955,0c14.21858,0 25.74955,-9.76753 25.74955,-21.81166l0,-21.81166l205.99641,0l0,21.81166c0,12.04412 11.53097,21.81166 25.74955,21.81166l25.74955,0c14.21858,0 25.74955,-9.76753 25.74955,-21.81166l0,-36.85488c7.91799,-7.66816 12.87478,-17.58564 12.87478,-28.58008l0,-32.71748c0,-13.10063 -6.96042,-24.72215 -17.75914,-32.71748l16.1498,0c4.43375,0 8.29619,-2.55605 9.3664,-6.19588l4.82804,-16.35875c1.52083,-5.15982 -3.08995,-10.16287 -9.37445,-10.16287l-0.00003,0zm-283.29333,-12.15318c5.86607,-12.41902 20.06856,-20.5643 35.86429,-20.5643l102.19353,0c15.79574,0 29.99823,8.14529 35.86429,20.5643l16.03715,33.96484l-205.99641,0l16.03715,-33.96484zm-41.78669,110.16932c-15.44973,0 -25.74955,-8.6974 -25.74955,-21.7435s10.29982,-21.7435 25.74955,-21.7435s38.62432,19.56914 38.62432,32.61524s-23.1746,10.87174 -38.62432,10.87174l0,0.00001zm257.49551,0c-15.44973,0 -38.62432,2.17435 -38.62432,-10.87174s23.1746,-32.61524 38.62432,-32.61524s25.74955,8.6974 25.74955,21.7435s-10.29982,21.7435 -25.74955,21.7435l0,-0.00001z"/>
        <path fill="none" stroke-width="20" d="m393.53762,12.45054c19.09036,19.66448 55.1499,62.56881 0,109.0485c-55.1499,46.47969 -2.38607,94.88381 0,97.51236"/>
        <path fill="none" stroke-width="20" d="m264.9566,12.45054c19.09036,19.66448 55.1499,62.56881 0,109.0485c-55.14989,46.47968 -2.38607,94.88381 0,97.51236"/>
        <path fill="none" stroke-width="20" d="m141.90111,12.45054c19.09036,19.66448 55.1499,62.56881 0,109.0485c-55.14989,46.47968 -2.38607,94.88381 0,97.51236"/>
     </svg>
  ); 
  
  export const ReceiptBackground = ({ fill }) => (
    <svg viewBox="0 0 320 580" fill={ fill } className="receipt-bg">
        <path d="m309.51599,574.64602l-299.03201,0l0,-399.68948c0,0 15.77873,0.17306 15.77873,-21.63308s-15.77873,-21.63308 -15.77873,-21.63308l0,-126.65842l299.03201,0l0,126.65842c0,0 -15.77873,1.56948 -15.77873,21.63308s15.77873,21.63308 15.77873,21.63308l0,399.68948z"/>
     </svg>
  ); 