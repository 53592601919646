import React, {useContext, useState, useEffect} from 'react';
import { faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoginForm from 'js/components/loginForm'
import ForgotPwForm from 'js/components/forgotPwForm'
import ProfileView from 'js/views/profileView'
import RegistrationView from 'js/views/registrationView.js'
import DataStoreContext from 'dataStoreContext'
import { faFan} from '@fortawesome/free-solid-svg-icons'
import HelperService from "services/helpers.js";
import 'styles/topOverlayView.scss';
import 'styles/common.scss';

// Translation
import { useTranslation } from 'react-i18next';

export default function TopOverlayView({mode, showOverlay, overlayModeCB, toggleOverlayCB}) {
    /*const MODE = {
        LOGIN: 'login',
        REGISTER: 'register',
        FORGOTPW: 'forgotpw',
        PROFILE: 'profile'
      };
      */

      const { userMe} = useContext(DataStoreContext);  
    //const [mode, setMode]  = useState(MODE.LOGIN);
    //const [showOverlay, setShowOverlay]  = useState(false);
    const [newReggedUser, setNewReggedUser]  = useState(null); //{userName:x, password:y} for a new registered user
      
    // Translation
    const { t } = useTranslation('views');

    useEffect(() => {
        if (userMe) {
            //setMode(MODE.PROFILE);
            overlayModeCB('PROFILE');
        }
        else {
            //setMode(MODE.LOGIN);
            overlayModeCB('LOGIN');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userMe]);

    const toggleOverlay = () => {
        toggleOverlayCB();
    }

    const showRegistrationView = () => {
        //setMode(MODE.REGISTER);
        overlayModeCB('REGISTER');
    }

    const showForgotPwView = () => {
        overlayModeCB('FORGOTPW');
    }

    const showLoginView = (user) => {
        overlayModeCB('LOGIN');
        if (user) setNewReggedUser(user);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
      }

    const renderProfileButton = () => {
        if (showOverlay) {
            return (
                <>
                    <FontAwesomeIcon icon={faChevronUp} />
                    <div className="text">{t("topOverlayView.close")}</div>
                </>
            );
        }
        else {
            if (userMe) {
                return (
                    <>
                        <div className="text">{userMe.firstName ? userMe.firstName : userMe.userName}</div>
                        <FontAwesomeIcon icon={faUserCircle} />
                    </>
                );
            }
            else {
                return (
                    <>
                        <div className="text">{t("topOverlayView.log_in")}</div>
                        <FontAwesomeIcon style={{ position: 'relative', left: '-2px' }} icon={faSignInAlt} />
                    </>
                );
            }
        }
    }
    
    return (
    <div className={(showOverlay) ? 'top-overlay show': 'top-overlay hide' } onKeyDown={handleKeyDown}>
        <div className="content-area">
            {mode === 'login' ? <LoginForm showRegistration={showRegistrationView} loginData={newReggedUser} showForgotPw={showForgotPwView} toggleOverlayCB={toggleOverlay}/> : null}
            {mode === 'register' ? <RegistrationView  showLogin={showLoginView} toggleOverlayCB={toggleOverlay}/> : null}
            {mode === 'forgotpw' ? <ForgotPwForm showLogin={showLoginView} cancelCB={showLoginView} loginData={newReggedUser} toggleOverlayCB={toggleOverlay}/> : null}
            {mode === 'profile' ? <ProfileView  showForgotPw={showForgotPwView} toggleOverlayCB={toggleOverlay}/> : null}
        </div>
        <div className="control-area">
        { HelperService.getDevOptionsKey('usePlayground') ? 
                <FontAwesomeIcon icon={faFan} style={{fontSize: '32px', position: 'absolute', left: '63px', bottom: '10px', color: 'yellow' }}/> 
            : null }

            <div className="profile-button" onClick={()=>{ toggleOverlay() }} >
                    <div className="profile-button-container">
                        { renderProfileButton() }
                    </div>
                </div>
        </div>
    </div>
  );
}

