import React, {useContext} from 'react';
import { slide as Menu } from 'react-burger-menu';
import DataStoreContext from 'dataStoreContext'
import { Link } from "react-router-dom";
import Api from "services/api.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faLightbulb, faHistory, faSignInAlt, faSignOutAlt, faGlobeEurope, faQuestionCircle, faRedo } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import './styles/sidebar.scss';

export default function Sidebar({isOpen, stateChangeCB, overlayMenuCB}) {

    const { userMe, setUserMe, userSettings, setUserSettings, setCompletedSessions, setPaymentCards, setActiveSessions, setPaymentReceipts, flutterAppVersion} = useContext(DataStoreContext); 
    const { t, i18n } = useTranslation('views');

    const handleLogOut = () => {
        console.log('LOGGING OUT SIDEBAR');
        setCompletedSessions(null);
        setPaymentCards(null);
        setActiveSessions(null);
        setPaymentReceipts(null);
        setUserMe(null);
        stateChangeCB({isOpen:false});
        /*
        localStorage.removeItem('accessToken');
        
        history.push("/");
        stateChangeCB({isOpen:false});
        */
    }

    const reloadApp =  () => {
        window.location.reload();
    }

    const changeLanguage = async (lang) => {
        i18n.changeLanguage(lang);
        var userSettingsCopy = cloneDeep(userSettings);
        userSettingsCopy.language = lang;
        setUserSettings(userSettingsCopy);

        if (userMe) {
            let updatedUser = await Api.updateUserMe({preferredLanguage: lang});
            updatedUser.usage = userMe.usage;
            if (updatedUser) setUserMe(updatedUser);
        }
    }

    const renderLoginItem = () => {
        if (userMe) {
            return (
                <Link to="/#" onClick={()=>{handleLogOut('login')}}><div className="item"><FontAwesomeIcon icon={faSignOutAlt} /><span>{t('sideBar.log_out')}</span></div></Link>
            );
        }
        else {
            return (
                <Link to="/#" onClick={()=>{overlayMenuCB('login')}}><div className="item"><FontAwesomeIcon icon={faSignInAlt} /><span>{t('sideBar.log_in')}</span></div></Link>
            )
        }
    }


  return (
    <Menu  className="side-bar" customBurgerIcon={ false } isOpen={isOpen} onStateChange={stateChangeCB}>
        <div className="top-area-bg"></div>
        <div className="top-area-content"></div>
        

            <ul>
                <li>
                    {renderLoginItem()}
                </li>
            <li>
                <Link to={'/map'}><div className="item"><FontAwesomeIcon icon={faGlobeEurope} /><span>{t('sideBar.map')}</span></div></Link>
            </li>
                
              <li>
                <Link to={'/history'}><div className="item"><FontAwesomeIcon icon={faHistory} /><span>{t('sideBar.history')}</span></div></Link>
              </li>
              {<li>
                <Link to={'/faq'}><div className="item"><FontAwesomeIcon icon={faLightbulb} /><span>FAQ</span></div></Link>
              </li>}
              {/*<li>
                <Link to={'/settings'}><div className="item"><FontAwesomeIcon icon={faCog} /><span>Inställningar</span></div></Link>
              </li>*/}
              <li>
                <Link to={'/support'}><div className="item"><FontAwesomeIcon icon={faQuestionCircle} /><span>{t('sideBar.support')}</span></div></Link>
              </li>
              <li>
                <Link to={'/about'}><div className="item"><FontAwesomeIcon icon={faLightbulb} /><span>{t('sideBar.about_epspot')}</span></div></Link>
              </li>

              <div className="language-block">
                <div className="lb-header">{t('sideBar.language')}</div>
                <div className="lang-row" onClick={()=>{changeLanguage('en')}}>
                    <span className="flag flag-uk"/>
                    {i18n.language === 'en' ?
                        <span style={{fontWeight:'bold'}}>English</span> :
                        <span>English</span>
                    }
                </div>
                <div className="lang-row" onClick={()=>{changeLanguage('sv')}}>
                    <span className="flag flag-se"/>
                    {i18n.language === 'sv' ?
                        <span style={{fontWeight:'bold'}}>Svenska</span> :
                        <span>Svenska</span>
                    }
                </div>
              </div>
            </ul>

            <div className="version" onClick={()=>{reloadApp()}}>Version: {process.env.REACT_APP_VERSION} <span><FontAwesomeIcon icon={faRedo} /></span>
                    {flutterAppVersion ? <div>App: {flutterAppVersion}</div> : null}
            </div>
  
            {/*<Switch>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} children={<route.sidebar />} />
              ))}
              </Switch>*/}
          </Menu>
  );
}