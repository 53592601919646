import React, { useState, useEffect,useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataStoreContext from 'dataStoreContext'

import HelperService, {FormatService} from "services/helpers.js";
import 'react-rangeslider/lib/index.css'
import moment from 'moment';
import 'moment-duration-format';
import { faMinusSquare, faQuestionCircle, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import Dropdown from 'react-bootstrap/Dropdown'
import sv from 'date-fns/locale/sv';
import cloneDeep from 'lodash/cloneDeep';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import "react-datepicker/dist/react-datepicker.css";
import 'styles/sessionInputHeater.scss';
import 'styles/common.scss'
import { useTranslation } from 'react-i18next';

export default function SessionInputHeater({outlet, initSessionPropsCB}) {
    const tariff = outlet.tariff;
    const { userMe, setHelpTextModal } = useContext(DataStoreContext);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    
    const [delayedStartTimePickerIsOpen, setDelayedStartTimePickerIsOpen] = useState(false);
    const [delayedStartTime, setDelayedStartTime] = useState(moment());
    // estimatedCost is the cost for prewarming until departure
    const [estimatedCost, setEstimatedCost] = useState(0);
    // maxCost is cost for prewarm + postwarm
    const [maxCost, setMaxCost] = useState(0);
    const [preWarmingTime, setPreWarmingTime] = useState(30);
    const [postWarmingTime, setPostWarmingTime] = useState(30);

    const { t } = useTranslation("components");

    registerLocale('sv', sv)
    let calendarRef = useRef();
    let LOGGED_IN_VIEW = userMe ? true : false;

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        
        <input type="text" placeholder="välj dag" readOnly value={getDayName()} ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}></input>
      ));

      useEffect(() => {
        let storageData = window.localStorage.getItem('HEATER_DATA');
        console.log(storageData);
        if (storageData){
            storageData = JSON.parse(storageData);
            if (storageData.preWarmingTime) setPreWarmingTime(storageData.preWarmingTime);
            if (storageData.postWarmingTime) setPostWarmingTime(storageData.postWarmingTime);
            if (storageData.hasOwnProperty('startHour') && storageData.hasOwnProperty('startMinute')) {
                let delayedStartTimeCopy = cloneDeep(delayedStartTime);
                delayedStartTimeCopy.hour(storageData.startHour);
                delayedStartTimeCopy.minute(storageData.startMinute);
                setDelayedStartTime(delayedStartTimeCopy);
            }
        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, []);

    useEffect(() => {
        calculateDurations();
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [delayedStartTime, preWarmingTime, postWarmingTime]);


    
    
    const getDayName = () => {
        let now = moment();
        if (delayedStartTime.isSame(now, 'day')) return t("sessionInput.today");
        else if(delayedStartTime.isSame(now.date(now.date() + 1), 'day')) return t("sessionInput.tomorrow");
        else return delayedStartTime.format('D MMM');
    }

    const calculateDurations = () => {
        var sessionProps = {};
        sessionProps.sessionType = 'HEAT';
        sessionProps.limitType = 'TIME';
        let maxDuration = (preWarmingTime + postWarmingTime) * 60;
        sessionProps.limit = Math.ceil(maxDuration);
        let preWarmingDate = moment(delayedStartTime.format());
        preWarmingDate = preWarmingDate.subtract(preWarmingTime, 'minutes').format();
        sessionProps.delayedStartTime = preWarmingDate;
        sessionProps.cost = calculateCost(maxDuration);
        initSessionPropsCB(sessionProps);
        setEstimatedCost(calculateCost(preWarmingTime*60));
        setMaxCost(sessionProps.cost); 
    }


    const calculateCost = (time) => {
        let maxChargeKW = HelperService.getMaxCharge_kW(outlet);
        let kWh = (maxChargeKW * time) /  3600.0;
        let currentKWhCost = (tariff.feeKwh) * kWh; 
        let currentHourCost = time * (tariff.feeHour / 3600.0);
        let openingFee = (LOGGED_IN_VIEW) ? tariff.openingFee : tariff.singlePaymentOpeningFee;
        return currentHourCost + currentKWhCost + openingFee;
        
    }

    
    const handleDeparturePickerChange = (pickedDate) => {
        let pickedMoment = moment(pickedDate);
        let delayedStartTimeCopy = cloneDeep(delayedStartTime);
        delayedStartTimeCopy.year(pickedMoment.year());
        delayedStartTimeCopy.month(pickedMoment.month());
        delayedStartTimeCopy.date(pickedMoment.date());
        setDelayedStartTime(delayedStartTimeCopy);
    }
 
     const handleDepartureDateChange = (selectedDate) => {
         
         let todayDate = moment().date();
         let delayedStartTimeCopy = cloneDeep(delayedStartTime);
 
         if (selectedDate === 'today') {
             delayedStartTimeCopy.date(todayDate);
             setDelayedStartTime(FormatService.roundTimeToNextQuarter(delayedStartTimeCopy));
         }
         if (selectedDate === 'tomorrow') {
             delayedStartTimeCopy.date(todayDate+1);
             console.log('setting to: ' + delayedStartTimeCopy.format());
             setDelayedStartTime(FormatService.roundTimeToNextQuarter(delayedStartTimeCopy));
         }
         if (selectedDate === 'other') {
             calendarRef.current.setOpen(true); 
         }
 
     }
 
    const handleDepartureTimeChange = (selectedTime) => {
        let selectedHour = selectedTime.hour();
        let selectedMinute = selectedTime.minute();
        let delayedStartTimeCopy = cloneDeep(delayedStartTime);
        if (selectedHour === delayedStartTimeCopy.hour()) {
            setDelayedStartTimePickerIsOpen(false);
        }

        delayedStartTimeCopy.hour(selectedHour);
        delayedStartTimeCopy.minute(selectedMinute);

        HelperService.writeToLocalStorage('HEATER_DATA', {startHour: selectedHour, startMinute: selectedMinute});

        setDelayedStartTime(delayedStartTimeCopy);
    }

     const changePreWarmingTime = (operation) => {
         if (operation === 'plus' && preWarmingTime < 120) {
            let newTime = preWarmingTime;
            newTime += 10;
            HelperService.writeToLocalStorage('HEATER_DATA', {preWarmingTime: newTime});
            setPreWarmingTime(newTime);
         }
         else if (operation === 'minus' && preWarmingTime > 0) {
             let newTime = preWarmingTime;
             newTime -= 10;
             HelperService.writeToLocalStorage('HEATER_DATA', {preWarmingTime: newTime});
            setPreWarmingTime(newTime);
        }
     }

     const changePostWarmingTime = (operation) => {
        if (operation === 'plus' && postWarmingTime < 120) {
           let newTime = postWarmingTime;
           newTime += 10;
           HelperService.writeToLocalStorage('HEATER_DATA', {postWarmingTime: newTime});
           setPostWarmingTime(newTime);
        }
        else if (operation === 'minus' && postWarmingTime > 0) {
            let newTime = postWarmingTime;
            newTime -= 10;
            HelperService.writeToLocalStorage('HEATER_DATA', {postWarmingTime: newTime});
           setPostWarmingTime(newTime);
       }
    }
 
    const formatAmount = (value) => {
        return value.toFixed(2);
    }

    const renderCostInfo = () => {
        if (LOGGED_IN_VIEW) {
            return (
                <>
                    <div className="heater-cost"><div>{formatAmount(estimatedCost)}</div><div>{FormatService.getCurrencyUnit('SEK')}</div></div> 
                    <div onClick={()=>setHelpTextModal(['price_and_payment:car_heater_estimated_cost'])} className="heater-cost-info">{t("inputHeater.estimated_cost")} <span className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span></div> 
                </>
            );
        }
        else {
            return (
                <>
                    <div className="heater-cost" onClick={()=>setHelpTextModal(['price_and_payment:car_heater_pricing'])}><div>{formatAmount(maxCost)}</div><div>{FormatService.getCurrencyUnit('SEK')}</div><span className="help-text-button"><FontAwesomeIcon icon={faQuestionCircle} /></span></div> 
                </>
            );
        }
        
    }

    return (
        <>
            <div className="heater-time-header">
                <div className="left-side" />
                <label>{t("sessionInput.set_departure_time")}</label>
                <div className="right-side" />
            </div>



            <div className="heater-time-section">
                <DatePicker selected={delayedStartTime.toDate()} locale="sv" ref={calendarRef} onChange={date => handleDeparturePickerChange(date)} />
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="departure-date-picker">
                        {t("sessionInput.today")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="today" onSelect={handleDepartureDateChange}>{t("sessionInput.today")}</Dropdown.Item>
                        <Dropdown.Item eventKey="tomorrow" onSelect={handleDepartureDateChange}>{t("sessionInput.tomorrow")}</Dropdown.Item>
                        <Dropdown.Item eventKey="other" onSelect={handleDepartureDateChange}>{t("sessionInput.another_day")}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <TimePicker placeholder='välj tid' open={delayedStartTimePickerIsOpen} onOpen={() => { setDelayedStartTimePickerIsOpen(true) }} onClose={() => { setDelayedStartTimePickerIsOpen(false) }} popupClassName="time-picker-popup" value={delayedStartTime}
                    showSecond={false} minuteStep={5} onChange={handleDepartureTimeChange} clearIcon={null} inputReadOnly={true} placement="topRight" />
            </div>

            <div className="heater-info-section" onClick={()=>setShowSettingsModal(true)}>
                {t("inputHeater.engine_pre-heater")} <span className="pre-post-warm">{preWarmingTime} min</span> {t("inputHeater.before_and_turns_off")} <span className="pre-post-warm">{postWarmingTime} min</span> {t("inputHeater.after_selected_time")}
            </div>

            {renderCostInfo()}
            <div style={{height:'30px'}}/>


            <Modal className="pre-post-modal" centered show={showSettingsModal} onHide={()=>{setShowSettingsModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>{t("inputHeater.change_time_limit")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>{t("inputHeater.pre-heat_time")}</label>
                    <div className="input-row">
                        <div className={(preWarmingTime>0)?'input-button':'input-button disabled'} onClick={()=>changePreWarmingTime('minus')}><FontAwesomeIcon icon={faMinusSquare} /></div>
                        <div  className="value">{preWarmingTime}</div>
                        <div className={(preWarmingTime < 120)?'input-button':'input-button disabled'} onClick={()=>changePreWarmingTime('plus')}><FontAwesomeIcon icon={faPlusSquare} /></div>
                    </div>

                    <label className="post-warming-label">{t("inputHeater.post-heat_time")}</label>
                    <div className="explainer">{t("inputHeater.information")}</div>
                    <div className="input-row">
                        <div className={(postWarmingTime > 0)?'input-button':'input-button disabled'} onClick={()=>changePostWarmingTime('minus')}><FontAwesomeIcon icon={faMinusSquare} /></div>
                        <div  className="value">{postWarmingTime}</div>
                        <div className={(postWarmingTime < 120)?'input-button':'input-button disabled'} onClick={()=>changePostWarmingTime('plus')}><FontAwesomeIcon icon={faPlusSquare} /></div>
                    </div>

                    <Button className="ok-button" onClick={()=>setShowSettingsModal(false)}>Ok</Button>

                </Modal.Body>
                
            </Modal>

        </>
    );

}


