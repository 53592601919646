import React from 'react';
import 'styles/radioButton.scss';
import 'styles/common.scss';

export default function RadioButton({text, value, name, checked, onChange}) {


  return (
    <label className="radio-button-container">{text}
    <input id={value} name={name} value={value} checked={checked} type="radio" onChange={onChange}/>
    <span className="checkmark"></span>
    </label>
      
    
  );
}