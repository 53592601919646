import React, { useState, useRef} from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Toggle from 'react-toggle';
import { CSSTransition } from 'react-transition-group';
import HelperService, { FormatService} from "services/helpers.js";
import moment from 'moment';
import 'moment-duration-format';
import { registerLocale } from  "react-datepicker";
import sv from 'date-fns/locale/sv';
import Dropdown from 'react-bootstrap/Dropdown'
import cloneDeep from 'lodash/cloneDeep';

import { useTranslation } from 'react-i18next';


import "react-datepicker/dist/react-datepicker.css";
import 'styles/sessionInputPower.scss';
import 'styles/common.scss'

export default function DelayedStartInput({sessionType, enableDepartureTime, setEnableDepartureTime, delayedStartTime, setDelayedStartTime}) {
    const [delayedStartTimePickerIsOpen, setDelayedStartTimePickerIsOpen] = useState(false);
    const { t } = useTranslation('components');
    registerLocale('sv', sv);
    let calendarRef = useRef();

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        
        <input type="text" placeholder="välj dag" readOnly value={getDayName()} ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}></input>
      ));
    
    const getDayName = () => {
        let now = moment();
        if (delayedStartTime.isSame(now, 'day')) return t('sessionInput.today');
        else if(delayedStartTime.isSame(now.date(now.date() + 1), 'day')) return t('sessionInput.tomorrow');
        else return delayedStartTime.format('D MMM');
    }  

    const handleDeparturePickerChange = (pickedDate) => {
        let pickedMoment = moment(pickedDate);
        let delayedStartTimeCopy = cloneDeep(delayedStartTime);
        delayedStartTimeCopy.year(pickedMoment.year());
        delayedStartTimeCopy.month(pickedMoment.month());
        delayedStartTimeCopy.date(pickedMoment.date());
        setDelayedStartTime(delayedStartTimeCopy);
    }
 
     const handleDepartureDateChange = (selectedDate) => {
         
         let todayDate = moment().date();
         let delayedStartTimeCopy = cloneDeep(delayedStartTime);
 
         if (selectedDate === 'today') {
             delayedStartTimeCopy.date(todayDate);
             setDelayedStartTime(FormatService.roundTimeToNextQuarter(delayedStartTimeCopy));
         }
         if (selectedDate === 'tomorrow') {
             delayedStartTimeCopy.date(todayDate+1);
             setDelayedStartTime(FormatService.roundTimeToNextQuarter(delayedStartTimeCopy));
         }
         if (selectedDate === 'other') {
             calendarRef.current.setOpen(true); 
         }
     }
 
     const handleDepartureTimeChange = (selectedTime) => {
         let selectedHour = selectedTime.hour();
         let selectedMinute = selectedTime.minute();
         let delayedStartTimeCopy = cloneDeep(delayedStartTime);
         if (selectedHour === delayedStartTimeCopy.hour()) {
            setDelayedStartTimePickerIsOpen(false);
        }
         delayedStartTimeCopy.hour(selectedHour);
         delayedStartTimeCopy.minute(selectedMinute);

         let storageKey = 'POWER_DATA';
         if (sessionType === 'CHARGE') storageKey = 'CHARGE_DATA';
         HelperService.writeToLocalStorage(storageKey, {startHour: selectedHour, startMinute: selectedMinute});

         setDelayedStartTime(delayedStartTimeCopy);
     }

    return (
        <>
            <div className={(enableDepartureTime) ? "centered-toggle-button on" : "centered-toggle-button off"}>
            <div className="left-side"><div className="shortened-line" /></div>
            <Toggle
                checked={enableDepartureTime}
                icons={{
                    checked: <span className="track-text"></span>,
                    unchecked: <span className="track-text"></span>,
                }}
                onChange={() => { setEnableDepartureTime(!enableDepartureTime) }} />
            <label>{t('sessionInput.delayed_start')}</label>
            <div className="right-side" />
        </div>

        <CSSTransition in={enableDepartureTime} timeout={300} classNames="dts">
            <div className="departure-time-section">
                <DatePicker selected={delayedStartTime.toDate()} locale="sv" ref={calendarRef} onChange={date => handleDeparturePickerChange(date)} />
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="departure-date-picker">
                        {t('sessionInput.today')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="today" onSelect={handleDepartureDateChange}>{t('sessionInput.today')}</Dropdown.Item>
                        <Dropdown.Item eventKey="tomorrow" onSelect={handleDepartureDateChange}>{t('sessionInput.tomorrow')}</Dropdown.Item>
                        <Dropdown.Item eventKey="other" onSelect={handleDepartureDateChange}>{t('sessionInput.another_day')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <TimePicker placeholder={t('sessionInput.set_time')}  open={delayedStartTimePickerIsOpen} onOpen={() => { setDelayedStartTimePickerIsOpen(true) }} onClose={() => { setDelayedStartTimePickerIsOpen(false) }} popupClassName="time-picker-popup" value={delayedStartTime}
                    showSecond={false} minuteStep={15} onChange={handleDepartureTimeChange} clearIcon={null} inputReadOnly={true} placement="topRight" />
            </div>
        </CSSTransition>
        </>
  );
}